.prediction-wrapper {
    padding: 15px;
}

.prediction-list {
    border-radius: 14px;
    box-shadow: 0 16px 16px 0 rgba(0, 0, 0, 0.05);
    overflow: hidden;
    margin-bottom: 20px;
}

.list-header {
    background: $white;
    padding: 15px;

    .list-que {
        font-size: 16px;
        font-family: $font-bold-italic;
        color: $gray-900;
        margin-bottom: 20px;

        img {
            width: 20px;
        }
    }

    i.icon-share {
        position: absolute;
        right: 30px;
        font-size: 16px;
        color: gray;
    }

    .option-list {
        list-style-type: none;
        padding: 0;
        margin: 0;

        li {
            border: 1px solid $gray-500;
            box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
            border-radius: 15px;
            padding: 5px 15px;
            font-family: $font-bold-italic;
            font-size: 12px;
            color: $gray-900;
            margin-bottom: 10px;

            &.selected {
                color: $white;
                background: $primary;
            }

            &.active {
                color: $white;
                background: $gray-600;
                margin-bottom: 0;

                ~.estimation {
                    color: $gray-550;
                    font-size: 10px;
                    font-family: $font-bold-italic;
                    margin-bottom: 20px;
                    padding-left: 10px;
                    padding-top: 3px;

                    .applied-coins {
                        color: $green;
                        font-size: 12px;
                    }

                    i {
                        font-size: 14px;
                        position: relative;
                        top: -2px;
                    }
                }
            }

            .ratio {
                float: right;

                i {
                    position: relative;
                    top: -2px;
                    font-size: 16px;
                    margin-left: 5px;
                }
            }

            &.selected-right {
                color: $white;
                background: $green;
            }

            &.selected-wrong {
                color: $white;
                background: $red;
            }
        }
    }
}

.list-footer {
    display: table;
    width: 100%;

    .display-table-cell {
        padding: 15px;
        color: $gray-575;
        font-size: 14px;
        font-family: $font-bold-italic;

        .total-pool {
            color: $primary;

            i {
                position: relative;
                top: -1px;
            }
        }

        .text-small {
            font-family: $font-bold-italic;
        }

        .countdown {
            font-size: 10px;
            font-family: $font-bold-italic;
            color: $red;
        }

        &:nth-child(2) {
            width: 30%;
            text-align: center;
        }
    }

    .btn-rounded-border {
        width: 110px;
        font-size: 11px;
    }
}

.prediction-modal {
    .modal-footer {
        padding: 0;
        border-radius: 0 0 13px 13px;
        border-top: 0;
        overflow: hidden;

        .btn {
            border-radius: 0;
            width: 100%;
            font-size: 16px;
            color: $green;
        }
    }

    .modal-body {
        // background: url('https://sports-vinfotech-org.s3.amazonaws.com/assets/img/bg-circle.png') no-repeat;
        background-size: cover;

        .verification-block {
            margin-bottom: 20px;
        }
    }
}

.user-coins-bal {
    margin: 0 auto;
    margin-top: 45px;
    margin-bottom: 32px;
    border-radius: 32px;
    background: $gray-170;
    padding: 10px 40px;
    font-size: 14px;
    font-family: $font-bold-italic;
    color: $gray-800;
    text-align: center;
    display: inline-block;

    .bal-count {
        font-family: $font-bold;
        font-size: 20px;
        color: $green;
    }
}

.min-coins {
    position: absolute;
    bottom: 5px;
    font-size: 11px;
    left: 0;
    right: 0;
}

.prediction-contest-wrapper {
    .tab-content {
        .contest-card:first-child {
            margin-top: 0;
        }
    }
}

.toggle-nav {
    .nav.nav-tabs {
        border-radius: 22px;
        display: block;
        width: 80%;
        max-width: 300px;
        margin: 20px auto 0;
        overflow: hidden;
        height: 30px;
        padding: 0 10px;

        li {
            display: inline-block;
            border-radius: 22px;
            height: 30px;
            overflow: hidden;

            a {
                padding: 7px 10px;
            }

            &.active {
                a {
                    background: $primary;
                    color: $white;

                    &:before,
                    &:after {
                        display: none;
                    }
                }
            }
        }
    }
}

.ribbon {
    position: absolute;
    background: $green;
    border-radius: 10px 0 0px 10px;
    height: 20px;
    width: 50px;
    color: #fff;
    text-align: center;
    line-height: 22px;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    font-size: 12px;
    text-transform: uppercase;
}

.verification-block {
    min-height: 97px;
}