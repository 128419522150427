.leaderboard-header {
    padding: 15px;
    background: #f7f7f7;
}

.leaderboard-web-container {
    .page-inner-header.page-inner-header-xlg {
        background: $primary url('../img/sponsered-img.png') no-repeat center center;
        background-size: cover;
        height: 270px;

        img {
            display: none;
        }

        .tab-navigation {
            padding-top: 170px;
        }

        @media (max-width: 380px) {
            height: 210px;

            .tab-navigation {
                padding-top: 110px;
            }
        }
    }
}

.leaderboard-wrapper {
    .heading-section {
        background: #f7f7f7;
        margin: 0;
        padding: 0 15px 15px 15px;
        font-family: $font-black;

        .purple {
            color: $refsocial-secondary-clr
        }

        &.border-top {
            padding-top: 15px;
            margin-top: 1px solid $border-light;
        }

        i {
            font-size: 21px;
            color: $secondary;
            display: inline-block;
            margin-right: 5px;
            top: -1px;
            position: relative;

            &.icon-search {
                float: right;
                font-size: 18px;
            }
        }
    }
}

.leaderboard-data-wrap {
    .elimination-group {
        text-align: center;
        font-size: 14px;
        font-family: $font-black;
        color: $secondary;
        margin: 0 5% 4px;
        max-height: 40px;
        overflow: hidden;

        .slick-arrow.slick-next {
            display: block !important;
            right: 0;
            z-index: 1;

            &:before {
                color: $secondary;
                opacity: 1 !important;
                background: white;
            }
        }

        .slick-arrow.slick-prev {
            display: block !important;
            left: 0;
            z-index: 1;

            &:before {
                color: $secondary;
                opacity: 1 !important;
                background: white;
            }
        }
    }

    a {
        color: $gray-900;

        &.collapsed {
            .leaderboard-table-view {
                background: $white;

                &.current-user-data {
                    // current user
                    background: $highlight-clr;
                }

                &.selected-player-data {
                    //pundite user
                    background: $selected-highlight-clr;
                }

                &.unselected-player-data {
                    background: $unselected-highlight-clr;
                }
            }
        }

        &.selected-player-data {
            //pundite user
            background: #F7F7F7;
        }
    }

    .leaderboard-table-view {
        display: table;
        width: 100%;
        border-bottom: 1px solid $border-light;
        background: #F7F7F7;

        &.ot-list{
            .ref-section{
                img{
                    width: 37px;
                    height: 37px;
                    margin-right: 7px;
                    &.p-plus-img {
                        left: 31px;
                    }
                    &.p-plus-img-2{
                        width: 14px;
                        height: 14px;
                        margin-top: 0px;
                        margin-left: 2px;
                        position: absolute;
                    }
                }

            }
            
        }

       

        &.white-bg {
            background: $white;
        }

        .g-name {
            font-family: $font-italic;
            color: $secondary;
        }

        .rank-section,
        .ref-section,
        .pl-section,
        .sp-section,
        .rp-section,
        .follow-section {
            display: table-cell;
            padding: 20px 8px;
            font-family: $font-regular;
            font-size: 12px;
            vertical-align: middle;

            @media (max-width: 320px) {
                padding: 20px 4px;
            }
        }

        .rank-section {
            width: 13%;
            text-align: left;
            color: $gray-650;
            font-family: $font-bold;

            i {
                font-size: 10px;
                margin-left: 3px;

                &.icon-sorting-arrow-down {
                    color: $red;
                }

                &.icon-sorting-arrow-up {
                    color: $ternary;
                }

                &.no-change {
                    width: 8px;
                    height: 8px;
                    border-radius: 100%;
                    border: 2px solid #FAB800;
                    display: inline-block;
                }
            }

        }

        .ref-section {
            width: 47%;
            font-family: $font-bold;
            position: relative;
            padding-left: 0;

            img {
                width: 30px;
                height: 30px;
                border-radius: 50%;
                margin-right: 18px;
                background: #e1e1e1;
                object-fit: cover;

                @media (max-width: 320px) {
                    margin-right: 25px;
                }

                &.p-plus-img {
                    width: 14px;
                    height: 14px;
                    position: absolute;
                    margin: 0;
                    left: 28px;
                    top: 50%;
                    transform: translateY(-50%);

                    @media (max-width: 320px) {
                        left: 35px;
                    }
                }
            }

            span {
                display: inline-block;
                overflow: hidden;
                max-width: 240px;
                text-overflow: ellipsis;
                white-space: nowrap;
                vertical-align: middle;

                div.us_name{
                    text-overflow: ellipsis;
                    // white-space: nowrap;
                    // overflow: hidden;
                }

                @media (max-width: 767px) {
                    max-width: 160px;
                }

                @media (max-width: 580px) {
                    max-width: 103px;
                    div.us_name{
                        font-size: 11px;
                    }
                }

                // @media (max-width: 438px) {
                //     max-width: 55px;
                // }

                // @media (max-width: 320px) {
                //     max-width: 50px;
                // }
            }
        }

        .pl-section {
            width: 9%;
            text-align: center;
            color: $gray-900;

            &.batch {
                width: 30%;
                text-align: left;
            }
        }

        .rp-section {
            width: 9%;
            text-align: center;
            color: $gray-900;
        }

        .sp-section {
            width: 9%;
            text-align: center;
            color: $gray-900;
        }

        .follow-section {
            width: 13%;
            text-align: center;

            i {
                font-size: 18px;

                &.icon-add-user {
                    color: $ternary;
                }

                &.icon-user-select {
                    color: $gray-550;
                }
            }
        }

        &.leaderboard-table-head {
            background: $primary;

            .rank-section,
            .ref-section,
            .pl-section,
            .rp-section,
            .sp-section,
            .follow-section {
                color: $refsocial-secondary-clr;
                font-size: 12px;
                font-family: $font-bold;
                text-transform: capitalize;
                padding: 7px 8px;

                &.serial {
                    width: 15%;
                }

                &.username {
                    width: 40%;
                }

                &.batch {
                    width: 30%;
                    text-align: left;
                }
            }
        }
    }

    &.leaderboard-seeding-data-wrap {
        .leaderboard-table-view {
            .ref-section {
                img {
                    margin-right: 10px;
                }
            }
        }
    }

    &.leaderboard-rpl-data-wrap {
        padding-top: 10px;

        .leaderboard-table-view {

            .serial-no-section,
            .username-section,
            .batch-section {
                display: table-cell;
                padding: 15px 8px;
                font-family: $font-regular;
                font-size: 12px;
                vertical-align: middle;
            }

            .serial-no-section {
                width: 10%;
            }

            .username-section {
                width: 45%;
                font-family: $font-bold;
                position: relative;
                padding-left: 0;

                img {
                    width: 30px;
                    height: 30px;
                    border-radius: 50%;
                    margin-right: 10px;
                }
            }

            .batch-section {
                width: 30%;
            }

            &.leaderboard-table-head {
                background: $secondary;

                .serial-no-section,
                .username-section,
                .batch-section {
                    color: $white;
                    font-size: 12px;
                    font-family: $font-black;
                    text-transform: capitalize;
                    padding: 7px 8px;
                }
            }
        }
    }
}

.user-more-info {
    background: $gray-170;

    .user-earned {
        border-bottom: 1px solid $border-light;
        display: table;
        width: 100%;
        padding: 15px 10px;
    }

    .label {
        font-size: 11px;
        font-family: $font-bold-italic;
        color: $gray-900;
        padding-right: 10px;
        text-transform: uppercase;
        display: table-cell;
        vertical-align: middle;

        span {
            color: $gray-550;
        }
    }

    .data {
        display: table-cell;
        text-align: right;
        vertical-align: middle;

        i {
            margin-left: 5px;
            font-size: 18px;
        }

        a {
            display: inline-block;
            text-align: center;
            border-radius: 20px;
            box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.1), 0 16px 16px 0 rgba(0, 0, 0, 0.05);
            background: $white;
            padding: 6px 12px;
            font-size: 10px;
            color: $secondary;
            font-family: $font-black;
            text-transform: uppercase;
            margin-left: 10px;

            @media(max-width: 428px) {
                padding: 6px 10px;
                margin-left: 6px;
            }
        }

        &.last-match-status {
            img {
                width: 22px;
                margin-right: 5px;
            }
        }

        &.badges-earned {
            img {
                margin-right: 5px;
                height: 20px;
            }

            sup {
                top: -14px;
                right: 3px;
            }
        }
    }
}

.leaderboard-web-container {
    .default-tab {
        ul.nav-tabs {
            li {
                height: 44px;
                padding: 0 5px;

                a {
                    line-height: 26px;
                    height: 100%;
                    padding: 8px 5px;
                    vertical-align: middle;
                }

                &.bg-label {
                    a {
                        line-height: 14px;
                    }
                }
            }
        }
    }

    .tab-navigation li {
        a:last-child {
            color: $white;
            position: relative;

            i {
                transform: rotate(-20deg);
                display: inline-block;
                position: relative;
                top: -2px;
            }

            span.red-dot {
                background: $red;
                position: relative;
                display: inline-block;
                width: 5px;
                height: 5px;
                top: -8px;
                right: 10px;
                border-radius: 50px;

                @media (max-width: 320px) {
                    top: -5px;
                    right: 8px;
                }
            }
        }
    }
}

.search-header {
    padding: 0 15px;

    .heading-section {
        i {
            font-size: 21px;
            color: #023320;
            display: inline-block;
            margin-right: 5px;
            top: -1px;
            position: relative;
        }
    }
}

.leaderboard-wrapper .heading-section,
.heading-section {
    .search-btn {
        position: relative;
        width: 34px;
        float: right;
        -webkit-transform: translate3d(0px, 0px, 0px);
        transform: translate3d(0px, 0px, 0px);
        -webkit-transition-duration: 0.5s;
        transition-duration: 0.5s;
        -webkit-transition-timing-function: ease-in-out;
        transition-timing-function: ease-in-out;

        @media (max-width: 428px) {
            -webkit-transition-duration: 0.5s;
            transition-duration: 0.5s;
        }

        .search-box {
            top: 0;
            color: #023320;
            background: transparent;
            position: absolute;
            width: 34px;
            height: 36px;
            padding: 10px;
            text-align: center;
            right: 0;
            z-index: 1;
            cursor: pointer;
        }

        .form-control {
            background: transparent;
            border: 0;
            padding: 9px 10px;
            font-size: 12px;
            border-radius: 0;
            height: auto;
            color: $secondary;
            padding-right: 35px;
            border-bottom: 1px solid transparent !important;
        }

        i {
            color: $primary;
            display: inline-block;
            top: -1px;
            position: relative;
            color: $secondary;
            font-size: 18px;
            float: right;

            &.icon-search {
                right: 0;
                margin-right: 0;
            }

            &.icon-close {
                font-size: 14px;
                right: 0;
                margin-right: 0;
                top: 2px;
            }
        }

        &.search-full {
            width: 100%;
            margin-bottom: 5px;

            .search-box {
                background: transparent;
            }

            .form-control {
                color: $secondary;
                border-color: rgba(153, 153, 153, 0.5) !important;
            }
        }
    }

    .filter-by-name {
        padding: 8px 0;
        display: inline-block;

        @media (min-width: 767px) {
            display: none;
        }
    }

    ::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        color: grey;
    }

    ::-moz-placeholder {
        /* Firefox 19+ */
        color: grey;
    }

    :-ms-input-placeholder {
        /* IE 10+ */
        color: grey;
    }

    :-moz-placeholder {
        /* Firefox 18- */
        color: grey;
    }
}

.user-earned {
    .data {
        span {
            >span.icon-star {
                font-size: 16px;
                margin-left: 5px;
                color: $gray-460;

                &.highlighted {
                    color: $fav-color;
                }
            }
        }
    }

    .active-perform {
        background: linear-gradient(73.19deg, #00AF8B 0%, #00FF87 100%) !important;
        color: #fff !important;
        box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.1), 0 16px 16px 0 rgba(0, 0, 0, 0.08) !important;
    }
}

.er-links{
    .earn-points-link{
        margin-right: 20px;
        position: relative;
        &:after{
            content: "";
            position: absolute;
            right: -13px;
            top: 5px;
            width: 4px;
            height: 4px;
            background-color: $secondary;
            border-radius: 50%;
        }
        &:last-child{
            &::after{display: none;}
        }
    }
}

.earn-points-link {
    text-decoration: underline;
    font-size: 10px;
    font-family: $font-bold-italic;
    color: $secondary;
    position: relative;
    top: -5px;
}

.badge-count {
    display: inline-block;
    border-radius: 100%;
    background: $secondary;
    color: $white;
    width: 16px;
    height: 16px;
    line-height: 16px;
    text-align: center;
    font-size: 10px;
    font-family: $font-bold;
    position: relative;
    top: -10px;
    left: -6px;
}