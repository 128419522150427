.flex-container {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: row;
    padding: 10px 0;

    li {
        position: relative;

        &:after {
            position: absolute;
            content: '';
            width: 50%;
            right: 0;
            height: 1px;
            background: #DDDDDD;
            top: 50%;
            transform: translateY(-50%);
        }

        &:nth-child(even) {
            &:before {
                content: "";
                position: absolute;
                width: 2px;
                right: 0;
                height: 100%;
                background: #DDDDDD;
                bottom: 0;
                transform: translateY(-50%);
            }
        }

        span {
            position: absolute;
            width: 50%;
            left: 0%;
            height: 1px;
            background: #DDDDDD;
            bottom: 50%;
            transform: translateY(-50%);
            display: inline-block
        }
    }

    .fifth-user-list {
        li {

            &:after,
            &:before {
                display: none;
            }
        }
    }
}

.first-user-list,
.second-user-list,
.third-user-list,
.fourth-user-list,
.fifth-user-list {
    width: 20%;
    text-align: center;

    ul {
        padding: 0;
        list-style-type: none;
        margin: 0;

        li {
            padding: 10px;

            img {
                border-radius: 100%;
                width: 30px;
                height: 30px;
                background-color: #D8D8D8;
                position: relative;
                z-index: 9;
                cursor: pointer;
                object-fit: cover;

                &:before {
                    content: "";
                    position: absolute;
                    width: 200px;
                    right: 0;
                    height: 50px;
                    background: black;
                    top: 0%;
                }
            }
        }
    }
}

.second-user-list {
    ul {
        li {
            padding: 35px 10px;
        }
    }
}

.third-user-list {
    ul {
        li {
            padding: 85px 10px;
        }
    }
}

.fourth-user-list {
    ul {
        li {
            padding: 185px 10px;
        }
    }
}

.fifth-user-list {
    ul {
        li {
            .winner-section {
                border-radius: 4px;
                padding: 5px 5px;
                background-color: $white;
                position: relative;
                z-index: 9;
                box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.1);

                .winner-name {
                    margin: 0;
                    padding: 5px 0 0;
                    font-size: 10px;
                    color: $secondary;
                    font-family: $font-bold-italic;
                }
            }
        }
    }
}