.refer-friend {
    .nav-tabs {
        box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.12) !important;
    }

    .custom-nav-tabs .tab-content {
        padding: 0;

        .inner-tab-content {
            padding: 15px;
        }
    }

    .list-card {
        border-radius: 5px;
        padding: 12px 5px;

        &.list-card-with-img {
            padding-left: 48px;
        }

        &.list-card-wrapper {
            padding: 0;
            border-radius: 8px;
            box-shadow: 0 16px 16px 0 rgba(0, 0, 0, 0.05);

            .user-cell {
                justify-content: unset;
                margin-right: 0;
                padding: 10px;

                .center-alingment {
                    i.icon-coins {
                        font-size: 14px;
                        position: relative;
                        top: -2px;
                        margin-right: 2px;
                    }
                }

                &.user-joined-info {
                    padding: 15px;
                }
            }

            .display-table.display-table-four-col {
                .user-cell {
                    width: 25%;
                }
            }

            .referral-amount-section {
                .user-cell.display-table-cell {
                    padding: 4px 10px 10px;
                }

                .amount-value {
                    font-size: 12px;
                    font-family: $font-bold;
                    display: block;
                    text-align: center;
                    color: $gray-900;
                }

                .amount-heading {
                    font-family: $font-italic;
                    font-size: 12px;
                    display: block;
                    text-align: center;
                }
            }
        }
    }

    .page-inner-header {
        &.page-inner-header-sm {
            padding: 14px 15px 34px;
        }

        h2 {
            max-width: 380px;
            font-family: $font-xtrabold;
        }

        @media (max-width: 430px) {
            &.page-inner-header-sm {
                padding: 15px 15px 35px;
            }

            .header-bg {
                width: 36%;
            }

            h2 {
                max-width: 230px;
                font-family: $font-xtrabold;
                font-size: 22px;
            }
        }

        @media (max-width: 400px) {
            .header-bg {
                width: 40%;
            }

            h2 {
                font-size: 20px;
            }
        }

        @media (max-width: 400px) {
            &.page-inner-header-sm {
                padding: 15px 6px 35px;
            }

            .header-bg {
                width: 45%;
            }

            h2 {
                width: 210px;
                font-size: 17px;
            }
        }
    }
}

.my-referals {
    display: table;
    border-radius: 3px;
    width: 80%;
    background: $white;
    box-shadow: 4px 4px 8px 0 rgba(0, 0, 0, 0.1);
    position: relative;
    overflow: hidden;
    top: -20px;
    max-width: 400px;
    top: -20px;
    margin: 0 auto;

    .display-table-cell {
        color: $gray-900;
        font-size: 16px;
        font-family: $font-bold;
        text-align: center;
        vertical-align: middle;
        padding: 12px 15px;

        @media (max-width: 400px) {
            padding: 12px 12px;
        }

        @media (max-width: 320px) {
            padding: 12px 8px;
        }

        .arrow-right {
            width: 0;
            height: 0;
            border-top: 8px solid transparent;
            border-bottom: 8px solid transparent;
            border-left: 8px solid $light-gray;
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
        }

        .referal-points-heading {
            text-transform: uppercase;
            font-size: 10px;
            color: $gray-550;

        }

        &.referals-heading {
            background: $light-gray;
            color: $blue-800;
            font-size: 10px;
            text-transform: uppercase;
            font-family: $font-bold;
            width: 90px;
        }

        i {
            font-size: 14px;
            color: $primary;
            position: relative;
            top: -1px;
        }
    }
}

.link-heading {
    font-size: 12px;
    font-family: $font-bold;
    color: $gray-900;
    text-align: center;
}

ul.social-icons {
    list-style-type: none;
    padding: 0;
    text-align: center;
    margin-top: 20px;

    li {
        display: inline-block;
        margin-right: 30px;
        text-align: center;

        @media (max-width: 320px) {
            margin-right: 20px;
        }

        .social-circle {
            border-radius: 50%;
            cursor: pointer;
            box-shadow: 0 8px 15px 0 rgba(0, 0, 0, 0.1);
            width: 40px;
            height: 40px;
            background: $white;
            text-align: center;
            display: block;
            line-height: 40px;
            margin: 0 auto;

            i {
                font-size: 18px;
            }
        }

        label {
            color: $gray-900;
            font-size: 10px;
            font-family: $font-bold;
            margin-top: 10px;
        }

        &:last-child {
            margin-right: 0;
        }


    }
}

.social-linking {
    background: $gray-125;
    border-radius: 5px;
    box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.1);
    padding: 15px;
}

.referal-code {
    background: $white;
    border-radius: 3px;
    box-shadow: 4px 4px 8px 0 rgba(0, 0, 0, 0.1);
    font-family: $font-regular;
    color: $gray-900;
    font-size: 11px;
    text-align: center;

    h1 {
        font-family: $font-black;
        font-size: 18px;
        margin: 5px 0;
    }
}

.referal-body {
    padding: 7px;
    position: relative;

    .copy-text {
        position: absolute;
        right: 10px;
        top: 5px;
        color: $secondary;
        text-transform: uppercase;
        font-family: $font-black;
        font-size: 12px;
        cursor: pointer;
    }
}

.referal-footer {
    background: $gray-170;
    text-align: center;
    padding: 5px;
    color: $txt-secondary;
    font-size: 9px;
    font-family: $font-regular;
}

.top-referals {
    background: $white;
    box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    padding: 15px 0;
    padding-bottom: 8px;
    margin-top: 15px;

    .list-card {
        border: 0.5px solid rgba(224, 221, 221, 0.7);
        box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
        border-radius: 3px;
        margin-bottom: 0;
        justify-content: space-between
    }
}

figure {
    &.square32 {
        width: 32px;
        height: 32px;
    }

    img {
        width: 100%;
    }
}

.user-cell {
    justify-content: space-between;
    margin-right: 40px;
    font-size: 12px;
    font-family: $font-italic;

    div:last-child {
        color: $gray-675;
        font-family: $font-italic;

        &.referal-name {
            // margin-bottom: 15px;
            color: $gray-900;
            font-size: 14px;

            i {
                font-size: 16px;
                margin-right: 10px;

                &.icon-email {
                    color: $secondary;
                }
            }

            span {
                display: inline-block;
                float: right;
                color: $gray-675;
                font-size: 12px;
                font-family: $font-italic;
                line-height: 1.7;
            }
        }
    }
}

.center-alingment {
    text-align: center;
}

.referal-time {
    color: $gray-675;
    font-size: 12px;
    font-family: $font-italic;
}

.user-status {
    display: inline-block;
    width: 18px;
    height: 18px;
    border: 1px solid $gray-550;
    border-radius: 50%;
    line-height: 16px;
    text-align: center;

    i {
        font-size: 9px;
        display: none;
    }

    &.active {
        border: 1px solid $green;

        i {
            display: inline-block;
            color: $green;
        }
    }
}

.referal-list-heading {
    li {
        padding: 10px;
        font-size: 10px;
        font-family: $font-bold;
        color: $gray-575;
        text-transform: capitalize;
        float: left;
        width: 25%;
        display: block;
        text-align: center;
    }

    margin: 0;
    padding: 0;
}

.checkbox-section {
    padding: 10px 10px;

    .user-cell {
        background: $gray-170;
        padding: 3px 10px 7px 10px !important;

        &:first-child {
            border-radius: 17px 0 0 17px;
        }

        &:last-child {
            border-radius: 0 17px 17px 0;
        }
    }

}

.total-amount-heading {
    padding: 10px 0 0 10px;
    font-size: 10px;
    color: $gray-900;
    font-family: $font-bold;
    margin-bottom: 0;
}

.page-sub-header {
    background: $light-gray;
    padding: 6px 15px;
    font-size: 11px;
    font-family: $font-black;
    text-transform: uppercase;
    color: $gray-900;
    letter-spacing: 1px;
}

.referral-list {
    padding: 15px;
    list-style-type: none;
    margin: 0;
}


// share modal link styling

.share-modal-bg {
    .modal-body {
        padding: 20px 10px;
        border-radius: 14px;
        background: $white;

        .share-link-body {
            h4 {
                font-size: 16px;
                font-family: $font-black-italic;
                color: $black;
                text-align: center;
                max-width: 190px;
                margin: 0 auto;
            }
        }
    }

    .social-linking {
        box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.1);
        background: $white;
    }

    ul.social-icons {
        li {
            label {
                color: $gray-550;
                font-size: 12px;
            }

            .social-circle {
                line-height: 40px;
                font-size: 18px;
                margin: 0 auto;
            }
        }
    }

    .referal-code,
    .referal-footer {
        background: $secondary;
        color: $white;
        overflow: hidden;

        .copy-text {
            color: $white;
            font-family: $font-bold;
        }

        h1 {
            font-family: $font-bold;
        }
    }

    .referal-footer {
        font-size: 11px;
        color: $gray-550;
    }
}

.match-detail-section {
    display: table;
    margin: 20px 0;

    .match-sub-detail-section {
        display: table-cell;
        width: 25%;
        text-align: center;
        padding: 10px;
        vertical-align: middle;

        img {
            max-width: 100%;
        }

        &.team-detail {
            width: 50%;
            padding: 10px 0;
        }
    }

    .team-detail {
        text-align: center;

        .leaguename,
        .match-schedule {
            color: $gray-550;
            font-size: 12px;
            font-family: $font-bold;
        }

        .team-name {
            color: $black;
            font-family: $font-xtrabold;
            font-size: 26px;
        }
    }
}

.win-prize {
    color: $white;
    font-size: 16px;
    font-family: $font-xtrabold-italic;
    padding: 22px;
    margin: 0 auto -30px;
    width: 100%;
    position: relative;

    .ribbon {
        font-size: 16px !important;
        /* This ribbon is based on a 16px font side and a 24px vertical rhythm. I've used em's to position each element for scalability. If you want to use a different font size you may have to play with the position of the ribbon elements */
        width: 180px;
        height: 60px;
        position: relative;
        background: $secondary;
        color: #fff;
        border-radius: 0;
        text-align: center;
        padding: 20px 0;
        /* Adjust to suit */
        margin: 0 auto;
        /* Based on 24px vertical rhythm. 48px bottom margin - normally 24 but the ribbon 'graphics' take up 24px themselves so we double it. */
    }

    .ribbon:before,
    .ribbon:after {
        content: "";
        position: absolute;
        display: block;
        bottom: 4px;
        border: 20px solid $secondary;
        z-index: -1;
    }

    .ribbon:before {
        left: -42px;
        border-right-width: 21px;
        border-left-color: transparent;
    }

    .ribbon:after {
        right: -42px;
        border-left-width: 20px;
        border-right-color: transparent;
    }

    .ribbon .ribbon-content:before,
    .ribbon .ribbon-content:after {
        content: "";
        position: absolute;
        display: block;
        border-style: solid;
        border-color: $secondary transparent transparent transparent;
        bottom: 0;
    }

    .ribbon .ribbon-content:before {
        left: -20px;
        border-width: 60px 0 0 20px;
    }

    .ribbon .ribbon-content:after {
        right: -25px;
        border-width: 60px 25px 0 0;
    }
}