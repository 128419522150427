html,
body {
    height: 100%;
}

body {
    background: $gray-170;
    background: url("../img/connection-bg.png");
    background-size: cover;
    background-attachment: fixed;
}

.Site-content {
    background: transparent;
    max-height: 100vh;

    @media (max-width: 768px) {
        background: #fff;
    }
}

.web-container {
    width: 100%;
    max-width: 768px;
    min-height: 100vh;
    width: 100%;
    margin: 0 auto;
    overflow: hidden;
    background: $gray-170;
    position: relative;
    padding-top: 58px;
    box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.1);
    padding-bottom: 55px;
    &.position-unset {
        position: initial;
    }

    &.transparent-header {
        .header-wrap {
            background-color: transparent;
        }
    }

    .bottom {
        position: fixed;
        bottom: 0;
        width: 100%;
        max-width: 768px;
        z-index: 9;
        border-radius: 0px;
        font-size: 14px;
    }

    &.web-container-white {
        background: $white;
    }
}

.white-bg {
    background: $white;
}

.bg-offwhite {
    background: $gray-170 !important;
}

.display-table {
    display: table;
    width: 100%;
}

.display-table-cell {
    display: table-cell;

    &.v-mid {
        vertical-align: middle;
    }
}

.countdown-start {
    color: $red;
    font-size: 14px;
    font-family: $font-bold;
}

.verses {
    color: $gray-200;
    font-size: 14px;
    font-family: $font-bold;
    display: inline-block;
    width: 30px;
}

.text-small-italic {
    font-size: 10px;
    font-family: $font-bold-italic;
    color: $gray-200;
}

.progress-bar-default {
    &.display-table {
        width: 70%;
    }

    .progress {
        background-color: rgba(51, 51, 51, 0.1);
        border-radius: 2.5px;
        height: 4px;
        width: 90%;

        &.danger-area {
            .progress-bar {
                background-color: $dark-red;
            }
        }
    }

    .progress-bar {
        border-radius: 2.5px;
        background-color: $primary;
    }

    .progress-bar-value {
        color: $gray-900;
        font-size: 11px;
        font-family: $font-bold-italic;
        margin-top: 5px;
        position: relative;
    }

    .total-entries {
        color: $gray-550;
        font-size: 12px;
    }

    .total-output {
        color: $gray-900;
        font-size: 16px;
    }

    .min-entries {
        font-size: 12px;
        color: #a9a9a9;
        position: absolute;
        font-family: $font-bold;
        font-style: italic;
        right: 9%;
        top: -1px;

        @media (min-width: 415px) {
            right: 10%;
            left: auto;
        }
    }
}

.slick-arrow.slick-next {
    display: none !important;
}

.slick-arrow.slick-prev {
    display: none !important;
}

.match-heading {
    .display-table-cell {
        &:first-child,
        &:last-child {
            width: 25%;
        }

        img {
            max-width: 35px;
            max-height: 35px;
        }

        .team-name {
            font-size: 12px;
            color: $gray-900;
        }
    }
}

.winner-count {
    font-size: 10px;
    font-family: $font-bold-italic;
    color: $gray-550;
}

.modal-close {
    position: absolute;
    font-size: 12px;
    left: 20px;
    top: 50%;
    transform: translateY(-50%);
    width: 20px;
    text-align: center;
}

.icon-g {
    color: $green;
}

.icon-m {
    color: $yellow;
}

.share-banner {
    background: $white;
    color: $gray-900;
    font-family: $font-bold;
    border-radius: 12px;
    padding: 8px 15px;
    font-size: 14px;

    @media (max-width: 767px) {
        font-size: 16px;
    }

    .share-text {
        max-width: 70%;
        line-height: 24px;

        @media (max-width: 414px) {
            line-height: 18px;
        }
    }

    span {
        color: $teal;
    }

    .btn {
        float: right;
        background: transparent;
        color: $primary;

        @media (min-width: 768px) {
            padding: 4px 14px;
        }

        @media (max-width: 414px) {
            margin-top: 5px;
        }
    }
}

.input-transparent {
    input {
        background: transparent !important;
    }
}

.page-footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 200;
    max-width: 768px;
    cursor: pointer;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);

    &.page-footer-absolute {
        position: absolute;
    }

    .btn {
        border-radius: 0;
        font-size: 14px;
        padding: 12px;

        &.btn-default {
            background-color: $gray-450;
            color: $primary;
            font-family: $font-black;
        }

        &.btn-paystack-text {
            font-family: $font-black;
            pointer-events: none;

            &.btn-success:disabled {
                background: linear-gradient(90deg, #e5e5e5 0%, #e5e5e5 60%, #e5e5e5 90%);
                box-shadow: none;
            }
        }

        &.disabled,
        &:disabled {
            color: #a0a0a0;
        }

        &.btn-primary {
            color: $white;
        }
    }

    &.zIndex {
        z-index: 6;
    }

    &.zIndex9999 {
        z-index: 9999;
    }
}

.form-group {
    margin-bottom: 25px;
}

.input-hint {
    position: absolute;
    bottom: 7px;
    right: 0;
    font-size: 11px;
    color: $gray-200;
    font-family: $font-italic;
}

.list-card {
    background: $white;
    border-radius: 14px;
    box-shadow: 0 16px 16px 0 rgba(0, 0, 0, 0.05);
    padding: 12px 15px;
    margin-bottom: 15px;
    color: $gray-900;
    font-size: 14px;

    &.list-card-with-img {
        padding-left: 48px;
        position: relative;

        figure {
            width: 32px;
            height: 32px;
            border-radius: 50%;
            overflow: hidden;
            background-color: #25316a;
            line-height: 32px;
            left: 8px;

            img {
                width: 32px;
                height: 32px;
                object-fit: cover;
            }
        }
    }

    p {
        margin-bottom: 0;
    }
}

.list-data {
    font-size: 12px;
    color: $gray-900;
    font-family: $font-bold;

    p {
        margin: 0;
        color: $gray-600;
        font-family: $font-italic;
    }
}

.text-dark-blue {
    color: $primary !important;
}

.font-12 {
    font-size: 12px !important;
}

.page-subheading {
    font-size: 11px;
    color: $gray-900;
    font-family: $font-black;
    text-transform: uppercase;
    background: $light-gray;
    padding: 5px 15px;
    letter-spacing: 1px;
}

.dot-class {
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: $black;
    display: inline-block;
    position: relative;
    top: -1px;
    margin: 0 10px;

    @media (max-width: 767px) {
        margin: 0 6px;
    }
}

.font-16 {
    font-size: 16px !important;
}

.custom-radio-group {
    input[type="radio"] {
        position: absolute;
        opacity: 0;
    }

    .btn {
        border-radius: 50px !important;
        background: $border-light;
        color: $gray-675;
        font-size: 14px;
        padding: 12px 10;
        text-align: center;
        width: 105px;
        margin-left: 10px !important;
        height: 44px;
        line-height: 1.3;
        font-family: $font-black;
        letter-spacing: 1.27px;
        margin-bottom: 10px;

        &:first-child {
            margin-left: 0 !important;
        }

        @media (max-width: 428px) and (min-width: 375px) {
            &:nth-child(3n + 1) {
                margin-left: 0 !important;
            }
        }

        @media (max-width: 374px) {
            margin-left: 10px !important;

            &:nth-child(2n + 1) {
                margin-left: 0 !important;
            }
        }

        &.active {
            box-shadow: 1px 2px 7px 0 rgba(0, 0, 0, 0.1), 0 16px 16px 0 rgba(0, 0, 0, 0.02);
            background: $white;
            color: $secondary;
        }
    }

    [type="button"] {
        -webkit-appearance: none;
    }

    .btn-group {
        flex-wrap: wrap !important;
        margin: 10px auto;
        justify-content: center;

        @media (max-width: 360px) {
            padding: 0 20px;
        }
    }
}

.disclaimer-text {
    color: $gray-750;
    font-size: 12px;
    font-family: $font-italic;
}

figure {
    &.square100 {
        width: 100px;
        height: 100px;
    }
}

.join-league-wrapper {
    padding: 15px;

    .league-data {
        margin-top: 60px;
        margin-bottom: 50px;
    }

    h1 {
        font-size: 20px;
        color: $gray-900;
        font-family: $font-black;
    }

    p {
        font-size: 12px;
        font-family: $font-italic;
        color: $gray-750;
    }
}

.slide-pane__overlay.ReactModal__Overlay--after-open {
    max-width: 768px;
    margin: 0 auto;
}

.react-date-picker__calendar-button {
    svg {
        display: none;
    }

    &:before {
        content: "\e95e";
        font-family: "icomoon" !important;
        font-size: 20px;
    }
}

.modal-footer {
    z-index: 9;
}

.text-small {
    font-size: 10px;
    color: $gray-550;
}

.select__value-container {
    font-size: 12px;
    text-align: center;

    .select__placeholder {
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 80%;
    }
}

.secondary__menu {
    z-index: 9999 !important;
    left: 0 !important;
}

.fb-label {
    color: $fb-color;
}

.mt-20per {
    margin-top: 20% !important;
}

.icon-next-btn {
    font-size: 52px;
}

.dual-btn-footer {
    padding: 5px 0;

    a {
        font-size: 11px;
        border-radius: 0;
        background: transparent;
        margin: 0;
        box-shadow: none;
        width: 50%;
        color: $white;
        text-transform: uppercase;
        text-align: center;
        padding: 10px;
        font-family: $font-bold;

        &:nth-child(2) {
            border-left: 1px solid #fff;
        }

        &.single-text {
            width: 100%;
        }
    }
}

.select__indicators {
    position: absolute;
    right: 0;
}

.select__menu {
    z-index: 999 !important;
    position: relative;
}

.confirmation-modal {
    .select__indicators {
        top: -8px;
        right: -8px;
    }
}

.circular-close {
    width: 24px;
    height: 24px;
    display: inline-block;
    background: $gray-550;
    border-radius: 50px;
    text-align: center;
    line-height: 22px;

    i {
        color: $white;
        font-size: 10px;
    }
}

.pancard-img {
    .circular-close {
        position: absolute;
        top: -6px;
        left: 90px;
    }
}

.account-wrapper {
    .dual-action-btn {
        padding: 0 15px;

        .btn {
            box-shadow: 0 16px 16px 0 rgba(0, 0, 0, 0.05);
        }
    }
}

.date-picker-custom {
    width: 100%;

    .react-date-picker__wrapper {
        width: 100% !important;
        margin: 0 auto;
    }

    .react-date-picker__calendar {
        @media (max-width: 360px) {
            left: -5% !important;
        }
    }
}

.dob-date-picker {
    z-index: 1001;
}

.react-date-picker__inputGroup__input {
    width: 80px !important;
}

.react-select__menu {
    border: 10px solid red !important;
}

.modal-header {
    button.close {
        top: -12px;
        right: -10px;
        background-color: $white;
        box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.1);
    }
}

.imp-text {
    p {
        margin: 0;
        font-size: 12px;
        font-family: $font-bold;
    }

    padding-bottom: 50px;
}

.cursor-pointer {
    cursor: pointer;
}

.toast-notification {
    margin: 0 auto !important;
    transform: translate(0) !important;
    width: 100% !important;
    max-width: 768px !important;
    z-index: 9999;

    @media (max-width: 767px) {
        width: 100vw !important;

        span {
            width: 100vw !important;
            border-radius: 0 !important;
        }
    }
}

.modal,
.modal-backdrop {
    max-width: 768px !important;
    left: 50%;
    transform: translateX(-50%);
    margin: 0;
    width: 100%;
}

.payment-method-img {
    width: 100%;
    max-width: 300px;
    margin-top: 40px;

    @media (max-width: 428px) {
        max-width: 240px;
    }
}

.promo-code-text {
    font-family: $font-italic;
    font-size: 12px;
    color: $gray-550;
    max-width: 80%;
    margin: 10px auto;
    text-align: center;
}

.input-with-cancel {
    i {
        font-size: 18px;
        position: absolute;
        bottom: 12px;
        right: 10px;
        cursor: pointer;
    }
}

.cursor-default {
    cursor: default;
}

.bold-text {
    font-weight: bold;
}

.input-label-center.form-group.default-floting {
    label {
        span {
            padding: 0 0 13px !important;
        }
    }
}

.league-select,
.match-list {
    z-index: 2;
    position: relative;

    .react-select__menu {
        z-index: 99999;
    }
}

.league-select {
    z-index: 3;
}

.contest-name-input {
    z-index: 1;
    position: relative;
}

label.custom-label {
    margin: 0;
    font-size: 12px;
    line-height: 18px;
    color: rgb(105, 105, 105);
}

.stateStyle {
    min-height: 38px;
}

.default-selected {
    .select__indicators {
        display: none;
    }
}

.contest-max-team {
    background-color: $gray-120;
    border-radius: 15px;
    text-align: center;
    color: $gray-900;
    font-size: 12px;
    font-family: $font-bold;
    padding: 6px;
}

.contest-name-heading {
    color: $gray-900;
    font-size: 12px;
    font-family: $font-bold-italic;
    margin-bottom: 10px;
    margin-left: 3px;
    position: relative;

    .icon-m,
    .icon-g,
    .icon-share {
        margin-left: 10px;
        display: inline-block;
        position: absolute;
        top: 0;
        font-size: 16px;
    }

    .icon-m,
    .icon-g {
        right: 20px;
    }

    .icon-m {
        & ~ .icon-g {
            margin-right: 20px;
        }
    }

    .icon-share {
        right: -4px;
        color: #9e9e9e;
    }
}

.download-btn-header {
    height: 40px;
    width: 100%;
    background-color: $primary;
    background-image: linear-gradient(to right, $primary, #0b9ad4);
    position: relative;
    z-index: 2;
}

.app-name-txt {
    font-family: $font-bold;
    color: white;
    margin-left: 20px;
    padding-top: 10px;
}

.d-button {
    border-radius: 3px;
    border-color: white;
    border: 2px;
    margin-left: 20px;
}

.icon-close-size {
    align-items: flex-end;
    margin-left: 30px;
    color: white;
    padding-bottom: 10px;
    vertical-align: baseline;
    line-height: 0;
}

.have-league-wrapper {
    .title {
        h4 {
            font-size: 20px;
            font-family: $font-black;
            text-transform: uppercase;
            color: $gray-900;
            margin-bottom: 20px;
        }

        p {
            font-size: 12px;
            font-family: $font-italic;
            color: $gray-550;
            margin-bottom: 50px;
        }
    }
}

.email-status {
    position: absolute;
    right: 20px;
    width: auto !important;
    top: 0;
    font-size: 11px;
}

.withdraw-wrapper {
    .non-editable-area {
        padding: 20px;
        border-radius: 10px;
        background: $gray-570;

        .input-label-center:not(.label-left) span {
            color: $gray-200 !important;
        }

        .form-group.font-14 input {
            border-bottom: none !important;
            font-size: 16px !important;
        }
    }
}

.bank-withdraw {
    .secondary__placeholder,
    .secondary__single-value {
        font-size: 16px !important;
    }
}

.empty-screen {
    text-align: center;

    .img-part {
        padding: 30px 0 50px;
        background-position: 0 -30px;
        background-size: cover;
    }

    h2 {
        font-size: 16px;
        font-family: $font-bold-italic;
        text-transform: uppercase;
        color: $gray-900;
        margin-bottom: 15px;
    }

    p {
        margin-bottom: 30px;
        font-size: 12px;
        font-family: $font-italic;
        color: $gray-550;
    }

    .btn.btn-rounded.btn-success {
        padding: 15px 60px;
        font-size: 12px;
        border-radius: 22px;
        background: $green;
        color: $white;
        position: static;
    }
}

a.click-disabled {
    pointer-events: none;
}

.wrapper-spacing {
    padding: 15px;
}

.modal-title {
    padding: 15px 10px;

    .vissel-img {
        width: 45px;
    }

    position: relative;
}

.heading-section {
    font-size: 14px;
    font-family: $font-black;
    color: $secondary;
    margin: 15px 0;
    position: relative;

    i.icon-search {
        position: absolute;
        right: 10px;
        font-size: 18px;
    }
}

.heading-section-alt {
    font-size: 14px;
    font-family: $font-bold;
    color: $white;
    background: $secondary;
    padding: 15px;
}

.circle-social-ic {
    display: inline-block;
    border-radius: 50%;
    border: 1.2px solid $gray-550;
    padding: 3px;
    text-align: center;
    width: 30px;
    height: 30px;
    font-size: 14px;
    color: $gray-550;
}

.blog-social-ic {
    .circle-social-ic {
        margin-left: 8px;
    }
}

.heading-section-sm {
    background: $white;
    padding: 15px;
    color: $gray-900;
    font-size: 14px;
    font-family: $font-bold;
    box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.1);
    position: relative;

    span {
        color: $red;
    }
}

.section-txt {
    font-size: 12px;
    font-family: $font-bold-italic;
    color: $gray-900;
    text-align: center;
    max-width: 80%;
    margin: 0 auto 10px;
}

.default-tab {
    ul.nav-tabs {
        li {
            width: 33.33%;
            background: $white;
            padding: 0 15px;

            a {
                width: 100%;
                display: inline-block;
                padding: 15px 5px;
                color: $gray-675;
                font-family: $font-black;
                font-size: 11px;
                letter-spacing: 0.92px;
                text-transform: uppercase;
                text-align: center;
            }

            &.active {
                a {
                    position: relative;
                    color: $secondary;

                    &:before {
                        content: "";
                        background: $primary;
                        height: 3px;
                        width: 100%;
                        left: 0;
                        position: absolute;
                        bottom: 0px;
                    }
                }
            }
        }
    }

    &.default-tab-two {
        ul.nav-tabs {
            li {
                width: 50%;
            }
        }
    }

    &.less-padding {
        ul.nav-tabs {
            li {
                padding: 0;
            }
        }
    }
}

.circular-tab {
    .nav.nav-tabs {
        border-radius: 22px;
        background: $white;
        border-bottom: 0;
        box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.15);

        li {
            width: 33.33%;
            border-radius: 22px;
            padding: 0;

            a {
                width: 100%;
                display: inline-block;
                padding: 8px;
                text-align: center;
                color: $gray-675;
                border-radius: 22px;
                font-size: 11px;
                font-family: $font-black;
                text-transform: uppercase;
            }

            &.active {
                a {
                    background: linear-gradient(73.19deg, #00af8b 0%, #00ff87 100%);
                    color: $white !important;

                    &:before {
                        display: none;
                    }

                    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2), 0 5px 10px 0 rgba(0, 0, 0, 0.1);
                }
            }
        }
    }
}

.full-width-input {
    padding: 20px;

    .form-group {
        label {
            width: 100%;
        }

        &.input-label-center:not(.label-left) input {
            width: 100%;
        }
    }
}

.primary-color {
    color: $primary !important;
}

.icon-star {
    color: $gray-460;

    &.highlighted {
        color: $fav-color;
    }
}

.input-label-center {
    .secondary__value-container {
        text-align: center !important;
    }
}

.form-group.has-error {
    .select-gender {
        .gender-border {
            border-bottom-color: $red;
        }
    }
}

.form-group {
    .select-gender {
        &:focus {
            .gender-border {
                border-bottom-color: rgba(153, 153, 153, 1) !important;
            }
        }
    }
}

.select-secondary {
    z-index: unset;

    .secondary__menu {
        z-index: 9999 !important;
    }
}

.secondary__value-container {
    z-index: 9;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type="number"] {
    -moz-appearance: textfield;
}

.help-text {
    font-size: 11px;
    font-family: $font-italic;
    color: $gray-550;
    position: relative;
    top: -20px;
    text-align: center;
}

.double-input-container {
    .input-label-center:not(.label-left) {
        input {
            padding: 16px 15px 10px !important;
        }
    }

    .dual-input-separater {
        position: absolute;
        width: 1px;
        right: 0;
        top: 0;
        height: 42px;
        background: rgba(153, 153, 153, 0.5);
    }
}

.select-field-default {
    @extend .select-gender;

    .gender-border {
        border-bottom: 1px solid rgba(153, 153, 153, 0.5);
        margin-top: -3px;
    }

    .secondary__placeholder,
    .secondary__single-value {
        left: 0;
        right: 0;
        font-size: 14px;
        font-family: $font-bold;
        color: $gray-900;
    }

    .select-arr {
        width: 20px !important;
    }

    .secondary__single-value {
        color: $gray-900;
    }

    &.input-label-center:not(.label-left) span {
        .select-arr {
            width: 20px !important;
        }
    }

    label {
        color: $gray-200 !important;
    }
}

.dob-date-picker .datepicker_display.datepicker_display_default {
    > label {
        color: $gray-200;
    }
}

.textarea-input .control-label {
    width: 100%;
    margin-bottom: 0;
    color: $gray-200;
}

textarea.form-control {
    border-radius: 0;
    border: 0;
    border-bottom: 1px solid $gray-500;
    resize: none;
    text-align: center;
}

.profile-btn-section {
    .btn {
        border-radius: 22px;
        padding: 13px 50px;
        font-size: 14px;
    }
}

.modal-txt {
    font-size: 13px;
    color: $white;
    text-align: center;
    max-width: 520px;
    margin: 0 auto 20px;
}

.modal-body {
    a {
        &:hover,
        &:focus,
        &:active,
        &.active {
            color: $ternary !important;
        }

        &.btn {
            &:hover,
            &:focus,
            &:active,
            &.active {
                color: $white !important;
            }
        }
    }
}

.custom-date-picker {
    .react-date-picker__inputGroup {
        opacity: 0;
    }

    .dobField {
        position: relative;
    }

    .dob-text {
        position: absolute;
        top: -4px;
        bottom: 0;
        left: 0;
        right: 0;
        text-align: center;
        cursor: pointer;
        font-family: $font-bold;
        padding: 12px;
    }
}

.verify-text {
    font-size: 12px;
    color: $success;
    position: absolute;
    right: 10px;
    top: 25px;
    cursor: pointer;
}

body.modal-open {
    .Site-content {
        //  overflow: hidden;
        // padding-left: 16px;
    }

    .modal {
        overflow-y: auto;
    }
}

.custom-checkbox,
.custom-checkbox ~ span a .custom-checkbox {
    &.i-agree-text {
        input[type="checkbox"],
        input[type="radio"] {
            ~ span {
                a {
                    color: $primary !important;
                    margin-right: 5px;

                    &:last-child {
                        @media (max-width: 428px) {
                            margin-left: 0;
                        }

                        @media (max-width: 320px) {
                            margin-left: 5px;
                        }
                    }

                    @media (max-width: 320px) {
                        &:first-child {
                            margin-left: 0px;
                        }
                    }
                }
            }
        }
    }
}

.rkfloatingLabelInput {
    flex-direction: column;
    box-sizing: border-box;
    display: inline-block;
    font-family: MuliRegular;
    padding-top: 5px;
    position: relative;
    padding-bottom: 0px;
    margin-bottom: 0px;
    width: 100%;
    font-size: 12px;
    color: rgba(255, 255, 255, 0.8);

    &.editp {
        color: rgb(51, 51, 51);

        input {
            color: rgb(51, 51, 51);
        }

        span {
            &.on {
                color: rgb(158, 158, 158);
            }
        }
    }

    &.withdraw {
        color: rgb(51, 51, 51);

        input {
            color: rgb(51, 51, 51);
            margin-top: 3px;
        }

        span {
            color: rgb(105, 105, 105);

            &.on {
                color: rgb(126, 126, 126);
            }
        }
    }

    span {
        position: absolute;
        top: 0px;
        left: 0;
        font-size: 1rem;
        pointer-events: none;
        padding: 17px 0px 13px;

        transition: font-size 200ms ease 0s, padding 200ms ease 0s;

        &.on {
            padding: 0;
            font-size: 12px;
            color: rgba(255, 255, 255, 0.6);
        }
    }

    input {
        border-top-width: initial;
        border-right-width: initial;
        border-bottom-width: 1px;
        border-left-width: initial;
        border-style: none none solid;
        border-image: initial;
        box-sizing: border-box;
        font-family: MuliBold;
        font-size: 16px;
        padding: 16px 0px 10px;
        width: 100%;
        color: rgb(255, 255, 255);
        background-color: transparent;
    }
}

.no-data-container {
    img {
        margin-bottom: 30px;
        max-width: 195px;
    }

    h3 {
        font-size: 16px;
        font-family: $font-bold-italic;
        color: $gray-900;
        margin-bottom: 5px;
        text-transform: uppercase;
    }

    .btn {
        padding: 15px 20px;
        border-radius: 22px;
        font-size: 14px;
        margin-top: 30px;
        color: $white;
    }
}

.filter {
    background-color: $secondary;
    color: $secondary;

    .filterStyle {
        .select-secondary {
            padding: 2px;
            text-align: center;
        }

        .secondary__single-value {
            color: $primary;
            width: 100%;
        }
    }

    .input-label-center .secondary__value-container {
        padding: 0 !important;
    }

    span.select-arr {
        width: 35px;
        height: 35px;
        background-color: $primary;
        top: 0;
        right: 0;
        text-align: center;
        line-height: 34px;
        color: $secondary;
        transform: translateX(0);
    }
}

.video-body {
    background: $light-gray !important;
    width: 100% !important;
    height: 360px !important;

    @media (max-width: 767px) {
        height: 300px !important;
    }
}

.skip-ad ~ div {
    text-align: center;
}

.select-more-modal {
    .genderStyle {
        .select-secondary {
            text-align: center;
        }
    }
}

.input-hide {
    position: absolute;
    right: 10px;
    bottom: 16px;
    font-size: 12px;
    color: #b4b4b4;
}

.btn {
    position: relative;

    &:before {
        position: absolute;
        content: "";
        width: 100%;
        height: 100%;
        background: rgba(255, 255, 255, 0.5);
        border-radius: inherit;
        transform: scale(0);
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: 0 auto;
    }

    &:active {
        &:before {
            animation: ripple-in2 2s;
        }
    }
}

@keyframes ripple-in2 {
    0% {
        transform: scaleX(0);
    }

    20% {
        transform: scaleX(1);
        opacity: 0.3;
    }

    100% {
        transform: scaleX(1);
        opacity: 0;
    }
}

.tooltip-info {
    color: $gray-200;
    margin-left: 5px;
    float: none !important;
    position: relative;
    top: -1px;
    text-shadow: none;
}

.filter-with-logo {
    position: relative;
    top: -1px;
    margin-right: 3px;
}

.icon-infinite {
    margin-left: 5px;
}

.icon-logo {
    position: relative;

    &:before {
        display: none;
    }

    &:after {
        content: "";
        display: inline-block;
        width: 25px;
        height: 15px;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        text-align: center;
        background: url("../img/logo.png") no-repeat;
        background-size: contain;
        background-repeat: no-repeat;
    }
}

.tspan {
    color: $white !important;
}

input {
    &:focus,
    &:hover {
        border-color: rgba(2, 51, 32, 0.5) !important;
    }
}

.opacity {
    opacity: 0.6;
}

.no-data-outer-wrapper {
    display: table;
    width: 100%;
    height: 100%;
    min-height: calc(100vh - 191px);
    text-align: center;
    vertical-align: middle;
    padding: 20px 0;

    .no-data-container {
        display: table-cell;
        vertical-align: middle;
        height: 100%;
    }

    &.no-data-outer-wrapper-lg {
        min-height: calc(100vh - 298px);
    }

    &.no-data-outer-wrapper-xlg {
        min-height: calc(100vh - 330px);
    }
}

.refpay-web-container {
    .no-data-outer-wrapper {
        min-height: calc(100vh - 501px);
    }
}

.leaderboard-wrapper {
    .no-data-outer-wrapper {
        min-height: calc(100vh - 541px);
    }
}

.profile-web-container {
    .no-data-outer-wrapper {
        min-height: calc(100vh - 550px);
    }
}

.edit-prediction-text {
    text-align: center;
    padding: 7px 22px 20px;
    font-size: 12px;
    color: $gray-900;
    font-family: $font-bold-italic;
}

.phone-group {
    display: flex;
}
.con-sel {
    width: 100px;
}
.genderStyle {
    .css-1g6gooi {
        width: 100%;
    }
    .secondary__input {
        #react-select-3-input {
            color: #fff !important;
            text-align: center;
            width: 100% !important;
        }
    }
}

.wp-mod-sh {
    text-align: center;
    margin-bottom: 10px;
    .ic-grp {
        background: rgba(0, 0, 0, 0.6);
        display: inline-block;
        padding: 6px 10px;
        border-radius: 30px;
        min-height: 36px;
        span {
            margin: 0 7px;
        }
    }
}

.lg-wd-ref {
    text-align: center;
    padding-top: 30px;

    .hdng_1 {
        color: #fff;
        color: #ffffff;
        font-size: 14px;
        font-weight: bold;
        margin-bottom: 30px;

        .tx {
            position: relative;
            &:before,
            &:after {
                content: "";
                position: absolute;
                width: 82px;
                height: 1px;
                background: #fff;
                margin-top: 10px;
            }

            &:before {
                left: -90px;
            }
            &:after {
                right: -90px;
            }
        }

        @media (max-width: 540px) {
            .tx {
                &:before,
                &:after {
                    width: 60px;
                }
                &:before {
                    left: -65px;
                }
                &:after {
                    right: -65px;
                }
            }
        }
    }
}
.btn.ref-id-btn {
    margin: 0 auto;
    min-height: 44px;
    min-width: 198px;
    border-radius: 22px;
    background-color: #37003c;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2);
    color: #fff;
    font-size: 14px;
    font-weight: 800;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
        width: 32px;
        margin-right: 5px;
    }
}

.complete-profile-modal {
    .f-gr-area {
        background: $primary;
        padding: 20px 15px;
        text-align: center;
        position: relative;
        .logo {
            height: 40px;
            margin: 0 auto;
            margin-bottom: 15px;
        }

        .modal-close-btn {
            margin-right: 15px;
            margin-top: 15px;
        }
    }

    .tabie-ar {
        display: flex;
        align-items: center;
        position: relative;
        max-width: 350px;
        z-index: 0;
        margin: 0 auto;
        margin-top: 10px;
        &:before {
            background-color: #37003c;
            height: 1px;
            position: absolute;
            content: "";
            left: 50px;
            width: calc(100% - 100px);
            top: 30px;
            z-index: -1;
        }
        .tbie {
            text-align: center;
            flex: 1;
            cursor: pointer;
            span {
                height: 40px;
                width: 40px;
                background-color: #37003c;
                border-radius: 50%;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                img {
                    width: 18px;
                }
            }

            div {
                margin-top: 10px;
                font-size: 12px;
                font-weight: bold;
            }

            &.completed {
                span {
                    background-color: #ff005a;
                }
            }

            &.active {
                span {
                    height: 60px;
                    width: 60px;
                    img {
                        width: 32px;
                    }
                }
                div {
                    font-size: 14px;
                    font-weight: 900;
                    font-family: "MuliBold";
                }
            }
        }
    }
}

.cp-tab {
    display: none;
    &.active {
        display: block;
    }
}

.mem-tab-ar {
    background: $primary;
    display: flex;
    padding: 0 2px;
    overflow-x: auto;
    .mem-tab {
        height: 44px;
        border-radius: 6px 6px 0 0;
        background-color: #37003c;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 5px 15px;
        flex: 1;
        border: solid 2px $primary;
        color: #fff;
        font-weight: bold;
        text-transform: uppercase;
        border-bottom: none;
        cursor: pointer;
        min-width: 120px;
        font-size: 12px;
        &.active {
            // background: #000;
            color: $primary;
        }
    }
}

.cp-profile-modal {
    &.member-tab-active {
        .modal-content .modal-overlay {
            background: #fff;
        }
    }
    .modal-body {
        padding: 0 !important;
    }
}

.mem-tab-body {
    padding: 15px 50px;

    .mem-tab-dv {
        display: none;
        // padding: 30px 15px;
        border-radius: 10.89px;
        background-color: #ffffff;
        box-shadow: 0 16px 16px 0 rgba(0, 0, 0, 0.1);
        padding-top: 50px;
        min-height: 300px;
        position: relative;
        &.active {
            display: block;
        }

        &:before,
        &:after {
            content: "";
            height: 80%;
            width: 60px;
            border-radius: 10.37px;
            position: absolute;
            background-color: #ffffff;
            box-shadow: 0 16px 16px 0 rgba(0, 0, 0, 0.1);
            top: 10%;
        }

        &:before {
            left: -80px;
        }

        &:after {
            right: -80px;
        }

        .head-part {
            background: url("../img/membership-bg.png") no-repeat;
            background-size: cover;
            background-position: center bottom;
            padding: 20px 20px 70px;
            height: 230px;
        }
    }
}

.mem-tab-dv,
.mem-tab-dv2 {
    .mm-hdng {
        color: $secondary;
        font-family: MuliRegular;
        font-size: 18px;
        font-style: italic;
        font-weight: bold;
        letter-spacing: 0;
        text-align: center;
    }

    .fr-h {
        color: $secondary;
        font-family: MuliRegular;
        font-size: 40px;
        font-style: italic;
        font-weight: bold;
        letter-spacing: 0;
        text-align: center;
    }

    .feat-dv {
        min-height: 250px;
        display: flex;
        align-items: center;

        .feats {
            padding-left: 0;
            padding: 0 10px;
            width: 100%;
            li {
                list-style-type: none;
                text-align: center;
                margin-bottom: 15px;
                margin-top: 15px;
                font-size: 12px;
                &.bold {
                    font-weight: bold;
                    font-family: "MuliBold";
                }
            }
        }
    }

    .old-plan {
        text-align: center;
        margin-top: 10px;
        cursor: default;
        opacity: 0.45;
        i {
            color: $primary;
            margin-right: 5px;
            font-style: normal;
        }
        span {
            color: #333333;
            font-family: MuliRegular;
            font-size: 20px;
            font-weight: 800;
            letter-spacing: 0;
        }
    }

    .offered-plan {
        text-align: center;
        margin-top: 10px;
        margin-bottom: 30px;
        i {
            font-size: 20px;
            color: $primary;
            margin-right: 5px;
            position: absolute;
            font-style: normal;
        }
        .vl {
            color: #37003c;
            font-family: "Muli-ExtraBold";
            font-size: 26px;
            padding-left: 15px;
        }
        .prd {
            color: #999999;
            font-family: MuliRegular;
            font-size: 12px;
        }
    }

    .prem-plan-types {
        display: flex;
        // align-items: center;
        justify-content: center;
        max-width: 300px;
        margin: 0 auto;
        margin-bottom: 30px;
        .pr-che {
            text-align: center;
            padding: 5px 20px;
            flex: 1;
            cursor: pointer;
            .lbl {
                display: block;
                color: #999999;
                font-family: "Muli-ExtraBold";
                font-size: 10px;
                text-transform: uppercase;
            }

            .vl {
                font-size: 16px;
                font-family: "Muli-ExtraBold";
                color: $secondary;
                margin-bottom: 7px;
                display: block;
                i {
                    color: $primary;
                }

                &.old {
                    color: #999999;
                }
            }

            .checkie {
                display: inline-flex;
                align-items: center;
                justify-content: center;
                height: 24px;
                width: 24px;
                background-color: #efefef;
                border-radius: 50%;
                img {
                    width: 13.71px;
                    opacity: 0;
                }
            }

            &.active {
                .checkie {
                    background-color: $primary;
                    img {
                        opacity: 1;
                    }
                }
            }

            @media (max-width: 540px) {
                padding: 5px 10px;
            }
        }
    }
}

.pln-bt-ar {
    text-align: center;
    padding: 50px;
    .plan-btn {
        min-height: 44px;
        width: 198px;
        border-radius: 22px;
        background: #40edd0;
        box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2);
        border: none;
        color: #000;
        font-family: MuliRegular;
        font-size: 14px;
        font-weight: bold;
        text-transform: uppercase;
        cursor: pointer;
        span {
            display: block;
            color: #37003c;
            font-size: 10px;
            font-family: "Muli-ExtraBold";
        }
    }
}

.lg-sec {
    display: none;
    &.active {
        display: block;
    }
}

.ref_id_grp {
    position: relative;

    input {
        padding-left: 40px !important;
        padding-right: 40px !important;
        // text-align: left;
    }
    .r-con {
        position: absolute;
        left: 0;
        color: $primary;
        width: 40px !important;
        font-size: 20px;
        bottom: 8px;
        font-weight: 900;
        border-radius: 5px;
        font-family: "Muli-ExtraBold";
    }
}

.phone-grp-reg {
    .phone_code_sel {
        margin-top: 10px;
        position: absolute;
        background: rgba(256, 256, 256, 0.15);
        width: 70px;
        border-radius: 30px;
        > div {
            padding-bottom: 0;
            margin-bottom: 0;
        }
        .secondary__single-value {
            color: #fff;
            font-family: "Muli-ExtraBold";
        }
        .secondary__placeholder {
            font-size: 12px;
        }
        .secondary__control {
            padding-right: 25px;
        }
        .select-arr {
            top: 6px !important;
            right: -13px !important;
        }

        &.has-error {
            border: solid 1px red;
        }
    }

    .phone_num {
        span,
        input {
            padding-left: 80px;
            padding-right: 80px;
        }
    }
}

.mem-tab-dv2 {
    display: none;
    &.active {
        display: block;
    }
}


.subscribe-checkgrp{
    display: flex;
    justify-content: center;
    .custom-checkbox{
        margin-right: 15px;
        span{color: #000 !important; font-weight: bold;}
    }
}

.offer-m {
    text-transform: none !important;
    color: #000;
    font-family: "MuliRegular";
    font-size: 10px;
}

.lb-ban-wrap {
    padding: 4px 0px;

    .slick-slide{
        padding: 0 4px;
        line-height: 0;
    }
}
.lb-banner {
    background: grey;
    // height: 80px;
    border-radius: 8px;
    overflow: hidden;
    // max-height: 250px;
    img {
        width: 100%;
    }
}


.benefic-select{
    .secondary__input{
        input#react-select-3-input{color: #000 !important;}
    }
}