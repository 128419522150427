// stylelint-disable selector-no-qualifying-type

.fade {
  @include transition($transition-fade);
  &.in {
    opacity: 1;
  }
  &:not(.in) {
    opacity: 0;
  }
}

.collapse {
  &:not(.in) {
    display: none;
  }
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  @include transition($transition-collapse);
}
