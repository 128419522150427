.inner-header-wrapper {
    background: $white;
    padding: 10px;
    margin-bottom: 20px;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);

    ul {
        margin-bottom: 0;
        list-style-type: none;
        padding-left: 0;
        display: table;
        height: 100%;
        width: 100%;

        li {
            display: table-cell;
            width: 33.33%;
            vertical-align: middle;
            color: $gray-900;
            text-align: center;
            font-size: 14px;
            font-family: $font-bold;
            text-transform: uppercase;
            position: relative;

            img {
                width: 20px;
                height: 20px;
                display: block;
                background: lightgrey;
                border-radius: 50%;
                margin: 0 auto 10px;
            }

            i {
                font-size: 20px;
                display: inline-block;
                margin-bottom: 10px;
            }

            span {
                display: block;
                padding: 0 12px;
            }

            &:not(:last-child) {
                &:after {
                    content: '';
                    position: absolute;
                    right: 0;
                    width: 1px;
                    height: 100%;
                    background: $border-light;
                    top: 0;
                    transform: rotate(8deg);
                }
            }
        }
    }
}

.predict-que {
    background: $white;
    margin-bottom: 10px;
    box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.1);
    padding: 12px 0 15px;

    h1 {
        font-size: 16px;
        font-family: $font-black;
        color: $gray-900;
        text-align: center;
        position: relative;

        span {
            position: absolute;
            border: 1px solid $border-light;
            border-left: 0;
            box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.1);
            font-size: 12px;
            font-family: $font-bold;
            color: $txt-secondary;
            text-align: center;
            padding: 3px;
            top: 0;

            &.predict-que-count {
                left: 0;
                width: 38px;
                border-radius: 0 100px 100px 0;
            }

            &.prediction-points {
                right: 0;
                width: 48px;
                border-radius: 100px 0 0 100px;
            }
        }
    }

    ul {
        margin-bottom: 0;
        padding-left: 0;
        list-style-type: none;

        li {
            display: inline-block;
            width: 33%;
            text-align: center;

            &.w50 {
                width: 50%;

                img {
                    width: 20px;
                    height: 20px;
                    border-radius: 50%;
                    background: lightgrey;
                    display: inline-block;
                    margin-right: 10px;
                }

                >span {
                    margin-right: 10px;
                }
            }
        }
    }
}

.prediction-options {
    .w50 {
        .form-group {
            border: 1px solid grey;
            display: inline-block;
            width: 70px;
            border-radius: 18px;

            .select-secondary {
                padding: 3px 4px;

                .secondary__menu,
                .secondary__menu-list {
                    height: 100px;
                }
            }

            &.input-label-center .secondary__placeholder {
                text-align: left;
                padding: 0 10px;
            }

            &.input-label-center:not(.label-left) span {
                width: 10px !important;
                right: 15px;
                top: 22%;
            }
        }
    }
}

.prediction-options {
    .form-group {
        margin-bottom: 0;
        margin-top: 10px;
    }

    .custom-checkbox {
        label {
            margin-bottom: 0;
        }

        input[type="checkbox"],
        input[type="radio"] {
            position: absolute; // take it out of document flow
            opacity: 0; // hide it

            &~span {
                position: relative;
                cursor: pointer;
                padding: 0;
                display: block;
                width: 100%;
                padding-right: 20px;
                max-width: 100%;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                width: 28px;
                height: 28px;
            }

            // Box.
            &~span:before {
                content: '';
                margin-right: 10px;
                display: inline-block;
                vertical-align: text-top;
                width: 28px;
                height: 28px;
                background: $white;
                border-radius: 50px;
                border: 1px solid $gray-460;
                margin: 0;
                position: absolute;
                right: 0;
            }

            // Box checked
            &:checked~span {
                color: $white;
            }

            // Disabled state label.
            &:disabled~span {
                color: $gray-550;
                cursor: auto;
            }

            // Disabled box.
            &:disabled~span:before {
                box-shadow: none;
                background: $gray-910;
            }

            // Checkmark. Could be replaced with an image
            &:checked~span:after {
                content: '';
                position: absolute;
                right: 13px;
                left: 50%;
                top: 50%;
                width: 14px;
                height: 14px;
                background: linear-gradient(75.01deg, $gradient-clr1 0%, $gradient-clr2 100%);
                border-radius: 50%;
                transform: translate(-50%, -50%);
                box-shadow: none;
            }
        }
    }
}

.web-container.prediction-web-container {
    &.prediction-fixed {
        padding-bottom: 140px;
        padding-top: 150px;

        .prediction-header {
            position: fixed;
            top: 56px;
            width: 100%;
            max-width: 768px;
            z-index: 1020;
        }
    }
}