.create-contest {
    background: $gray-170;

    .input-label-spacing .form-group input {
        padding-bottom: 8px !important;
    }

    .no-data-outer-wrapper {
        min-height: calc(100vh - 150px);

        &.no-data-outer-wrapper-lg {
            min-height: calc(100vh - 298px);
        }

        h3 {
            max-width: 300px;
            margin: 0 auto;
            line-height: 24px;
        }
    }

    .select-secondary-select__menu,
    .secondary__menu {
        z-index: 9999 !important;
    }

    .select-league {
        position: relative;
        z-index: 99999;

        .league-border {
            border-bottom: 1px solid #ECECEC;
            margin-top: -3px;
        }

        .secondary__placeholder {
            color: $gray-900;
        }

        .secondary__placeholder,
        .secondary__single-value {
            left: 0;
            right: 0;
            font-size: 16px;
            font-family: $font-bold;
        }
    }

    .select-match {
        .match-border {
            border-bottom: 1px solid #ECECEC;
            margin-top: -3px;
        }

        .secondary__placeholder,
        .secondary__single-value {
            left: 0;
            right: 0;
            font-size: 16px;
            font-family: $font-bold;
        }
    }

    .input-label-center:not(.label-left) span.select-arr {
        width: 20px;
        color: #888888;
    }

    .prize-pool {
        font-size: 12px;
        font-family: $font-bold;
        box-shadow: 0 16px 16px 0 rgba(0, 0, 0, 0.05);
        background: $white;
        border-radius: 14px;
        text-align: center;
        padding: 9px;
        text-transform: uppercase;
        color: $gray-600;
        width: 100%;
        margin: 0 auto;
        max-width: 350px;

        span:not(.prize-pool-label) {
            color: $secondary;
            font-size: 20px;
        }

        .prize-pool-label {
            position: relative;
            top: -3px;
        }
    }

    .prize-detail {
        table {
            margin-bottom: 80px;
        }

        .prize-heading {
            font-size: 20px;
            color: $gray-900;
            font-family: $font-xtrabold;
            display: block;
            text-align: center;
            margin-top: 15px;
            margin-bottom: 20px;

            span {
                position: relative;
                display: inline-block;
                width: 80%;
                overflow: hidden;
                text-transform: uppercase;

                &:before,
                &:after {
                    content: '';
                    height: 1px;
                    width: 100%;
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    background: $gray-500;
                }

                &:before {
                    left: -60%;

                    @media (max-width: 414px) {
                        left: -75%;
                    }
                }

                &:after {
                    right: -60%;

                    @media (max-width: 414px) {
                        right: -75%;
                    }
                }
            }
        }

        .table {
            th {
                border: none;
                color: $gray-550;
                text-transform: uppercase;
                font-family: $font-bold;
                font-size: 11px;
                padding: 9px 9px 0;
                width: 33.33%;

                &:first-child {
                    padding-left: 30px;
                }

                &:last-child {
                    padding-right: 30px;
                }
            }

            td {
                color: $gray-900;
                vertical-align: middle;
                font-size: 14px;
                font-family: $font-regular;
                width: 33.33%;
                padding: 0 0 16px;

                &:first-child {
                    padding-left: 30px;
                }

                &:last-child {
                    font-family: $font-bold;
                    padding-right: 30px;

                    span {
                        color: $gray-550;
                        font-family: $font-regular;
                        display: inline-block;
                        margin-right: 8px;
                    }
                }

                .winning-perc {
                    display: inline-block;
                    background: $white;
                    padding: 8px 15px;
                    border-radius: 4px;
                }

                &.winner-distribution-input {

                    // width: 50%;
                    &>div {
                        // width: 70px;
                        margin: 0 auto;
                        padding: 8px 10px;
                        box-shadow: 0px 11px 16px 0 rgba(0, 0, 0, 0.05);

                        label {
                            padding-top: 0;
                        }
                    }

                    input {
                        text-align: center !important;
                        padding: 6px 0 6px !important;
                        border: 0 !important;
                    }
                }
            }

            tr:not(:last-child) {
                td {
                    border: none;
                }
            }

            tr:first-child {
                td {
                    padding-top: 12px;
                }
            }

            tr:last-child {
                td {
                    padding-top: 12px;
                }
            }
        }
    }

    .page-footer {
        .btn {
            background: linear-gradient(75.01deg, $ternary 0%, $primary 100%);

            &:disabled {
                background: linear-gradient(90deg, #E5E5E5 0%, #E5E5E5 60%, #E5E5E5 90%);
                box-shadow: none !important;
            }
        }
    }

    .select-arr {
        top: 48%;
        -webkit-transform: translateX(-48%);
        transform: translateX(-48%);
    }

    .create-contest-form {
        padding: 25px;

        input {
            border-bottom: 1px solid #ECECEC !important;

            &:hover,
            &:focus {
                border-bottom-color: rgba(2, 51, 32, 0.5) !important;
            }
        }

        .has-error {
            input {
                border-color: theme-color("danger") !important;
            }

            .select-field-transparent {
                .select__control {
                    border-color: theme-color("danger") !important;
                }
            }

            .league-border {
                border-bottom: 1px solid theme-color("danger");
            }
        }
    }

    .input-label-center {
        label {
            text-align: center;
        }

        .select-secondary {
            .secondary__control {
                text-align: center;
            }
        }

        .secondary__value-container {
            height: 30px;
        }
    }

    .verification-block {
        min-height: 60px;
    }
}

.input-label-spacing {
    z-index: 0;

    &.zIndex9 {
        z-index: 9;
    }

    .form-group {
        input {
            padding: 16px 10px !important;
        }
    }

    position: relative;

    .bordered-span {
        position: absolute;
        display: inline-block;
        width: 2px;
        height: 30px;
        background: $gray-500;
        top: 12px;
        right: 0;
    }
}

.create-league-list {
    .nav-tabs {
        background: $white;
        box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.15);

        li {
            padding: 0;
            width: 50%;

            a {
                padding: 15px 10px;
                font-size: 11px;
                text-align: center;
                font-family: $font-black;
                color: $gray-675;
                text-transform: uppercase;
                display: block;

                @media (max-width: 320px) {
                    padding: 15px 6px;
                }
            }

            &.active {
                a {
                    color: $secondary;
                    position: relative;

                    &:before {
                        position: absolute;
                        content: '';
                        width: 100%;
                        height: 3px;
                        bottom: -2px;
                        left: 0;
                        background: $primary;
                    }
                }
            }
        }
    }

    .tab-content {
        padding: 18px 0;
    }

    table {
        margin-top: 10px;
        width: 100%;
        margin-bottom: 80px;

        tr {
            &.selected-tr {
                background: $white;
            }

            border-bottom: 1px solid $gray-570;

            td {
                padding: 15px;
                font-size: 14px;
                color: $gray-900;
                vertical-align: middle;

                &.list-name {
                    font-family: $font-bold;
                    width: 50%;

                    p {
                        margin: 0;
                        color: $gray-675;
                        font-size: 12px;
                        font-family: $font-regular;
                    }
                }

                &.user-action {
                    font-family: $font-black;
                    color: $secondary;
                    text-align: right;
                    width: 10%;

                    &.selected-team-action {
                        width: 24%;

                        a.delete-team {
                            display: inline-block;
                            margin-right: 8px;
                            font-size: 19px;
                            padding: 0 12px;
                            color: $black;
                        }
                    }
                }

                &.user-rank {
                    color: $secondary;
                    font-family: $font-bold;
                    width: 10%;
                }

                &.user-name {
                    font-family: $font-bold;
                    max-width: 400px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;

                    @media (max-width: 428px) {
                        max-width: 200px;
                    }

                    img {
                        border-radius: 100%;
                        width: 40px;
                        height: 40px;
                        margin-right: 15px;
                        background: lightgrey;
                    }
                }
            }
        }
    }

    .add-user {
        width: 24px;
        height: 24px;
        border: 1px solid $secondary;
        display: inline-block;
        border-radius: 100%;
        text-align: center;
        vertical-align: middle;

        i {
            display: none;
        }

        &.added {
            background: $secondary;

            i {
                display: inline-block;
                color: $primary;
            }
        }

        i.icon-remove {
            font-size: 14px;
        }

    }
}

.input-search {
    padding: 0 15px;
    position: relative;

    .start-search {
        position: absolute;
        background: $primary;
        height: 36px;
        width: 34px;
        text-align: center;
        color: $secondary;
        top: 0;
        right: 15px;
        z-index: 3;
        line-height: 34px;
    }

    .form-control {
        background: $secondary;
        color: $primary;
        text-align: center;
        font-size: 11px;
        border: 0;
        padding: 10px 34px 10px 10px;
        height: 36px;
        font-family: $font-bold;
    }

    ::-webkit-input-placeholder {
        color: $primary;
        text-transform: uppercase;
        letter-spacing: 1px;
        font-family: $font-xtrabold;
    }

    ::-moz-placeholder {
        /* Firefox 19+ */
        color: $primary;
        letter-spacing: 1px;
        font-family: $font-xtrabold;
        text-transform: uppercase;
    }

    :-ms-input-placeholder {
        /* IE 10+ */
        color: $primary;
        letter-spacing: 1px;
        font-family: $font-xtrabold;
        text-transform: uppercase;
    }

    :-moz-placeholder {
        /* Firefox 18- */
        color: $primary;
        letter-spacing: 1px;
        font-family: $font-xtrabold;
        text-transform: uppercase;
    }
}

.wrapper-spacing {
    &.my-private-contest-wrap {
        @extend .mycontest-wrap;
        padding-top: 15px;

        .collection-list-wrapper {
            padding: 0;

            .collection-list-body {
                padding: 15px 15px 10px;

                .icon-delete {
                    font-size: 18px;
                    position: absolute;
                    top: 10px;
                    right: 10px;
                    color: $black;
                }

                .btn-rounded {
                    min-width: 122px;
                    margin-top: 3px;

                    @media (max-width: 400px) {
                        min-width: 112px;
                        padding: 8px 0;
                    }
                }
            }

            .creater-info {
                padding: 5px 15px;
                border-radius: 0 0 14px 14px;
                background-color: #EFEFEF;
                box-shadow: 0 16px 16px 0 rgba(0, 0, 0, 0.05);
                font-size: 10px;
                font-family: $font-bold-italic;
                color: #9B9B9B;

                span {
                    color: $gray-900;
                }
            }
        }

        .collection-list-wrapper {
            .collection-heading {
                font-family: $font-italic;
                line-height: 1.3;
            }

            .prizepool {
                line-height: 1.2;
            }

            .your-rank-count {
                top: -2px;
            }
        }
    }
}

.private-contest-container {
    .countdown-start {
        font-size: 11px;
        padding: 10px 15px 0;
    }

    .my-contest-filter {
        padding: 18px 15px 0;
    }
}

.filter-wrapper {
    padding: 15px 15px 0;

    .select-secondary,
    .secondary__control,
    .secondary__value-container {
        cursor: pointer;
    }

    .input-label-center .secondary__placeholder {
        color: $primary;
        text-transform: uppercase;
        font-size: 11px;
        font-family: $font-black;
        text-transform: uppercase;
        cursor: pointer;
    }
}

.list-help-text {
    @extend .help-text;
    top: -15px;
}

.list-help-text-left {
    @extend .list-help-text;
    text-align: left;
    padding: 5px 15px !important;
    top: -5px;

    span {
        font-family: $font-xtrabold;
    }
}

.picked-user-count {
    float: right;

    span {
        font-family: $font-xtrabold;
    }
}

.custom-checkbox{
    &.cr-lg-checkie{
        input[type="checkbox"] ~ span, 
        input[type="radio"] ~ span{
            color: #000;
            user-select: none;
        }
    } 
}