.modal-dialog {
    &.contest-detail-modal {
        max-width: 768px;
        min-height: 100vh;
        margin: 0 auto;

        @media (max-width: 767px) {
            margin: 0;
        }

        .modal-content {
            border: 0;
            border-radius: 0;
        }

        .modal-header {
            padding: 0px;
            border-radius: 0;
            border: 0;
            background: $primary;

            .modal-close {
                top: 50%;
                transform: translateY(-50%);
                right: 15px;
                font-size: 16px;
                left: auto;
            }

            .modal-title {
                line-height: 1;
            }
        }

        .match-heading {
            max-width: 250px;
            margin: 0 auto;

            .verses {
                color: $gray-900;
                width: 20px;
                line-height: 14px;
            }

            .match-timing {
                margin: 0;
            }

            &.header-content {
                text-align: center;

                .team-img-block {
                    width: 34px;
                    height: 34px;
                    border-radius: 50%;
                    overflow: hidden;
                    display: inline-block;
                    vertical-align: middle;

                    img {
                        max-width: 100%;
                        max-height: 100%;
                    }
                }

                .team-header-detail {
                    display: inline-block;
                    vertical-align: middle;
                    margin: 0 10px;

                    .team-header-content {
                        color: $primary;
                        font-family: $font-black;
                        font-size: 13px;
                        display: inline-block;
                        vertical-align: middle;
                    }
                }
            }
        }

        .modal-body {
            padding: 0;
            background: $gray-100;
            height: calc(100vh - 109px);
            position: relative;

            .nav.nav-tabs {
                border: 0;
                box-shadow: 0 16px 16px 0 rgba(0, 0, 0, 0.05);

                li {
                    width: 33.33%;

                    a {
                        width: 100%;
                        background: $white;
                        color: $gray-550;
                        text-align: center;
                        text-transform: uppercase;
                        font-size: 11px;
                        font-family: $font-black;
                        letter-spacing: 1px;
                        display: inline-block;
                        padding: 13px;
                    }

                    &.active {
                        a {
                            color: $gray-900;
                            position: relative;

                            &:after {
                                content: '';
                                position: absolute;
                                bottom: 0px;
                                height: 2px;
                                width: 100%;
                                background: $primary;
                                left: 0;
                            }
                        }
                    }
                }
            }

            .tab-content {
                max-height: calc(100vh - 92px);
                height: calc(100vh - 139px);
                overflow: auto;

                // padding-bottom: 50px;
                // -moz-padding-bottom: 50px;
                >.tab-pane {
                    // height: 100%;
                    height: calc(100vh - 139px);
                    overflow: auto;
                }
            }
        }

        .modal-footer {
            padding: 0;
            border: 0;

            .btn {
                border-radius: 0;
                font-size: 14px;
            }
        }

        .winning-section {
            height: 100%;
            padding-bottom: 20px;
            padding-top: 30px;

            .winning-amt {
                color: $gray-550;
                font-size: 18px;
                font-family: $font-regular;

                span {
                    font-family: $font-black;
                    color: $teal;
                    font-size: 24px;
                }
            }

            .table-responsive {
                // height: calc(100% - 290px);
            }

            .table {
                margin: 30px 0;

                tr {
                    border-color: $border-light;

                    &:last-child {
                        border-bottom: 1px solid $border-light;
                    }
                }

                td {
                    padding: 10px 30px;
                    color: $gray-900;
                    vertical-align: middle;

                    &:first-child {
                        font-size: 11px;
                        font-family: $font-regular;
                    }

                    &:last-child {
                        font-size: 13px;
                        font-family: $font-bold;
                    }
                }
            }
        }

        .info-section {
            padding-bottom: 50px;

            .header-section {
                padding: 15px;
            }

            .contest-type {
                font-size: 12px;
                font-family: $font-regular;
                color: $gray-900;

                i {
                    font-size: 16px;
                    margin-right: 5px;
                    position: relative;
                    top: -2px;
                }

                span {
                    display: inline-block;
                    margin: 30px 0 15px 10px;
                }
            }

            .contest-info {
                padding: 15px;
                text-align: center;
                color: $gray-550;
                font-size: 16px;
                font-family: $font-bold;
                border-top: 1px solid $gray-400;

                .salary-cap-text {
                    span {
                        display: inline-block;
                        margin-right: 5px;
                    }
                }

                span {
                    color: $primary;
                }

                p {
                    margin: 0 0 5px 0;
                    font-size: 12px;
                    color: $gray-900;

                    span {
                        color: $gray-900;
                    }
                }
            }

            .table {

                th,
                td {
                    border: 0;
                    font-size: 12px;
                    color: $gray-900;
                    font-family: $font-bold;
                    padding: 5px 15px;
                }

                th {
                    text-transform: uppercase;
                }

                td:first-child {
                    font-family: $font-regular;
                }
            }

            .panel-body {
                padding: 8px 0;
            }

            .panel-heading {
                background-color: $light-gray;
                padding: 8px 15px;
                font-size: 12px;
                font-family: $font-regular;
                position: relative;

                a {
                    color: $gray-900;
                }

                i {
                    color: $gray-550;
                    position: absolute;
                    right: 15px;
                    font-size: 10px;
                    top: 50%;
                    transform: translateY(-50%);
                }
            }

            .panel {
                a {
                    display: block;

                    &:not(.collapsed) {
                        i.icon-arrow-down {
                            display: none;
                        }

                        i.icon-arrow-up {
                            display: block;
                        }
                    }

                    &.collapsed {
                        i.icon-arrow-down {
                            display: block;
                        }

                        i.icon-arrow-up {
                            display: none;
                        }
                    }
                }
            }
        }

        .entries-section {
            padding: 20px;
            padding-bottom: 50px;
            overflow-y: unset;

            table {
                td {
                    border: 0;
                    vertical-align: middle;
                }

                .table-body {
                    width: 100%;
                    overflow-y: auto;
                }
            }

            .user-entry {
                position: relative;
                padding-left: 60px;
                font-size: 14px;
                font-family: $font-bold;
                color: $gray-900;
                padding-top: 20px;
                padding-bottom: 20px;

                .user-img {
                    width: 46px;
                    height: 46px;
                    top: 50%;
                    transform: translateY(-50%);
                    left: 0;
                    border-radius: 50%;
                    position: absolute;
                }
            }

            .team-joined {
                color: $gray-550;
                font-size: 12px;
                font-family: $font-regular;
            }

            .progress-bar-default .min-entries {
                right: 0;
                left: auto;
                top: 4px;
            }
        }

        .progress-bar-default {
            margin: 10px 0 40px;

            .progress {
                width: 100%;
                max-width: 100%;
            }
        }

        &.contest-detail-without-btn {
            .modal-body {
                height: calc(100vh - 62px);
            }
        }
    }
}

.winning-tab-header {
    // margin-top: 30px;
    margin-bottom: 48px;

    .winning-amt {
        span {
            i.icon-bonus {
                display: inline-block;
                margin-left: 5px;
                margin-top: -4px;
            }
        }
    }
}

.table-heading {
    text-align: center;
    font-size: 14px;
    font-size: $gray-900;
    font-family: $font-bold;
}

.tab-description {
    color: $gray-675;
    font-size: 12px;
    font-family: $font-light-italic;
    max-width: 90%;
    text-align: center;
    margin: 0 auto 20px;
}