.achivements-header {
    background: linear-gradient(180deg, #FFFFFF 0%, #EFEFEF 100%);
    border-radius: 14px 14px 0 0;
    padding: 15px;
    text-align: center;
    text-transform: uppercase;
    color: $secondary;
    font-family: $font-black;

    img {
        width: 25px;
    }
}

.achivements-card {
    margin-bottom: 20px;
}

.achivements-web-container {
    .profile-header {
        background: $white;
    }
}

.achivements-wrapper {
    padding: 20px 15px;
}

.achivements-body {
    background: $white;
    border-radius: 0 0 14px 14px;

    .display-table {
        width: 100%;

        &:last-child {
            .achivments-section {
                border-bottom: none;
            }
        }
    }

    .achivments-section {
        padding: 25px 0;
        display: table-cell;
        text-align: center;
        vertical-align: middle;
        width: 50%;

        figure {
            height: 60px;
            position: relative;

            img {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                height: auto;
                width: auto;
            }
        }

        &:first-child {
            border-right: 1px solid $gray-120;
        }

        border-bottom: 1px solid $gray-120;

        p {
            font-size: 12px;
            font-family: $font-bold;
            color: $gray-900;
            margin: 15px 0 0 0;
        }
    }

    .media {
        padding: 20px;
        text-align: left;

        .media-body {
            padding-left: 10px;
        }

        h5 {
            font-size: 12px;
            font-family: $font-bold-italic;
            color: $gray-675;
            margin: 0;
        }

        p {
            font-size: 14px;
            font-family: $font-bold;
            margin: 0;
            color: $secondary;
        }

        &:not(:last-child) {
            border-bottom: 1px solid $gray-120;
        }
    }
}