.drawer-menu-trigger {
    float: left;
    width: 50px;
    height: 100%;
    color: $white;
    font-size: 22px;
    padding: 20px 0;
    text-align: center;

    &:hover {
        color: $cyan;
    }
}

.drawer-menu-wrapper {
    width: 100%;
    height: 100%;
    box-shadow: 4px 0 20px 0 rgba(0, 0, 0, 0.2);
    overflow: hidden;
}


.drawer-prof-content2 {
    height: 220px;
    background-color: $primary;
    padding: 15px;
    color: #fff;
    .l-p {
        flex: 1;
        .prof-img {
            height: 80px;
            width: 80px;
            border-radius: 20px;
            overflow: hidden;
            cursor: pointer;
            img {
                width: 100%;
                height: 100%;
            }
            margin-bottom: 15px;
        }
    }

    h4 {
        // color: #37003c;
        font-family: "MuliBold";
        font-size: 24px;
        font-weight: 800;
        letter-spacing: 0;
        margin-bottom: 0;
    }

    .usr-nm {
        font-family: "MuliRegular";
        font-size: 16px;
        font-weight: 600;
    }

    .r-p {
        .add-acc-box {
            display: flex;

            .prof-thumb {
                height: 40px;
                width: 40px;
                border-radius: 12px;
                overflow: hidden;
                margin-right: 12px;
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            .add-prof {
                height: 40px;
                width: 40px;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: $refsocial-secondary-clr;
                cursor: pointer;
                color: $primary;
            }
        }
    }

    .prf-cont {
        display: flex;
    }

    .fp-foot {
        display: flex;
        justify-content: space-between;

        .ref-id {
            font-family: "MuliRegular";
            font-size: 12px;
            font-weight: 600;
        }

        .add-bal {
            // color: $refsocial-secondary-clr;
            font-family: "MuliBold";
            font-size: 16px;
            font-weight: 800;
            cursor: pointer;
            i.add-ic{
                background-color: #fff;
                color: $primary;
                width: 18px;
                height: 18px;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                font-size: 10px;
                border-radius: 50%;
            }
        }
    }
}


.drawer-profile-content {
    float: left;
    height: 245px;
    width: 100%;
    background: $primary;
    position: relative;

    .profile-thumbnail {
        display: block;
        height: 128px;
        width: 128px;
        margin: 20px auto 10px;
        background-color: rgba($blue, 0.3);
        border-radius: 100%;

        a {
            height: 128px;
            width: 128px;
            display: block;

            img {
                max-width: 100%;
                max-height: 100%;
                width: 100%;
                height: 128px;
                width: 128px;
                box-shadow: 0 0 20px 0 rgba(0, 0, 0, .2);
                border: 3px solid #fff;
                border-radius: 50%;
            }
        }
    }

    .login-btn {
        height: 30px;
        width: 106px;
        border: 1px solid $white;
        border-radius: 3px;
        margin: auto;
        text-transform: uppercase;
        display: block;
        font: 14px $font-bold;
        color: $white;
        text-align: center;
        padding: 5px 0;
    }

    .user-full-name {
        display: block;
        font-size: 20px;
        font-family: $font-black;
        color: $white;
        text-align: center;
        text-transform: capitalize;
        text-shadow: 1.8px 1px 3px rgba(0, 0, 0, 0.2);
    }

    .ref-id{
        text-align: center;
        font-family: $font-black;
    }

    .user-acc-bal {
        color: $refsocial-secondary-clr;
        font-size: 16px;
        text-align: center;
        font-family: $font-black;
        margin-top: 4px;

        i {
            color: $refsocial-secondary-clr;
            position: relative;
            top: -2px;
            left: 4px;
            box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
            border-radius: 50%;

            &:before {
                z-index: 5;
                position: relative;
            }
        }
    }

    .user-acc-label {
        color: $gray-170;
        text-align: center;
        font-size: 10px;
        font-family: $font-italic;
        opacity: 0.8;
    }
}

.drawer-menu-list {
    float: left;
    width: 100%;
    list-style: none;
    padding: 0;
    margin: 0;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    background-color: $white;
    min-height: calc(100vh - 266px);

    &.drawer-heading-list {
        background: $secondary;
        color: $white;
        width: 40%;

        .drawer-menu-item {
            a {
                color: $white;
                border-bottom: 1px solid rgba(253, 253, 253, 0.1);
                padding: 15px;
                font-size: 14px;
                font-family: $font-bold;

                i {
                    font-size: 10px;
                    opacity: .5;
                    position: absolute;
                    right: 15px;
                    top: 50%;
                    transform: translateY(-50%);
                }

                &:hover,
                &:focus,
                &:active {
                    color: $white !important;
                }

                &.active {
                    color: $primary !important;

                    i {
                        color: $primary;
                        opacity: 1;
                    }
                }
            }
        }
    }

    &.drawer-submenu-list {
        background: $white;
        color: $secondary;
        position: relative;
        z-index: 1;
        width: 60%;
        @media(max-width:767px){
            padding-bottom: 35px;
        }

        a {
            color: $secondary;
            border-left: solid 4px transparent;
            &.active {
                background: linear-gradient(270deg, $white 0%, $gray-120 100%);
                color: $secondary;
                border-left-color: $primary;
                &:before {
                    background: $primary;
                }
            }
        }

        li.drawer-menu-without-icon {
            a {
                padding: 15px;
            }
        }
    }
}

.drawer-menu-item {
    display: block;
    overflow: hidden;

    a {
        display: block;
        padding: 15px 12px 15px 0;
        font-size: 13px;
        line-height: 1.2;
        color: $gray-650;
        position: relative;
        font-family: $font-bold;
        display: flex;

        .ic {
            float: left;
            width: 58px;
            height: 40px;
            margin: -13px 0;
            font-size: 22px;
            line-height: 38px;
            text-align: center;
            color: $gray-900;
            align-self: center;
        }

        &.active {
            color: $primary;
        }
    }
}

.drawer-footer {
    float: left;
    width: 100%;
    height: 48px;
    background: $white;
    border-top: solid 1px #F0F0F0;

    .league-code-btn,
    .logout-btn {
        float: left;
        margin: 13px;

        img {
            max-width: 16px;
            margin-right: 10px;
        }

        font: 12px $font-bold;
    }

    .logout-btn {
        margin: 15px;
        float: right;
    }

    i.icon-league-code {
        font-size: 24px;
        margin-right: 3px;
        color: $gray-900;
    }

    i.icon-logout {
        color: $gray-900;
        font-size: 20px;
        margin-right: 5px;
        position: relative;
        top: -2px;
    }
}

.drawer-lang-switch {
    height: 35px;
    width: 200px;
    border-radius: 3px;
    background-color: $white;
    overflow: hidden;
    border: 0.5px solid #D4D4D4;
    margin: 20px 20px;

    >span {
        float: left;
        width: 50%;
        height: 100%;
        padding: 10px 0;
        text-align: center;
        font-size: 11px;
        font-family: $font-bold;
        text-transform: uppercase;
        color: $gray-650;
        line-height: 1.1;

        &.arabic {
            padding: 6px 0;
            font-size: 16px;
        }

        &.selected {
            border-right: 0.5px solid #D4D4D4;
            color: $primary;
        }
    }
}

.drawer-sports-switch {
    position: absolute;
    bottom: -15px;
    right: 0;
    left: 0;
    margin: 0 auto;

    .form-group {
        margin: 0;

        .select-sports {
            width: 190px;
            box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.2);
            background: #fff;
            border-radius: 3px;
            margin: 0 auto;
            height: 35px;
            padding: 7px 15px 10px;
            position: relative;

            .select-sports-label {
                width: 41%;
                float: left;
                font-size: 11px;
                font-family: $font-italic;
                color: $gray-550;
                padding-top: 3px;
            }

            .sportStyle {
                display: inline-block;
                width: 55%;
                font-size: 12px;
                text-transform: uppercase;
                color: $gray-900;
                font-family: $font-bold;
                position: relative;
                top: -3px;
            }

            .select-arr {
                width: 20px;
                top: 45%;
                right: 4px;
                transform: translateY(-50%);
                color: $gray-550;

                .icon-arrow-sort {
                    font-size: 13px;
                }
            }

            .secondary__single-value {
                font-family: $font-bold;
            }

            .secondary__placeholder {
                color: $gray-900;
            }

        }
    }
}

.d-block>div {
    height: 100vh !important;
}

.drawer-menu-wrapper>span {
    z-index: 999999 !important;
}

.dr_add_fund_btn {
    display: inline;
    margin-left: 10px;
}



// custom drawer

.drawer-menu-wrapper {
    transform: translate3d(-16px, -8px, 0px);
    transition-duration: 0.5s;
    transition-timing-function: ease-in-out;
    width: 0;
    max-width: 0;

    &.drawer-menu-wrapper-open {
        transform: translate3d(-15px, -8px, 0px);
        transition-timing-function: ease-in-out;
        width: 100%;
        max-width: 400px;
    }

    position: fixed;
    z-index: 9991;
    // max-width: 100%;
}

.inner-drawer-menu-wrapper {
    width: 400px;
    height: 100%;
    box-shadow: 4px 0 20px 0 rgba(0, 0, 0, 0.2);
    overflow: hidden;
    max-width: 100vw;
}

.drawer-menu-overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    opacity: 1;
    top: 0px;
    left: 0px;
    margin: 0px;
    transform: translate3d(0px, 0px, 0px);
    touch-action: pan-y;
    transition-timing-function: ease-in-out;
    user-select: none;
    -webkit-user-drag: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    z-index: 999;
    display: none;

    &.drawer-menu-overlay-open {
        display: block;
    }
}

.drawer-scrollarea {
    height: calc(100vh - 53px);
    overflow-y: auto;
    max-height: 100vh;
    background: $secondary;
}

.drawer-close {
     // position: absolute;
     top: 10px;
     color: $secondary;
     cursor: pointer;
     z-index: 2;
     padding: 5px;
     // right: 15px;
}

.drawer-logout {
    padding: 0;
    font-size: 22px;
    color: $secondary;
    position: absolute;
    right: 15px;
    top: 15px;
    cursor: pointer;
}

.drawer-header-wrap {
    position: relative;
    padding: 14px;
    background: $primary;
    // border-bottom: 2px solid $white;
    // text-align: center;

    &.header-shadow {
        z-index: 3;
        box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.15);
    }
    i{
        font-size: 44px;
        color: $secondary;
    }
    img {
        width: 44px;
    }

    .icon-close.drawer-close {
        font-size: 14px;
        line-height: 24px;
    }
}