.refsocial-web-container {
    .post-card {
        .post-card-header {
            i {
                &.icon-share {
                    right: 50px;
                }

                &.icon-add-user {
                    font-size: 17px;
                    color: $ternary;
                    top: 3px;
                }

                &.icon-user-select {
                    font-size: 17px;
                    top: 3px;
                }
            }
        }

        &.repost-card {
            .post-card-header.repost-card-header {
                padding-bottom: 20px;

                i.icon-dots {
                    transform: rotate(270deg);
                    display: inline-block;
                }
            }

            .post-card {
                border-radius: 0;
                box-shadow: 2px 0 16px 0 rgba(0, 0, 0, 0.1);
                margin-bottom: 0;
            }
        }
    }

    .enter-comment-section {
        padding: 15px 10px;
    }
}

.refsocial-sub-header {
    div {
        display: inline-block;
        width: 33.33%;
        padding: 13px;
        text-align: center;
        color: $white;
        background: $refsocial-secondary-clr;
        font-size: 18px;
        border-right: 1px solid rgba(255, 255, 255, 0.2);

        &:last-child {
            border-right: none;
        }
    }
}