.prediction-quiz {
    padding: 15px;
    max-height: calc(100% - 48px);
    overflow: auto;

    .quiz-section {
        margin-bottom: 30px;
        border-radius: 7px 7px 0 0;
        background-color: $white;
        box-shadow: 1px 2px 8px 0 rgba(0, 0, 0, 0.1);
        list-style-type: none;
        margin-bottom: 0;
        padding: 0;

        &.quiz-section-rounded {
            border-radius: 7px;
        }

        li {
            display: inline-block;
            vertical-align: middle;
            padding: 12px 10px 8px;

            &:last-child {
                width: 26%;

                @media (max-width: 320px) {
                    width: 30%;
                }
            }

            &:nth-child(2) {
                width: 66%;
                padding-left: 45px;

                @media (max-width: 420px) {
                    width: 59%;
                }

                @media (max-width: 320px) {
                    width: 53%;
                }
            }

            position: relative;

            .quiz-query {
                color: $gray-675;
                font-size: 12px;
                font-family: $font-bold;
            }

            .team-selected {
                font-size: 14px;
                color: $gray-900;
                font-family: $font-bold;
            }

            img {
                position: absolute;
                width: 32px;
                height: 32px;
                background: lightgray;
                border-radius: 50%;
                left: 5px;
                top: 50%;
                transform: translateY(-50%);
            }

            .blocked {
                position: absolute;
                left: 5px;
                top: 50%;
                transform: translateY(-50%);
                font-size: 31px;
            }

            .fl1 {
                width: 20px;
                height: 20px;
                left: 4px;
                top: 42%;
                box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.5);
            }

            .fl2 {
                width: 20px;
                height: 20px;
                left: 12px;
                top: 57%;
                box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.5);
            }

            .numbers-text {
                height: 24px;
                width: 25px;
                border-radius: 5px;
                background: $white;
                box-shadow: 4px 4px 8px 0 rgba(0, 0, 0, 0.1);
                border: 0.5px solid $gray-225;
                font-size: 12px;
                color: $gray-550;
                font-family: $font-bold;
                line-height: 24px;
                text-align: center;
            }

            &.team-score-list {
                width: 33%;
                padding-left: 45px;

                @media (max-width: 420px) {
                    width: 29%;
                }

                @media (max-width: 320px) {
                    width: 26%;
                }
            }
        }

    }

    .prediction-result {
        font-size: 11px;
        font-family: $font-bold;
        text-align: right;
        color: $txt-secondary;
        padding-right: 2px;

        i {
            display: inline-block;
            margin-left: 5px;
            color: $red;
            font-size: 20px;

            &.pending {
                color: #ffc107;
            }
        }
    }

    .ans-picked-detail {
        padding-left: 0;
        margin-bottom: 0;
        border-radius: 0 0 7px 7px;
        list-style-type: none;
        background: $white;
        border-top: 1px solid $border-light;
        margin-bottom: 10px;
        box-shadow: 0 10px 25px 0 rgba(0, 0, 0, 0.1);

        li {
            display: inline-block;
            padding: 8px 13px;
            vertical-align: top;

            &:first-child {
                width: 68%;
            }

            &:last-child {
                width: 32%;
                text-align: right;
            }

            .ans-label {
                margin-bottom: 0;
                font-size: 10px;
                color: $ternary;
                font-family: $font-bold;
                padding-right: 5px;
            }

            .correct-ans {
                color: $gray-900;
                font-size: 14px;
                font-family: $font-bold;
                text-transform: uppercase;
                padding-right: 5px;
            }

            .points-get {
                display: inline-block;
                border-radius: 20px;
                background: $primary;
                color: $white;
                padding: 4px 5px;
                font-size: 10px;
                font-family: $font-bold;
            }

            &.width-100 {
                width: 100%;
                text-align: center;

                .correct-ans {
                    span {
                        display: inline-block;
                        margin-left: 5px;
                    }
                }
            }
        }

        .ans-picked-by {
            color: $txt-secondary;
            font-size: 12px;
            font-family: $font-italic;
            display: inline-block;
            max-width: 80%;
        }
    }

    .choosen-correct {
        .quiz-section {
            background: $mint-green;
            border-radius: 7px 7px 0 0;

            .prediction-result {
                color: $ternary;

                i {
                    color: $ternary;
                    font-size: 20px;
                }
            }
        }

        .ans-picked-detail {
            background: $mint-green;

            li {
                &:first-child {
                    width: 100%;
                }

                &:last-child {
                    // display: none;
                }
            }
        }
    }
}