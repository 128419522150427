.footer-container {
    background: $primary;
    position: relative;
    margin-top: 20px;
    width: 100%;
    max-width: 768px;
    margin: 0 auto;
    overflow: hidden;
    box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.1);

    &.refpay-foot{
        background-color: $refpay-clr;

        .footer-bottom{
            background-color: $refpay-clr;
            border-top: 1px solid #23ccf8;
            a:first-child{
                border-right: 1px solid #23ccf8;
            }
        }
    }

    ul {
        list-style-type: none;
        padding: 0;
        margin: 0;
        padding-top: 20px;

        li {
            position: relative;

            img {
                position: absolute;
                right: 30px;
                top: 6px;
                width: 50px;
            }

            a {
                padding: 13px 30px;
                font-size: 14px;
                font-family: $font-regular;
                color: $secondary;
                display: block;

                &.copy-right-text {
                    font-size: 12px;
                    padding-bottom: 25px;
                    cursor: default;
                }
            }

            &.footer-text {
                padding: 20px 30px 10px;
                font-size: 10px;
                font-family: $font-italic;
                opacity: 0.8;
            }
        }
    }

    .footer-bottom {
        border-top: 1px solid #2BE37F;
        background: $primary;

        &.feed-shadow {
            box-shadow: 0 -25px 40px 0 #0D3C3220;
            transition: box-shadow 0.3s ease-in-out;
        }

        a {
            display: inline-block;
            width: 50%;
            color: rgba(0, 57, 46, 0.7) !important;
            font-size: 13px;
            font-family: $font-bold;
            text-align: center;
            padding: 25px 25px 20px;

            &.tweets {
                padding: 24px 25px 18px;
            }

            &.open {
                background-color: #2BE37F;
                color: #00392E !important;

                i {

                    &.icon-twitter,
                    &.icon-facebook {
                        color: #00392E !important;
                    }
                }
            }

            cursor: pointer;

            &:first-child {
                border-right: 1px solid #2BE37F;
            }

            i {
                font-size: 20px;

                &.icon-twitter,
                &.icon-facebook {
                    color: rgba(0, 57, 46, 0.7) !important;
                    margin-right: 5px;
                    position: relative;
                    top: -2px;
                }

                &.icon-twitter {
                    font-size: 24px;
                }
            }
        }

        z-index: 9;
        position: relative;
    }
}

.tweet-content,
.feed-content {
    height: 0;
    width: 100%;
    transform: translate3d(0px, 0px, 0px);
    transition-duration: 0.6s;
    transition-timing-function: ease-in-out;
    position: absolute;
    bottom: -100%;
    background: $primary;
    z-index: 8;
    padding: 35px 30px;

    &.show-tweets-content {
        height: calc(100% - 75px);

        @media (max-width: 767px) {
            height: calc(100% - 75px);
        }

        bottom: 75px;
        transform: translate3d(0px, 0px, 0px);
        transition-timing-function: ease-in-out;
    }

    p {
        font-size: 14px;
        font-family: $font-regular;
        margin: 0;
        padding: 0;
        color: $secondary;

        &.iframe-container {
            text-align: center;

            iframe {
                width: 500px !important;
                height: 260px !important;

                @media (max-width: 428px) {
                    height: 290px !important;
                    width: auto !important;
                }
            }

            .fb_iframe_widget span {
                width: 500px !important;
                height: 260px !important;
                max-width: 500px;

                iframe {
                    width: 500px !important;
                    height: 260px !important;

                    @media (max-width: 500px) {
                        height: 290px !important;
                        width: 380px !important;
                    }

                    @media (max-width: 450px) {
                        height: 290px !important;
                        width: 350px !important;
                    }

                    @media (max-width: 400px) {
                        height: 290px !important;
                        width: 315px !important;
                    }

                    @media (max-width: 360px) {
                        height: 290px !important;
                        width: 300px !important;
                    }

                    @media (max-width: 340px) {
                        height: 290px !important;
                        width: 260px !important;
                    }
                }
            }

            &.visible-sm {
                display: none !important;
            }

            &.hidden-sm {
                display: block !important;
            }

            @media (max-width: 428px) {
                &.visible-sm {
                    display: block !important;
                }

                &.hidden-sm {
                    display: none !important;
                }
            }
        }
    }
}

.close-feeds {
    font-size: 11px;
    color: $refsocial-secondary-clr;
    float: right;
}