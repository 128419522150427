.header-modalbg {
    // background-image: url('https://sports-vinfotech-org.s3.amazonaws.com/assets/img/bg-circle.png');
    background-repeat: no-repeat;
    text-align: center;
    min-height: 200px;
    background-size: cover;
    position: relative;

    img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

.banner-modal {
    &.modal-dialog {
        max-width: 70%;

        @media screen and (max-width: 767px) {
            margin: 60px auto;
        }
    }

    .modal-header {
        padding: 0;
        border-bottom: 0;
        border-top-left-radius: 14px;
        border-top-right-radius: 14px;
    }

    .modal-body {
        padding: 1rem 35px 3rem 35px;
    }

    .btn-grops-footer {
        width: 100%;

        a {
            display: inline-block;
            width: calc(100% / 2);
            text-align: center;
            padding: 17px 5px;
            text-transform: uppercase;
            font-size: 12px;
            font-family: $font-bold;
            color: $white;

            &:first-child {
                border-right: 1px solid rgba(255, 255, 255, 0.1);
            }
        }
    }

    .custom-modal-footer {
        border-bottom-left-radius: 14px;
        border-bottom-right-radius: 14px;

    }


    .alert-footer-container {
        width: 100%;

        .my-alert-footer-button {
            text-align: center;
            display: inline-block;
            width: 50%;
            background-color: white;
            cursor: pointer;

            .my-alert-button-text {
                color: rgba(0, 0, 0, 0.8);
                font-family: 'Muli';
                font-size: 18;
                line-height: 20px;
                text-align: center;
            }
        }

        .my-alert-seperator {
            width: 2px;
            height: 100%;
            background-color: black;
        }
    }

    .Confirm-header {
        color: #333333;
        font-family: Muli;
        font-size: 20px;
        font-weight: 900;
        line-height: 25px;
        text-align: center;
        margin-top: 20px;
    }

    .banner-image {
        width: 100%;
        // height: 60vh;
        object-fit: contain;
    }

    .confirm-popup-body {
        text-align: center;
        width: 100%;
        display: inline-flex;
        justify-content: space-between;

        h4 {
            color: $gray-900;
            font-size: 20px;
            font-family: $font-black;
            line-height: 25px;
            margin: 0 0 15px 0;
        }

        p {
            color: $gray-900;
            font-size: 14px;
            line-height: 18px;
        }
    }

    .lable-text {
        opacity: 0.8;
        font-family: $font-black;
        font-size: 14px;
        line-height: 18px;
    }

    .selected-team {
        font-family: $font-black;
        font-size: 14px;
        line-height: 18px;
        text-align: right;
    }


    .my-alert-message-text {
        color: rgba(0, 0, 0, 0.8);
        font-family: $font-regular;
        font-size: 16px;
        line-height: 20px;
        text-align: center;
    }

}

.confirmation-modal {
    .modal-header {
        padding: 15px;
    }

    .Confirm-header {
        color: #333333;
        font-family: $font-black;
        text-transform: uppercase;
        font-size: 20px;
        font-weight: 900;
        line-height: 25px;
        text-align: center;
        margin-top: 0px;
    }

    .modal-body {
        padding: 1px;
    }

    table {
        td {
            width: 50%;
            font-size: 13px;

            &:last-child {
                text-align: right;
            }
        }

        tr {
            &:last-child {
                border-bottom: 1px solid $gray-300;
            }
        }
    }

    .dissmiss-btn-footer {
        padding: 5px 0;

        a {
            font-size: 13px;
            border-radius: 0;
            background: transparent;
            margin: 0;
            box-shadow: none;
            width: 100%;
            color: $white;
            text-transform: uppercase;
            text-align: center;
            padding: 10px;
            font-family: $font-bold;

            &:nth-child(2) {
                border-left: 1px solid #fff;
            }

            &.single-text {
                width: 100%;
            }
        }
    }

    .basic-select-field {
        padding: 0;
        width: 100%;

        .select__value-container {
            padding: 0;
            text-align: left;

            .select__placeholder {
                color: $gray-900;
                font-family: $font-bold;
                font-size: 14px;
            }
        }

        .select__control {
            height: 20px;
            min-height: 1px;
            border-radius: 0;
            background-color: $white;
            border-color: transparent !important;
            box-shadow: none !important;

            .select__single-value {
                //color: $white;
                font-size: 12px;
            }
        }

        .select__indicator-separator {
            display: none;
        }

        .select__indicator {
            padding-right: 0;
        }
    }

    .lable-text {
        font-family: $font-regular;
        font-size: 13px;
    }

    .select__menu {
        z-index: 999;
    }
}

.thank-you-modal {
    a.modal-close-btn {
        border-radius: 50%;
        background: $white;
        display: inline-block;
        width: 20px;
        height: 20px;
        position: absolute;
        right: 20px;
        top: 20px;
        text-align: center;
        font-size: 8px;
        line-height: 20px;
        color: $gray-900;
    }
}

.thank-you-body {
    text-align: center;
    margin-top: 30%;

    img {
        width: 90px;
    }

    h4 {
        margin-top: 50px;
        color: $white;
        font-size: 20px;
        font-family: $font-black;
        line-height: 25px;
        margin-bottom: 18px;
        text-transform: uppercase;
    }

    p {
        color: $white;
        font-size: 16px;
        font-family: $font-bold;
        margin: 0 auto 44px;
        max-width: 300px;
    }

    .btn {
        background: linear-gradient(79.63deg, #101243 0%, #09AFDB 100%);
        min-width: 200px;
        font-size: 14px;
        padding: 12.5px;
        border-radius: 22px;
    }
}