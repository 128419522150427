.refpodcast-section {
    padding: 20px 15px;
}

.card-playlist {
    padding: 15px;
    border-radius: 14px;
    box-shadow: 0 16px 16px 0 rgba(0, 0, 0, 0.05);
    background: $white;
    position: relative;
    padding-left: 100px;
    margin-bottom: 20px;

    img {
        position: absolute;
        left: 15px;
        top: 50%;
        transform: translateY(-50%);
        width: 70px;
        height: 70px;

        &.audio-action {
            width: 30px;
            height: 30px;
            left: auto;
            right: 20px;
            top: 50%;
            transform: translateY(-50%);
        }
    }

    .music-name {
        font-size: 14px;
        font-family: $font-black;
        color: $secondary;
    }

    .singer-name {
        color: $gray-200;
        font-family: $font-bold-italic;
        font-size: 11px;
    }

    .download {
        display: inline-block;
        color: $gray-675;
        font-size: 12px;
        font-family: $font-bold;
        margin-top: 14px;

        i {
            color: $primary;
            display: inline-block;
            margin-right: 3px;
            transform: rotate(-90deg);
        }
    }

    &.highlight-card-playlist {
        box-shadow: 0 0 16px 0 rgba(0, 255, 135, 0.4);
        border: 1px solid $primary;
    }
}

.sort-by-section {
    color: $gray-200;
}

.refpodcast-header {
    height: 360px;
    background: url('../img/singer-img2.png') no-repeat center;
    background-size: cover;
    position: relative;
    color: $white;
    text-align: center;
    padding: 50px 15px;

    .music-name {
        font-size: 16px;
        font-family: $font-black;
    }

    .singer-name {
        font-size: 11px;
        font-family: $font-bold-italic;
    }

    .btn-rounded {
        border-radius: 30px;
        position: absolute;
        bottom: 15px;
        right: 20px;
        padding: 5px 10px;
        font-size: 12px;
        color: $white;

        i {
            font-size: 12px;
            display: inline-block;
            margin-right: 3px;
            transform: rotate(-90deg);
        }
    }
}

.refpodcast-subheader {
    background: $white;
    padding: 5px 20px;
    color: $gray-675;
    font-size: 12px;
    text-transform: uppercase;
}