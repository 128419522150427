.static-page {
    background: $gray-170;

    .page-body {
        padding: 25px;
    }

    .page-header {
        padding: 60px 10px;
        text-align: center;
        box-shadow: 0 16px 16px 0 rgba(0, 0, 0, 0.05);

        p {
            margin: 5px 0;
            font-size: 12px;
            font-family: $font-bold;
            color: $gray-900;
            text-transform: uppercase;
        }
    }

    .page-subheading {
        font-size: 11px;
        color: $gray-900;
        font-family: $font-black;
        text-transform: uppercase;
        background: $light-gray;
        padding: 5px 15px;
    }

    .heading,
    p {
        font-size: 12px;
        color: $gray-900;
        margin-top: 30px;
        margin-bottom: 20px;
        text-align: center;
    }

    .heading {
        font-family: $font-bold;
        text-transform: uppercase;
    }

    p {
        font-family: $font-regular;
        margin-bottom: 15px;
    }

    .panel.panel-default {
        border-bottom: 1px solid $border-light;
    }

    .panel-title {
        padding: 15px 25px 15px 15px;
        font-size: 14px;
        font-family: $font-bold;

        a {
            color: $gray-900;
            position: relative;

            span {
                display: inline-block;
                padding-left: 30px;
            }

            .panel-label {
                position: absolute;
                left: 0;
                padding: 0;
                color: $primary;
                text-transform: uppercase;
            }
        }
    }

    .panel-body {
        padding: 0px 25px 15px 46px;
    }

    .page-body {
        ul {
            padding: 0;
            margin: 0;
            list-style-type: none;

            li {
                font-size: 12px;
                font-family: $font-regular;
                margin-bottom: 15px;
            }

            &.list-inline {
                display: inline-block;
                width: 50%;

                li {
                    font-family: $font-black;
                }
            }
        }
    }
}

.page-heading {
    font-size: 20px;
    font-family: $font-black;
    color: $gray-900;
    max-width: 240px;
    text-transform: uppercase;
    margin: 0 auto;

    &.about-heading {
        max-width: 340px;
    }
}

.static-page {
    .page-body {
        .rules-scoring-table {
            margin: 20px auto;
            width: 80%;

            td {
                display: block;
                float: left;
                width: 50%;
                border: 1px solid #ddd;
            }
        }

        .custom-nav-tabs {
            .nav.nav-tabs {
                margin-bottom: 0;

                li {
                    width: 33.33%;
                    margin-bottom: 0;
                }
            }
        }

        &.rules-scoring-body {
            .custom-nav-tabs .nav.nav-tabs {
                align-items: center;
                justify-content: center;

                li {
                    width: 150px;
                    max-width: 33.33%;
                }
            }

            .type-heading {
                text-align: center;
                padding: 6px;
                background: #031C43;
                border-radius: 5px;
                font-family: $font-bold;
                color: #fff;
            }

            ul.scoring-chart {
                margin: 20px 0;

                .text-block,
                .value-block {
                    display: table-cell;
                    background-color: #fff;
                    box-shadow: 0 0 4px 0 rgba(0, 0, 0, .1);
                    padding: 10px;
                    vertical-align: middle;
                }

                .text-block {
                    border-radius: 6px 0 0 6px;
                }

                .value-block {
                    text-align: center;
                    width: 30%;
                    border-radius: 0 6px 6px 0;
                }
            }
        }
    }
}

.rules-scoring-body {
    .custom-nav-tabs .nav.nav-tabs {
        align-items: center;
        justify-content: center;

        li {
            width: 150px;
            max-width: 33.33%;
        }
    }

    .type-heading {
        text-align: center;
        padding: 6px;
        border-radius: 5px;
        font-family: $font-bold;
        color: $gray-900;
        margin-top: 20px;
    }

    ul.scoring-chart {
        margin: 20px;
        list-style-type: none;
        padding: 0;

        li {
            margin-bottom: 10px;
        }

        .text-block,
        .value-block {
            display: table-cell;
            background-color: #fff;
            box-shadow: 0 0 4px 0 rgba(0, 0, 0, .1);
            padding: 10px;
            vertical-align: middle;
        }

        .text-block {
            border-radius: 6px 0 0 6px;
        }

        .value-block {
            text-align: center;
            width: 30%;
            border-radius: 0 6px 6px 0;
        }
    }
}

.modal-dialog.contest-detail-modal.modal-scoring-body .modal-body {
    height: calc(100vh - 62px);
}