.fixture-collection-wrapper {
    padding: 15px;

    .infinite-scroll-component {
        overflow: unset !important;
    }

    .card-view {
        min-height: 180px;
        display: flex;
        align-items: center;
        flex-direction: row-reverse;
        background: $gray-1000;
        border-radius: 14px;
        box-shadow: 0 8px 30px 0 rgba(0, 0, 0, 0.1);
        position: relative;
        overflow: hidden;
        margin-bottom: 20px;

        .icon-share {
            position: absolute;
            right: 10px;
            font-size: 16px;
            top: 10px;
            color: $gray-550;
            z-index: 6;
        }
    }
}

.card-view-right {
    background: $white;
    position: relative;
    z-index: 2;
    width: 75%;
    min-height: 180px;
    float: right;
    padding: 18px 20px 18px 8px;

    .vissel-img {
        position: absolute;
        right: 10px;
        bottom: 10px;
        z-index: -1;
    }

    &:before {
        content: "";
        width: 18px;
        height: 110%;
        background: #fff;
        position: absolute;
        left: -14px;
        z-index: 9;
        top: -2px;
        -webkit-transform: rotate(6deg);
        transform: rotate(6deg);
        box-shadow: -4px 0px 4px 0 rgba(0, 0, 0, 0.05);
    }

    &:after {
        content: "";
        width: 12px;
        height: 50%;
        background: $white;
        position: absolute;
        z-index: 2;
        left: -6px;
        bottom: -2px;
        -webkit-transform: rotate(6deg);
        transform: rotate(6deg);
    }

    h2 {
        padding-left: 15px;
        color: $gray-900;
        font-family: $font-black-italic;
        font-size: 16px;
        margin-bottom: 6px;

        span {
            color: $red;
        }
    }

    p {
        margin-bottom: 26px;
        font-size: 11px;
        font-family: $font-italic;
        color: $gray-675;
        padding-left: 12px;
        word-break: break-word;
        word-wrap: break-word;

        &:first-child {
            margin-bottom: 0;
        }
    }

    .match-date {
        font-size: 12px;
        font-family: $font-bold-italic;
        color: $gray-675;
        padding-left: 8px;
    }

    .match-time {
        font-size: $gray-900;
        font-size: 16px;
        padding-left: 6px;
        font-family: $font-bold-italic;

        i {
            color: $gray-675;
            position: relative;
            top: -2px;
            margin-right: 2px;
        }

        &.danger {
            i {
                color: $gray-675;
            }
        }

        .countdown.time-line {
            color: $red;
            display: inline-block;
            margin-left: 5px;
        }
    }

    .game-closed {
        padding-left: 4px;
        color: $red;
        font-size: 10px;
        font-family: $font-bold-italic;

        span {
            display: inline-block;
            margin-right: 3px;
        }
    }

    .play-btn {
        margin-top: 0px;
        color: $ternary;
        font-size: 16px;
        position: relative;
        z-index: 1;
        top: -8px;
        text-transform: uppercase;
        font-family: $font-LemonMilk;
        text-shadow: 1.8px 1px rgba(0, 0, 0, 0.2);
    }
}

.card-view-left {
    min-height: 180px;
    width: 25%;
    margin-right: 20px;
}

.team-section {
    height: 90px;
    position: relative;
    z-index: 5;

    &:before {
        content: url("../img/team-logo-shadow.png");
        display: inline-block;
        width: auto;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 4%;
        text-align: center;
    }

    &:first-child {
        &:after {
            content: "";
            position: absolute;
            left: 0;
            width: 102%;
            bottom: -4px;
            border-bottom: 1px dashed #dbdbdb;
            height: 1px;
        }

        img {
            top: 40%;
            transform: translateY(-40%);
            margin: 0 auto;
            right: 0;
            left: 0;
        }

        &:before {
            bottom: 7%;
        }
    }

    img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        height: 40px;
        width: 40px;
        border-radius: 50%;
        background: lightgrey;

        &:after {
            content: url("../img/team-logo-shadow.png");
            display: inline-block;
            width: 100%;
            position: absolute;
            left: -1px;
            bottom: -20px;
        }
    }

    .vs-section {
        position: absolute;
        bottom: -13px;
        left: 0;
        width: 100%;
        background: $gray-1000;
        text-align: center;
        text-align: center;

        i {
            display: inline-block;
            position: relative;
            z-index: 1;
            color: $gray-550;
            font-size: 16px;
        }
    }
}

.select-option-btn {
    padding: 10px;
    background: $secondary;
    position: relative;
    color: $primary;
    text-transform: uppercase;
    text-align: center;
    font-size: 11px;
    padding-right: 35px;
    font-family: $font-black;
    cursor: pointer;

    .arrow-section {
        top: 0;
        color: $secondary;
        background: $primary;
        position: absolute;
        width: 34px;
        height: 100%;
        padding: 10px;
        text-align: center;
        right: 0;
        z-index: 1;
    }
}

.collection-list-wrapper {
    background: $white;
    margin-bottom: 20px;
    border-radius: 14px;
    box-shadow: 0 16px 16px 0 rgba(0, 0, 0, 0.05);
    padding: 16px;
    position: relative;

    .icon-share {
        position: absolute;
        right: 10px;
        top: 10px;
        color: $gray-550;
    }

    .collection-heading {
        font-size: 13px;
        color: $gray-900;
        font-family: $font-bold-italic;

        .contest-name {
            cursor: pointer;
            max-width: 70%;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            display: inline-block;
        }
    }

    .prizepool {
        font-size: 16px;
        font-family: $font-black-italic;
        color: $txt-light;
        text-transform: uppercase;

        .prizepool-for {
            font-size: 10px;
            color: $gray-550;
            text-transform: none;
            font-family: $font-bold-italic;
        }

        .prizepool-amt {
            color: $secondary;
            display: inline-block;
            margin: 0 5px;
        }

        .scoring-link {
            text-transform: capitalize;
            font-size: 12px;
            color: $secondary;
            float: right;
            margin-right: 35px;
            line-height: 24px;

            @media (max-width: 320px) {
                margin-right: 8px;
            }
        }
    }

    .btn-rounded {
        float: right;
        background: linear-gradient(73.19deg, $ternary 0%, $primary 100%);
        padding: 8px 25px;
        margin-top: 8px;
        box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2), 0 5px 10px 0 rgba(0, 0, 0, 0.1);

        @media (max-width: 320px) {
            padding: 6px 14px;
        }

        &:hover,
        &:focus,
        &:active,
        &.active {
            color: $white !important;
        }
    }

    .progress {
        width: 60%;
    }
}

/* Fans Challenge section style starts */

.fans-challenge-list-wrapper {
    border-radius: 14px;
    background-color: $white;
    box-shadow: 0 16px 16px 0 rgba(0, 0, 0, 0.05);
    background: url("../img/fan-challenge-bg.png") no-repeat center;
    background-size: cover;
    height: 150px;
    padding: 15px;

    ul {
        padding: 0;
        margin: 0;
        list-style-type: none;

        li {
            display: inline-block;
            text-align: center;
            color: $white;
            width: 30%;
            vertical-align: middle;

            &:nth-child(2) {
                width: 40%;
            }

            .team-img {
                max-width: 50px;
                max-height: 50px;
            }

            .team-name {
                font-size: 14px;
                font-family: $font-bold;
                margin-top: 5px;
                margin-bottom: 0;
                text-transform: uppercase;
            }

            .joined-entries {
                margin-bottom: 8px;
                font-size: 11px;
                font-family: $font-bold-italic;
            }

            .btn {
                width: 80px;
            }

            .contest-type {
                font-size: 14px;
                font-family: $font-xtrabold-italic;
                text-transform: uppercase;
            }

            .timer {
                color: $red;
                font-size: 12px;
                font-family: $font-bold;
                margin-top: 5px;
            }
        }
    }
}

/* Fans Challenge section select palyer style starts */

.fan-challenge-team-selection {
    .fans-challenge-list-wrapper {
        height: 170px;
        border-radius: 0;
        padding: 20px;
        position: relative;
        background: url("../img/fan-challenge-bg2.png") no-repeat center center;
        background-size: cover;

        ul {
            li {
                vertical-align: middle;

                .joined-entries {
                    margin-bottom: 10px;
                }

                .team-name {
                    margin-top: 10px;
                }
            }
        }

        .comment-span {
            display: inline-block;
            border-radius: 50%;
            font-size: 20px;
            text-align: center;
            background: linear-gradient(73.19deg, $ternary 0%, $primary 100%);
            box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2), 0 5px 10px 0 rgba(0, 0, 0, 0.1);
            opacity: 0.95;
            position: absolute;
            bottom: -20px;
            left: 50%;
            transform: translateX(-50%);
            width: 40px;
            height: 40px;
            color: $white;
            line-height: 38px;
        }

        .fan-count {
            font-size: 12px;
            font-family: $font-bold-italic;
            margin-top: 4px;
            margin-bottom: 6px;
        }
    }

    &.fan-challenge-team-selection-alt {
        .fans-challenge-list-wrapper {
            padding: 30px 20px;
        }
    }
}

.select-player-list {
    width: 50%;
    float: left;

    th {
        color: $gray-550;
        font-family: $font-regular;
        font-size: 10px;
        padding: 10px 15px;
        border-bottom: 1px solid $border-light;

        &:nth-child(2) {
            text-align: right;
        }
    }

    td {
        border-bottom: 1px solid $border-light;
        color: $gray-900;
        font-size: 12px;
        font-family: $font-bold;
        padding: 15px 0;
        vertical-align: middle;

        &:first-child {
            padding-left: 10px;
            padding-right: 5px;
        }

        &:nth-child(2) {
            text-align: right;
            width: 10%;
            padding-right: 10px;

            img {
                margin: 0 0 0 5px;
            }

            i {
                float: left;
            }
        }

        img {
            max-width: 30px;
            max-height: 30px;
            border-radius: 50px;
            margin-right: 5px;
        }

        i {
            float: right;
            font-size: 18px;
            line-height: 28px;
            color: $gray-550;

            &.icon-add-user {
                color: $ternary;
            }
        }

        span {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 50px;
            display: inline-block;
            vertical-align: middle;
        }
    }

    &.last-fan-joined-section {
        width: 100%;
        position: absolute;
        bottom: 0;
        box-shadow: 0 -4px 14px 0 rgba(0, 0, 0, 0.2);
        z-index: 5;
        background: $white;

        td {
            color: $gray-755;
            font-size: 12px;
            font-family: $font-italic;

            &:first-child,
            &:nth-child(2) {
                width: 50%;
            }

            .timer {
                color: $txt-light;
                font-family: $font-black;
                margin-bottom: 0;
            }
        }

        &.total-pts {
            td {
                width: 10%;
                font-size: 20px;
                color: $gray-900;
                font-family: $font-black;

                &:first-child,
                &:last-child {
                    width: 40%;
                    font-size: 12px;
                }

                &:nth-child(3) {
                    padding-left: 10px;
                }

                &:last-child {
                    text-align: right;
                    padding-right: 10px;
                }
            }
        }
    }

    &.select-player-list-right {
        border-left: 1px solid $border-light;

        td {
            &:nth-child(2) {
                width: 90%;
            }

            i {
                float: left;
            }

            &.text-right {
                img {
                    margin-left: 5px;
                    margin-right: 0;
                }
            }
        }
    }
}

.user-pts-section {
    position: relative;
    background: $gray-150;
    box-shadow: 0 16px 16px 0 rgba(0, 0, 0, 0.05);
    padding: 15px 15px 30px;
    color: $secondary;
    text-align: center;
    margin-bottom: 20px;

    .your-name {
        text-transform: uppercase;
        font-size: 12px;
        font-family: $font-bold-italic;
    }

    .user-pts {
        font-size: 16px;
        font-family: $font-black;
    }

    .comment-span {
        display: inline-block;
        border-radius: 50%;
        font-size: 20px;
        text-align: center;
        background: linear-gradient(73.19deg, $ternary 0%, $primary 100%);
        box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2), 0 5px 10px 0 rgba(0, 0, 0, 0.1);
        opacity: 0.95;
        position: absolute;
        bottom: -20px;
        left: 50%;
        transform: translateX(-50%);
        width: 40px;
        height: 40px;
        color: $white;
        line-height: 38px;
    }
}

.share-txt {
    color: $secondary;
    font-size: 14px;
    font-family: $font-bold;
    text-transform: uppercase;
    margin-top: 20px;

    i {
        display: inline-block;
        margin-right: 5px;
    }
}

.completed-fixture-collection-wrapper,
.live-fixture-collection-wrapper {
    .card-view-right {
        padding: 25px 20px;
    }
}

.completed-fixture-collection-wrapper {
    .card-view-right {
        .play-btn {
            margin-top: 5px;
            display: inline-block;
        }
    }
}

.live-fixture-collection-wrapper {
    .card-view-right {
        .play-btn {
            margin-top: 25px;
            display: inline-block;
        }
    }
}

.training-fixture-collection-wrap {
    .card-view-right {
        .play-btn {
            margin-top: 30px;
            display: inline-block;
        }
    }
}

.mycontest-wrap {
    &.wrapper-spacing {
        padding-top: 0;
        padding-bottom: 40px;
    }

    .collection-list-wrapper {
        .progress {
            @media (max-width: 320px) {
                width: 50%;
            }
        }

        .btn-rounded {
            padding: 8px 10px;

            @media (max-width: 320px) {
                padding: 6px;
                font-size: 10px;
            }
        }

        .your-rank-label {
            font-size: 11px;
            font-family: $font-bold-italic;
            color: $gray-675;
            text-transform: capitalize;
            float: right;
            margin-right: 30px;

            @media (max-width: 320px) {
                margin-right: 10px;
            }
        }

        .your-rank-count {
            color: $secondary;
            font-size: 16px;
            font-family: $font-black-italic;
            float: right;
            margin-right: 30px;
            display: inline-block;
            min-width: 50px;
            text-align: center;
            position: relative;
            top: -6px;

            @media (max-width: 320px) {
                margin-right: 10px;
            }
        }
    }
}

.my-contest-filter {
    padding: 15px 15px 0;
}

.lb_type_tab15 {
    display: flex;
    align-items: center;
    width: 100%;
    // height: 44px;
    background-color: #ffffff;
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.15);
    padding: 0 15px;
    .ct-tab {
        flex: 1;
        text-align: center;
        height: 100%;
        height: 45px;
        // display: flex;
        // align-items: center;
        // justify-content: center;
        padding: 15px 5px;
        color: #7e7e7e;
        // font-family: "Muli SemiBold";
        font-size: 11px;
        border-bottom: solid 4px transparent;
        margin: 0px 15px;
        text-transform: uppercase;
        font-weight: 700;
        letter-spacing: 1px;
        cursor: pointer;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        &.active {
            color: #023320;
            border-bottom-color: $primary;
            cursor: default;
        }
    }
}

.lobby-web-container {
    &.refsoc-sec {
        .header-wrap {
            background-color: #ff005a;
        }
        .page-inner-header {
            background: #ff005a;
        }
    }
}

