.tab-navigation {
    padding: 0;
    margin: 0;

    li {
        width: 33.33%;
        padding: 0 3px;

        a {
            background: $white;
            text-align: center;
            display: block;
            border-radius: 6px 6px 0 0;
            padding: 15px 2px;
            font-family: $font-black;
            text-transform: uppercase;
            color: $black;
            font-size: 10px;

            &:hover,
            &:active,
            &.active {
                color: $black !important;
            }

            &:focus {
                color: $black !important;
            }
        }

        &.active {
            a {
                background: $black;
                color: $white !important;
            }
        }
    }

    &.tab-navigation-four {
        li {
            width: 25%;

            @media(max-width: 320px) {
                padding: 0 1px;

                a {
                    font-size: 9px;
                }
            }
        }
    }

    &.tab-navigation-two {
        li {
            width: 50%;
        }
    }

    &.tab-navigation-100 {
        li {
            width: 100%;
        }
    }

    &.tab-navigation-upper {
        margin-top: -45px;
        position: relative;
        z-index: 2;
        padding: 0 10px;

        &.tab-navigation-four {
            padding: 0 2px 0;

            @media (max-width: 320px) {
                margin-top: -43px;
            }
        }
    }
}

.refsocial-web-container {
    .tab-navigation {
        li {
            &.active {
                a {
                    background: $refsocial-secondary-clr;
                    color: $refsocial-clr !important;
                }
            }
        }
    }
}