.sorting-section {
    background: $white;
}

.select-blogs {
    max-width: 110px;

    .css-vj8t7z {
        border: none;
        background: transparent;
    }

    .css-1492t68 {
        color: $gray-900;
        font-family: $font-bold;
    }
}

.sortby {
    @extend .select-blogs;
    color: $gray-550;
    font-size: 12px;
    max-width: 125px;

    .css-vj8t7z {
        font-size: 12px;
    }

    .css-1492t68 {
        font-family: $font-regular;
    }

    .css-1ep9fjw {
        padding-right: 10px;
    }
}

.sort-by-section {
    .form-group {
        width: 130px;
        display: inline-block;
    }
}

.blog-section {
    padding: 15px;
}

.blog-detail {
    background: $white;
    border-radius: 14px;
    overflow: hidden;
    box-shadow: 0 16px 16px 0 rgba(0, 0, 0, 0.05);
}

.blog-header {
    height: 160px;

    img {
        height: 100%;
        width: 100%;
    }
}

.blog-body {
    padding: 15px;
}

.blog-time {
    color: $gray-200;
    font-size: 12px;
    font-family: $font-bold-italic;
    margin-bottom: 5px;
}

.blog-heading {
    font-family: $font-xtrabold;
    font-size: 20px;
    color: $secondary;
}

.blog-description {
    margin-top: 10px;
    margin-bottom: 5px;
    font-family: $font-regular;
    font-size: 14px;
    color: $gray-910;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    max-width: 90%;
}

.blog-action {
    padding: 15px;

    .user-fav {
        border-radius: 50%;
        display: inline-block;
        width: 34px;
        height: 34px;
        color: $gray-460;
        padding: 7px 10px;
        font-size: 14px;
        line-height: 19px;
        border: 1px solid $gray-460;
        box-shadow: 0 8px 8px 0 rgba(0, 0, 0, 0.08);

        &.liked {
            color: $white;
            border: none;
            background: $reflive-clr;
            box-shadow: none;
            box-shadow: 0 8px 8px 0 rgba(0, 0, 0, 0.08);
        }
    }

    span.txt {
        font-size: 14px;
        font-family: $font-bold;
        color: $blue-800;
        margin-left: 8px;
        display: inline-block;
    }

    i.icon-comment {
        color: $gray-550;
        font-size: 24px;
    }
}

.action-count {
    padding-top: 6px;
    display: inline-block;

    i {
        font-size: 18px;
        color: $gray-550;

        &.icon-dislike {
            font-size: 21px;
        }
    }
}

// blog detail styling

.blog-detail-section {
    .blog-detail {
        border-radius: 0;
    }

    .blog-body {
        .blog-time {
            margin-top: 10px;
            margin-bottom: 10px;
        }

        .blog-description {
            max-width: 100%;
        }
    }

    .blog-action {
        border-bottom: 1px solid $border-light;
        padding: 15px 0;
    }

    .blog-description {
        display: block;
    }
}

.blog-comment {
    padding: 0 18px 20px 18px;

    h2 {
        border-top: 1px solid $border-light;
        padding-top: 20px;
        font-size: 16px;
        color: $gray-900;
        font-family: $font-bold;
    }

    form {
        label {
            font-size: 12px;
            font-family: $font-bold;
            color: $gray-550;
        }

        padding: 5px 10px 0px 0px;

        .form-control {
            background: $gray-150;
            color: $gray-200;
            font-family: $font-regular;
            font-size: 14px;
            border-radius: 6px;
            border: none;
        }
    }

    .btn-rounded {
        padding: 13px 30px;
        font-size: 14px;
        border-radius: 22px;
    }

    .comment-action-btn {
        border-bottom: 1px solid $border-light;
        padding-bottom: 18px;
    }
}

.blog-by {
    display: inline-block;
    margin-left: 20px;
    font-size: 12px;

    span {
        color: $secondary;
    }
}

.particular-blog-comments {
    .msg-section {
        padding-top: 0;

        .media-body {
            padding: 0;
        }

        .comment-txt {
            box-shadow: none;
            padding-top: 0;
            padding-bottom: 0;

            .comment-by {
                margin-bottom: 5px;
            }

            .comment-time {
                padding-bottom: 15px;
                border-bottom: 1px solid $border-light;
            }
        }

        &:last-child {
            .comment-txt {
                .comment-time {
                    border-bottom: none;
                }
            }
        }
    }
}

.similar-blogs-section {
    .heading {
        background: $secondary;
        padding: 5px 8px;
        text-align: center;
        text-transform: uppercase;
        color: $white;
        font-size: 12px;
        font-family: $font-bold;
    }

    .similar-blog-wrap {
        padding: 10px;
    }

    .left-section,
    .right-section {
        display: inline-block;
        vertical-align: top;
    }

    .left-section {
        width: 90px;

        img {
            width: 90px;
            height: 90px;
        }
    }

    .right-section {
        padding: 10px 10px 10px 20px;
        width: calc(100% - 90px);

        .txt {
            font-size: 12px;
            line-height: 22px;
            font-family: $font-bold;
            color: $gray-900;
            display: block;
            margin-bottom: 10px;
            display: -webkit-box;
            max-width: 100%;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .date-time {
            font-size: 10px;
            font-family: $font-bold-italic;
            color: $gray-550;
        }
    }
}

.live-indicator {
    display: inline-block;
    border-radius: 50px;
    width: 7px;
    height: 7px;
    background: $red;
    margin-right: 5px;
}