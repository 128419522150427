.coming-soon-container {
    text-align: center;
    font-size: 16px;
    font-family: $font-bold-italic;
    color: $gray-900;
    margin-bottom: 5px;
    text-transform: uppercase;
    min-height: calc(100vh - 159px);
    box-shadow: none;
    padding: 0;
    position: relative;

    .coming-soon-inner-content {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        margin: 0 auto;
        left: 0;
        right: 0;
    }
}

.sponsered-coming-soon {
    .coming-soon-container {
        min-height: calc(100vh - 210px);
    }
}