.result-section {
    position: relative;

    .panel {
        box-shadow: 4px 4px 8px 0 rgba(0, 0, 0, 0.1);
    }

    .panel-title {
        a {
            background: transparent;
            color: $gray-900;
            padding: 15px 20px;
            border-bottom: 0;
            display: block;
            font-size: 14px;
            font-family: $font-bold;
            text-transform: capitalize;

            i {
                color: $gray-550;
                font-size: 12px;
                float: right;
                line-height: 22px;

                &.icon-remove {
                    display: inline-block;
                    color: $ternary;
                }

                &.icon-plus {
                    display: none;
                }
            }

            &.collapsed {
                background: $white;
                border-bottom: 1px solid $border-light;

                i {
                    &.icon-remove {
                        display: none;
                    }

                    &.icon-plus {
                        display: inline-block;
                    }
                }
            }
        }
    }

    .panel-collapse {
        padding: 0 15px 15px;

        ul {
            margin: 0;
            list-style-type: none;
            padding: 0;
            background: $white;
            border-radius: 5px;
            overflow: hidden;
            box-shadow: 4px 4px 8px 0 rgba(0, 0, 0, 0.1);

            li {
                border-bottom: 1px solid $border-light;
                cursor: pointer;

                .display-table-cell {
                    font-size: 14px;
                    padding: 15px;
                    color: $gray-675;
                    vertical-align: middle;
                    text-transform: uppercase;
                    font-family: $font-bold;

                    span {
                        color: $red;
                        text-transform: none;
                    }

                    &.your-score {
                        font-size: 12px;
                        font-family: $gray-550;
                        font-family: $font-bold-italic;
                        text-align: right;
                        padding-right: 40px;
                        position: relative;
                        text-transform: none;

                        p {
                            margin: 0;
                            font-size: 14px;
                            color: $gray-900;
                        }

                        i {
                            position: absolute;
                            right: 15px;
                            top: 50%;
                            transform: translateY(-50%);
                            color: $gray-550;
                            font-size: 16px;
                        }
                    }
                }
            }
        }
    }
}