.section-one {
    height: 100vh;
    position: relative;
    width: 100%;
    background: url('../img/section1-bg.png');
    background-repeat: no-repeat;
    background-size: cover;

    @media (max-width: 1200px) {
        height: 80vh;
    }

    @media (max-width: 767px) {
        height: 100%;
        min-height: 100vh;
        position: relative;
    }
}

.section-text {
    padding-top: 180px;

    @media (max-width: 1366px) {
        padding-top: 200px;
    }

    @media (max-width: 1200px) {
        padding-top: 320px;
    }

    @media (max-width: 991px) {
        padding-top: 180px;
    }

    @media (max-width: 767px) {
        padding-bottom: 380px;
    }

    @media (max-width: 414px) {
        padding-bottom: 230px;
    }
}

.text-h3 {
    font-size: 24px;
    line-height: 1.4;
    font-family: $font-saira-bold;
    color: $white;

    @media (max-width: 414px) {
        font-size: 20px;
        padding: 0 20px;
    }
}

.text-h1 {
    font-family: $font-saira-bold;
    color: #D5BB8E;
    font-size: 48px;
    line-height: 1.2;

    @media (max-width: 414px) {
        font-size: 40px;
        padding: 0 20px;
    }
}

.play-now-btn-container {
    margin-top: 44px;
    margin-bottom: 100px;

    @media (max-width: 1200px) {
        margin-bottom: 220px;
    }

    @media (max-width: 767px) {
        margin-bottom: 100px;
        padding: 0 20px;
    }
}

.play-now-btn {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
    background: linear-gradient(167.3deg, #FFEBC9 0%, #D5BB8E 100%);
    border-radius: 22px;
    color: $black;
    font-size: 14px;
    font-family: $font-bold;
    text-transform: uppercase;
    width: 200px;
    padding: 0;
    padding: 11px 15px;
    display: inline-block;

    &:hover {
        color: $black;
    }

    cursor: pointer;

    @media (min-width: 481px) {
        &:last-child {
            margin-left: 15px;
        }
    }

    @media (max-width: 480px) {
        &:last-child {
            margin-top: 15px;
        }
    }
}

.mobile-img {
    position: relative;
    // top: -140px;
    text-align: center;

    @media (max-width: 1366px) {
        img {
            width: 570px;
        }
    }

    @media (max-width: 991px) {
        top: -30px;

        img {
            width: 780px;
            width: 580px;
        }
    }

    @media (max-width: 767px) {
        position: absolute;
        top: auto;
        bottom: -50px;
        left: 0;
        right: 0;
        margin: 0 auto;
    }

    @media (max-width: 414px) {
        img {
            width: 340px;
        }
    }

    @media (max-width: 320px) {
        img {
            width: 300px;
        }
    }
}

.parallax-effect {
    height: 300px;

    @include media-breakpoint-down(sm) {
        height: 200px;
    }
}

.dashboard-header {
    position: absolute;
    top: 0;
    left: 0;
    padding: 15px 40px 0;
    width: 100%;
    z-index: 2;

    &.dashboard-header-blue {
        background: $blue-500;
    }

    .drawer-header {
        display: none;
    }

    @include media-breakpoint-down(md) {
        padding: 15px 0 0;

        .drawer-header {
            display: inline-block;
            padding: 0 20px;

            i {
                color: $white;
            }
        }
    }
}

.brand-logo {
    img {
        width: 80px;
    }

    @media (max-width: 767px) {
        img {
            width: 60px;
        }
    }
}

.section-second {
    background: $white;

    .section-fixed {
        height: 200px;

        @media (max-width: 1400px) {
            height: 350px;
        }

        @media (max-width: 767px) {
            height: 100px;
        }
    }
}

.play-pbl-img {
    width: 135px;
}

.footer-section {
    color: $white;
    background: $black;

    .section-disclaimer {
        background: $blue-500;
        padding: 30px 0;
        font-size: 10px;
        font-family: $font-regular;

        @include media-breakpoint-down(sm) {
            padding: 30px 15px;
        }

        p {
            opacity: 0.4;
        }
    }

    .footer-link-section {
        padding: 45px 0;

        .footer-heading {
            margin-bottom: 35px;
        }

        ul {
            list-style-type: none;
            padding: 0;
            margin: 0;

            li {
                a {
                    color: $white;
                    font-size: 14px;
                    font-family: $font-regular;
                    text-transform: capitalize;
                    padding: 5px 0;
                    display: inline-block;

                    i {
                        font-size: 18px;
                        margin-right: 10px;
                        position: relative;
                        top: -2px;
                        color: $gray-550;
                    }
                }
            }

            &.social-link {
                li {
                    a {
                        text-transform: none;
                    }
                }
            }
        }

        @include media-breakpoint-down(sm) {
            padding: 10px 0;

            .footer-links {
                padding: 15px;

                .footer-heading {
                    margin-bottom: 10px;
                }
            }
        }
    }
}

.footer-heading {
    font-size: 14px;
    font-family: $font-bold;
    text-transform: uppercase;
}

.footer-bottom-section {
    padding: 30px 0;
    border-top: 2px solid rgba(255, 255, 255, 0.1);

    @include media-breakpoint-down(sm) {
        padding: 30px 15px;
    }
}

.download-app-body {
    font-size: 14px;
    font-family: $font-regular;
    padding: 15px;

    a {
        font-size: 14px;
        color: $primary;

        &:hover {
            color: darken($primary, 15%);
        }
    }
}

.section-third {
    height: 300px;
    position: relative;

    .keyfeature {
        font-size: 40px;
        font-family: $font-saira-bold;
        color: $white;
        text-transform: uppercase;
        position: absolute;
        top: 50%;
        left: 0;
        margin: 0 auto;
        right: 0;
        text-align: center;
        transform: translateY(-50%);
    }
}

.section-sixth {
    padding: 60px 0;
    box-shadow: 0 -16px 16px 0 rgba(0, 0, 0, 0.05);
    background: $white;

    .follow-us-heading {
        font-family: $font-saira-bold;
        color: $gray-900;
        font-size: 20px;
        margin-bottom: 35px;
    }
}

.follow-links {
    box-shadow: 0 0 20px 0 #D5BB8E;
    background: $white;
    height: 88px;
    width: 88px;
    border-radius: 50%;
    display: inline-block;

    &:nth-child(2) {
        margin: 0 45px;
    }

    i {
        font-size: 26px;
        line-height: 88px;
        color: #D5BB8E;
    }

    @include media-breakpoint-down(sm) {
        width: 70px;
        height: 70px;

        i {
            font-size: 18px;
            line-height: 70px;
        }
    }
}

.section-fifth {
    background: $white;

    .left-section,
    .right-section {
        padding: 130px 0;

        @include media-breakpoint-down(md) {
            padding: 10px 0;
            display: block;
            text-align: center;
        }
    }

    @include media-breakpoint-down(md) {
        .left-section {
            padding-top: 50px;
        }

        .right-section {
            padding-bottom: 50px;
        }
    }

    h1 {
        font-size: 60px;
        font-family: $font-saira-bold;
        color: $gray-900;
        margin-bottom: 30px;

        @include media-breakpoint-down(sm) {
            font-size: 44px;
            margin-bottom: 15px;
        }
    }

    p {
        margin: 0;
        font-size: 20px;
        color: $gray-900;
        font-family: $font-regular;

        @include media-breakpoint-down(sm) {
            font-size: 16px;
        }
    }

    .left-section {
        @include media-breakpoint-down(sm) {
            img {
                max-width: 60%;
            }
        }
    }

    .right-section {
        @include media-breakpoint-down(sm) {
            img {
                width: 140px;
            }
        }
    }
}

.count {
    font-size: 60px;
    font-family: $font-saira-bold;
    color: $gray-575;

    @include media-breakpoint-down(sm) {
        font-size: 42px;
    }
}

.separator {
    height: 60px;
    margin: 35px 0;

    div {
        background: #D5BB8E;
        width: 10px;
        height: 60px;
    }

    @include media-breakpoint-down(sm) {
        height: 50px;
        margin: 15px 10px;

        div {
            height: 50px;
        }
    }
}

.section-heading {
    font-size: 60px;
    line-height: 1;
    font-family: $font-saira-bold;
    color: $gray-900;
    text-transform: uppercase;
    margin-bottom: 40px;

    @include media-breakpoint-down(sm) {
        font-size: 44px;
        margin-bottom: 20px;
    }
}

.section-description {
    font-family: $font-regular;
    font-size: 14px;
    color: $gray-900;
    margin-bottom: 12px;
    max-width: 370px;
}

.even-section {
    .text-section {
        text-align: right;
        background: url('../img/bg-img.png') no-repeat;
    }
}

.section-fourth {
    background: $gray-170;
    box-shadow: 0 16px 16px 0 rgba(0, 0, 0, 0.05);
}

.even-section {
    padding-bottom: 20px;
}

.odd-section,
.even-section {
    .display-table-cell {
        width: 50%;

        @include media-breakpoint-down(md) {
            width: 100%;
            display: block;

            &.text-section {
                max-width: 70%;
                margin: 0 auto;
            }

            &:not(.text-section) {
                text-align: center;
            }
        }

        @include media-breakpoint-down(sm) {
            &.text-section {
                max-width: 100%;
                padding: 20px;
            }

            img {
                max-width: 100%;
            }
        }
    }
}


.modal-download-app-dialog.modal,
.modal-backdrop.modal-download-app {
    max-width: 100vw !important;
}

.modal-download-app-dialog {
    .modal-header {
        border-bottom: 0;

        .modal-title {
            font-size: 26px;
            font-family: $font-black;
            text-transform: uppercase;
            text-align: center;
        }
    }

    .auth-txt.sm {
        padding-bottom: 0;
    }

    .modal-footer {
        padding: 0;
        overflow: hidden;
        border-radius: 0 0 13px 15px;
        border-top: 0;

        .btn {
            width: 100%;
            border-radius: 0;

            &.click-disabled {
                background: linear-gradient(90deg, $btn-disable-bg, $btn-disable-bg 60%, $btn-disable-bg 90%);
                color: $btn-disable-color;

            }
        }
    }
}

.even-section {
    .display-table-cell {
        padding: 150px 0 0;

        @include media-breakpoint-down(md) {
            padding: 60px 0 0;
        }
    }
}

.odd-section {
    .display-table-cell {
        padding: 20px 0 0;
    }

    &.odd-section-with-img {
        .display-table-cell {
            padding: 200px 0 130px;

            @include media-breakpoint-down(md) {
                padding: 60px 0 20px;
            }

            @include media-breakpoint-down(sm) {
                padding: 60px 20px 20px;
            }
        }
    }
}

.fantasy-ground-img {
    position: relative;
    top: -90px;

    @include media-breakpoint-down(md) {
        position: initial;
    }
}

.navbar-list {
    display: inline-block;
    list-style-type: none;

    &:not(.pull-right) {
        margin-top: 10px;
    }

    &.pull-right {
        @media (max-width: 767px) {
            padding: 0 5px 0 0;
        }
    }

    li {
        display: inline-block;

        a {
            font-size: 12px;
            font-family: $font-bold;
            color: $white;
            text-transform: uppercase;
            padding: 20px;
        }

        &.active,
        &:hover {
            a {
                position: relative;

                &:after {
                    content: '';
                    position: absolute;
                    width: 100%;
                    height: 4px;
                    background: linear-gradient(90deg, rgba(21, 21, 21, 0) 0%, #D5BB8D 100%);
                    left: 0;
                    bottom: 2px;
                    border-radius: 2px;
                }

                &.btn {
                    &:after {
                        display: none;
                    }
                }
            }
        }

        .btn {
            border: 2px solid #D5BB8E;
            box-shadow: 0 2px 16px 0 #D5BB8E;
            border-radius: 20px;
            padding: 7px 16px;

            @media (max-width: 360px) {
                padding: 3px 12px;
                font-size: 10px;
            }
        }
    }
}

.download-app-section {
    position: fixed;
    right: 30px;
    top: 110px;
    z-index: 2;

    img {
        margin-bottom: 15px;
        display: block;
        width: 48px;
        cursor: pointer;
    }

    @media (max-width: 767px) {
        top: 80px;
        right: 10px;
    }
}

.home-page {
    .icon-close {
        color: #333;
        position: absolute;
        display: inline-block;
        right: 40px;
        top: 20px;
    }

    @media (min-width: 992px) {

        .drawer-header,
        .drawer-menu-wrapper {
            display: none;
        }
    }

    @media (max-width: 991px) {
        .navbar-list.header-link-list {
            display: none;
        }
    }

    .drawer-menu-item a {
        font-size: 20px;
        padding: 15px;
    }
}

.section-second {
    padding-bottom: 80px;

    .slick-initialized .slick-slide {
        overflow: hidden;
        border-radius: 15px;
    }

    .how-play-section {
        text-align: center;

        // padding-left: 15%;
        // @media (max-width: 991px){
        //     padding-left: 5%;
        // }
        h1 {
            font-size: 40px;
            font-family: $font-saira-bold;
            color: $gray-900;
            text-transform: uppercase;
            margin-bottom: 50px;
        }

        img {
            width: 75px;
        }

        .htp-text {
            text-transform: uppercase;
            font-size: 24px;
            font-family: $font-saira-bold;
            color: $gray-900;
            line-height: 80px;

            span {
                color: #D5BB8E;
            }
        }

        .htp-steps {
            position: relative;
            height: 80px;
            padding-left: 100px;
            margin-bottom: 35px;
            width: 33.33%;
            display: inline-block;
            padding: 0;
            text-align: center;

            @media (max-width: 767px) {
                width: 100%;
                margin-bottom: 100px;
            }
        }
    }
}


.sms-checkbox {
    .custom-checkbox {
        label {
            line-height: 1.8;
        }

        input[type="checkbox"],
        input[type="radio"] {
            position: absolute; // take it out of document flow
            opacity: 0; // hide it
            padding: 30px 0 50px;

            &~span {
                position: relative;
                cursor: pointer;
                padding: 0;
                display: block;
                width: 100%;
                padding-right: 30px;
                padding-left: 30px;
                max-width: 100%;

                &.auth-txt {
                    font-size: 12px;
                    color: $gray-650;
                    font-family: $font-bold;
                }
            }

            // Box.
            &~span:before {
                content: '';
                margin-right: 10px;
                display: inline-block;
                vertical-align: text-top;
                width: 14px;
                height: 14px;
                background: transparent;
                border-radius: 2px;
                border: 1px solid $white;
                border: 1px solid $gray-550;
                margin: 0;
                position: absolute;
                left: 7px;
                top: 3px;
            }

            // Box checked
            &:checked~span {

                // color: $white;
                &:before {
                    background: $blue;
                    border: none;
                }
            }

            // Disabled state label.
            &:disabled~span {
                color: #b8b8b8;
                cursor: auto;
            }

            // Disabled box.
            &:disabled~span:before {
                box-shadow: none;
                background: #ddd;
            }

            // Checkmark. Could be replaced with an image
            &:checked~span:after {
                content: '';
                position: absolute;
                left: 9px;
                top: 9px;
                background: $white;
                width: 2px;
                height: 2px;
                box-shadow:
                    2px 0 0 $white,
                    4px 0 0 $white,
                    4px -2px 0 $white,
                    4px -4px 0 $white,
                    4px -6px 0 $white,
                    4px -8px 0 $white;
                transform: rotate(45deg);

            }
        }
    }
}

iframe {
    @media (max-width: 767px) {
        width: 100%;
        height: 300px;
    }
}

.visible-md {
    @media (max-width: 991px) {
        display: none !important;
    }
}

.visible-sm {
    @media (min-width: 992px) {
        display: none;
    }
}

.home-page {
    .drawer-menu-wrapper.drawer-menu-wrapper-open {
        transform: translate3d(-15px, 0px, 0px)
    }
}

.navbar-list-right {
    margin-top: 20px;
    margin-right: 10px;

    @media (max-width: 767px) {
        margin-top: 14px;
    }
}

.drawer-menu-wrapper {
    background: $white;
    z-index: 99999;
}