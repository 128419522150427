.profile-web-container {
    background: $white;

    .page-inner-header {
        height: 130px;
    }

    .coming-soon-container {
        min-height: 380px;
        background: transparent !important;
    }
}

.profile-header {
    background: $white url('../img/gray-bg-img.png') no-repeat;
    background-position: left bottom;
    text-align: center;
    padding-bottom: 20px;

    &.profile-header-without-bg {
        background: $white;
    }

    .user-thumbnail {
        height: 134px;
        width: 134px;
        margin: -62px auto 0;
        position: relative;

        img {
            box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
            border: 3px solid $white;
            width: 100%;
            height: 100%;
            border-radius: 50%;
            overflow: hidden;
            object-fit: cover;
        }

        .goto-msg-section {
            background: $white;
            display: block;
            box-shadow: 0 16px 16px 0 rgba(0, 0, 0, 0.05);
            width: 38px;
            height: 38px;
            font-size: 20px;
            color: $gray-675;
            text-align: center;
            padding: 2px 5px;
            border-radius: 50%;
            position: absolute;
            right: -8px;
            top: -4px;
        }
    }

    .user-detail {
        .user-name {
            font-size: 20px;
            font-family: $font-black;
            color: $gray-900;
            margin: 15px 0 2px;
        }

        .member-since {
            font-size: 12px;
            font-family: $font-italic;
            color: $gray-675;
        }

        .ref-id{
            color: $primary;
            font-family: $font-black;
        }
    }

    ul.your-profile-detail {
        padding: 0;
        margin: 20px 0 0;
        list-style-type: none;

        li {
            display: inline-block;
            font-size: 12px;
            font-family: $font-bold-italic;
            color: $gray-675;
            text-align: center;
            width: 33.33%;

            span {
                display: block;
                color: $gray-900;
                font-size: 20px;
                font-family: $font-black;
            }
        }
    }
}

.user-records {
    text-align: center;
    border-top: 1px solid $border-light;
    border-bottom: 1px solid $border-light;
    padding: 20px 10px;
    background: $white;
    @media (max-width: 320px) {
        padding: 20px 4px;
    }
    &:nth-child(2) {
        border-left: 1px solid $border-light;
    }

    .records-label {
        font-size: 11px;
        font-family: $font-bold-italic;
        color: $gray-675;
        text-transform: uppercase;
        margin-bottom: 10px;

        span {
            color: $gray-900;
        }

        ~div {
            padding-right: 8px;

            span {

                >span.icon-star {
                    font-size: 16px;
                    margin-left: 8px;
                    color: $gray-460;

                    &.highlighted {
                        color: $fav-color;
                    }
                }
            }
        }
    }

    .your-team-records {
        padding-right: 2px !important;
        img {
            width: 22px;
            @media (max-width: 320px) {
                width: 20px;
            }
        }

        img:not(:first-child) {
            margin-left: 5px;
        }
    }
}

.similar-profile {
    .heading {
        background: $gray-570;
        padding: 7px 15px;
        text-transform: uppercase;
        color: $gray-675;
        font-size: 11px;
        font-family: $font-black;
        letter-spacing: 0.92px;
    }

    ul {
        list-style-type: none;
        background: $white;
        margin: 0;
        padding: 0;

        li {
            display: table;
            width: 100%;

            div {
                display: table-cell;
                vertical-align: middle;
                padding: 15px;
                font-size: 14px;
                font-family: $font-bold;
                color: $gray-900;
                border-bottom: 1px solid $gray-570;

                img {
                    width: 40px;
                    height: 40px;
                    border-radius: 50%;
                    margin-right: 10px;
                }

                i {
                    font-size: 20px;
                    color: $gray-675;
                    float: right;
                    line-height: 36px;

                    &.icon-mail {
                        position: relative;
                        right: 4px;
                        ;
                    }
                }
            }
        }
    }
}

.user-basic-info-section {
    padding-top: 10px;
}

.your-detail {
    padding-top: 15px;

    .left-section {
        display: inline-block;
        width: 20%;
        vertical-align: top;
        text-align: center;
        font-size: 20px;
        color: $gray-550;
    }

    .right-section {
        display: inline-block;
        vertical-align: top;
        width: 80%;

        h5 {
            color: $gray-200;
            font-size: 12px;
            font-family: $font-regular;
            margin-bottom: 10px;
        }

        p {
            margin-bottom: 0;
            padding-bottom: 12px;
            border-bottom: 1px solid $border-light;
            color: $gray-900;
            font-family: $font-italic;
            max-width: 90%;
            word-break: break-word;
        }

        p.user-website {
            border-bottom: 0;

            a {
                margin-bottom: 0;
                padding-bottom: 20px;
                color: $secondary;
                font-family: $font-italic;
                max-width: 90%;
                word-break: break-word;
            }
        }
    }

    &:last-child {
        .right-section {
            p {
                border-bottom: none;
            }
        }
    }
}

.user-performance-section {
    .nav-link-container {
        padding: 25px 20px 15px;

        @media (max-width: 320px) {
            padding: 25px 6px;
        }
    }
}

.accuracy-header {
    border-bottom: 1px solid $border-light;
    color: $gray-200;
    font-family: $font-regular;
    font-size: 12px;

    span {
        padding: 15px;
        display: inline-block;
        width: 50%;
    }
}

.p_chart {
    ul {
        width: 50%;
        float: left;
        margin-top: 15px;
        margin-bottom: 9px;

        li {
            list-style: none;
            float: left;
            width: 100%;
            margin-bottom: 15px;
            line-height: 25px;
            font-family: $font-bold;

            .chart_circle {
                width: 25px;
                height: 25px;
                font-size: 12px;
                line-height: 20px;
                text-align: center;
                padding: 3px;
                color: #fff;
                border-radius: 50%;
                float: left;
                margin-right: 10px;
            }
        }
    }
}

.accuracy-section {
    .process {
        background: #F2F2F2;
        height: 10px;
        border-radius: 5px;
        width: 100%;
        max-width: 100%;
        margin: 8px 0;

        .process-bar {
            border-radius: 5px;
            white-space: nowrap;
            -webkit-transition: width 0.6s ease;
            transition: width 0.6s ease;

            .active-bar {
                display: table;
                border-radius: 5px;
                height: 10px;
                opacity: .6;
            }
        }
    }

    ul {
        padding: 10px 0 0;
        margin: 0;
        list-style-type: none;

        li {
            padding: 10px 15px 0 15px;
            font-size: 14px;
            font-family: $font-regular;
            color: $gray-900;

            .correct-ans-perc {
                font-family: $font-bold;
                float: right;
            }

            .progress {
                background: $gray-120;
                height: 10px;
                border-radius: 5px;
                width: 100%;
                max-width: 100%;
            }
        }
    }
}

// post card styling

.your-post-section {
    padding: 20px 15px;
}

.post-card {
    background: $white;
    overflow: hidden;
    border-radius: 14px;
    box-shadow: 0px 16px 16px 4px rgba(0, 0, 0, 0.08);
    margin-bottom: 20px;

    .post-card-header {
        padding-top: 10px;

        .postuser-detail {
            position: relative;
            padding: 0 10px;
            padding-left: 70px;
        }

        img {
            position: absolute;
            left: 10px;
            top: 0px;
            width: 47px;
            height: 47px;
            background: $white;
            border-radius: 50%;
        }

        .user-name {
            font-size: 14px;
            font-family: $font-black;
            color: $gray-900;
            padding: 3px 0 0;

            .user-tag {
                margin-left: 15px;
                font-size: 12px;
                font-family: $font-bold-italic;
                color: $gray-200;
                display: inline-block;
            }
        }

        .post-time {
            font-family: $font-bold-italic;
            color: $gray-200;
            font-size: 12px;
        }

        i {
            position: absolute;
            right: 15px;
            top: 5px;
            color: $gray-675;
            font-size: 15px;

        }
    }

    .post-description {
        padding: 20px 15px 10px;
        font-size: 14px;
        font-family: $font-regular;
        color: $gray-910;
    }

    .post-tag {
        padding: 0 15px;

        span {
            display: inline-block;
            margin-right: 10px;
            color: $gray-200;
            font-family: $font-italic;
            font-size: 12px;
        }
    }

    .posted-img {
        height: 160px;
        width: 100%;
        text-align: center;
        background: $gray-200;
        margin-top: 10px;

        img {
            height: 100%;
            width: 100%;
        }
    }

    .post-card-body {
        background: $white;
    }

    .post-card-footer {
        background: linear-gradient(180deg, #F4F4F4 0%, #FFFFFF 100%);

        .msg-section.media {
            img.user-img {
                width: 30px;
                height: 30px;
            }
        }

        &.enter-comment-section {
            position: static;

            .type-comment-area {
                .form-group {
                    i {
                        font-size: 15px;
                        top: 8px;
                    }

                    .form-control {
                        padding: 6px 40px 6px 15px;
                        height: 30px;
                        line-height: 1;
                        font-size: 12px;
                    }
                }
            }
        }
    }
}

.accuracy-section {
    ul {
        li {
            &:nth-child(6n+1) {
                .progress-bar {
                    background: $progress-clr1;
                }
            }

            &:nth-child(6n+2) {
                .progress-bar {
                    background: $progress-clr2;
                }
            }

            &:nth-child(6n+3) {
                .progress-bar {
                    background: $progress-clr3;
                }
            }

            &:nth-child(6n+4) {
                .progress-bar {
                    background: $progress-clr4;
                }
            }

            &:nth-child(6n+5) {
                .progress-bar {
                    background: $progress-clr5;
                }
            }

            &:nth-child(6n+6) {
                .progress-bar {
                    background: $progress-clr6;
                }
            }
        }
    }
}

.banner-change-section {
    background: rgba(0, 0, 0, 0.6);
    display: block;
    box-shadow: 0 16px 16px 0 rgba(0, 0, 0, 0.05);
    width: 35px;
    height: 35px;
    font-size: 20px;
    color: $white;
    text-align: center;
    padding: 1px 11px;
    border-radius: 0 0 0px 50%;
    position: absolute;
    right: 0;
    top: 0;
    font-size: 17px;

    &:hover,
    &:focus,
    &:active,
    &.active {
        color: $white !important;
    }
}

.user-profile-pic {
    position: relative;

    .select-profile-section {
        background: #fff;
        display: block;
        box-shadow: 0 16px 16px 0 rgba(0, 0, 0, .05);
        width: 38px;
        height: 38px;
        font-size: 20px;
        color: #7e7e7e;
        text-align: center;
        padding: 2px 5px;
        border-radius: 50%;
        position: absolute;
        right: 41%;

        @media (max-width: 428px) {
            right: 32%;
        }

        top: -4px;
    }
}

.setting-list {
    margin: 0 0 15px 0;
    list-style-type: none;
    padding: 0;
    box-shadow: 0 16px 16px 0 rgba(0, 0, 0, 0.05);

    li {
        cursor: pointer;
        padding: 20px;
        border-bottom: 1px solid $border-light;
        color: $gray-900;
        font-size: 16px;
        background: $white;
        font-family: $font-bold;

        &:last-child {
            border-bottom: 0;
        }

        i {
            margin-top: 5px;
            font-size: 16px;
            color: $gray-550;
            float: right;
            cursor: pointer;

            &.tooltip-info {
                margin-left: 8px;
                top: -2px;
            }
        }
    }
}

.set-pin-label {
    padding-top: 20px;
    padding-bottom: 10px;
    display: inline-block;
    color: $gray-675;
    font-size: 12px;
    font-family: $font-regular;
    width: 100%;
    background: $white;
}

.set-pin {
    @extend .setting-list;
    background: $white;
    margin-bottom: 0;
    box-shadow: none;

    li {
        border: 0;
        padding: 20px 20px 0 20px;
        display: inline-block;

        span.label {
            margin-bottom: 10px;
            display: inline-block;
            color: $gray-675;
            font-size: 12px;
            font-family: $font-regular;
            width: 100%;
        }
    }

    .custom-checkbox {
        label {
            margin-bottom: 0;
        }

        input[type="checkbox"],
        input[type="radio"] {
            position: absolute; // take it out of document flow
            opacity: 0; // hide it

            &~span {
                position: relative;
                cursor: pointer;
                padding: 0;
                display: block;
                width: 100%;
                max-width: 100%;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                width: 28px;
                height: 28px;
                padding-right: 30px;
                padding-left: 34px;
                font-size: 16px;
                font-family: $font-bold;
                color: $gray-900;
                line-height: 21px;
            }

            // Box.
            &~span:before {
                content: '';
                margin-right: 10px;
                display: inline-block;
                vertical-align: text-top;
                background: $white;
                border-radius: 50px;
                border: 1px solid $gray-460;
                margin: 0;
                position: absolute;
                right: 0;
                width: 22px;
                height: 22px;
            }

            // Box checked
            &:checked~span {
                color: $gray-900;
            }

            // Disabled state label.
            &:disabled~span {
                color: $gray-550;
                cursor: auto;
            }

            // Disabled box.
            &:disabled~span:before {
                box-shadow: none;
                background: $gray-910;
            }

            // Checkmark. Could be replaced with an image
            &:checked~span:after {
                content: '';
                position: absolute;
                top: 17%;
                width: 12px;
                height: 12px;
                background: linear-gradient(75.01deg, $gradient-clr1 0%, $gradient-clr2 100%);
                border-radius: 50%;
                box-shadow: none;
                left: 8%;
                right: auto;
                transform: translateY(0%);
            }
        }
    }
}

.otp-field-container {
    box-shadow: 0 16px 16px 0 rgba(0, 0, 0, 0.05);
    margin-bottom: 20px;

    .page-inner-header.page-inner-header-sm h2 {
        text-align: left;
    }

    text-align: center;
}

.pin-block {
    background: $white;
    padding: 0 20px 20px;

    .otp-field {
        label {
            text-align: center;
            color: #7E7E7E;
            margin-bottom: 0.2rem;
        }

        >div {
            justify-content: center;
        }

        input {
            font-size: 20px;
            padding: 0 0 6px;
            color: $gray-900;
            width: 100% !important;
            text-align: center;
            letter-spacing: 20px;
        }

        .pdding-right {
            input {
                padding-left: 16px;
            }
        }
    }

    .help-text {
        font-size: 11px;
        font-family: $font-italic;
        color: $gray-550;
        padding: 10px 0 0;
        position: initial;
        text-align: center;
    }
}

.save-pin-btn {
    margin-top: 39px;
    font-size: 12px;
    height: 30px;
    width: 140px;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2), 0 5px 10px 0 rgba(0, 0, 0, 0.1);
}

.change-password-form {
    padding: 20px 24px;
    margin: 0 0 20px;
    background: $white;
    box-shadow: 0 16px 16px 0 rgba(0, 0, 0, 0.05);
}