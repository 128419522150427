.video-step {
    .page-inner-header.page-inner-header-sm {
        padding: 15px;
        height: 103px;

        h2 {
            padding-left: 0;
        }

        .match-date {
            font-size: 10px;
            font-family: $font-bold-italic;
            color: $secondary;
            margin: 4px 0;
        }

        a {
            display: inline-block;
            text-decoration: underline;
            font-size: 12px;
            font-family: $font-bold;
            text-transform: capitalize;
            color: $secondary;
        }
    }

    .btn-rounded {
        width: 200px;
        margin: 60px auto 0;
    }
}

.video-section-header {
    padding: 35px 10px;
    text-align: center;

    h2 {
        font-size: 20px;
        text-transform: uppercase;
        color: $secondary;
        font-family: $font-black;
    }

    p {
        margin: 8px 0 20px;
        color: $gray-900;
        font-size: 12px;
        font-family: $font-bold-italic;
    }

    .show-step {
        span {
            display: inline-block;
            border-radius: 50%;
            width: 40px;
            height: 40px;
            background: $primary;
            color: $secondary;
            text-align: center;
            padding: 5px;
            font-size: 20px;
            font-family: $font-black;
            position: relative;

            i {
                position: relative;
                top: -1px;
            }

            &:first-child {
                margin-right: 100px;

                &:before {
                    content: '';
                    position: absolute;
                    left: 40px;
                    width: 100px;
                    height: 4px;
                    background: $primary;
                    top: 50%;
                    transform: translateY(-50%);
                }
            }
        }
    }
}

.video-body {
    position: relative;

    .video-react {
        width: 100% !important;
        height: auto !important;
        padding-top: 56.2% !important;

        .video-controls {
            display: none;
        }
    }

    .skip-ad {
        display: inline-block;
        position: absolute;
        right: -4px;
        bottom: 40px;
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
        border-radius: 14px 0 0 14px;
        border: 1px solid $white;
        font-size: 12px;
        color: $white;
        font-family: $font-bold;
        text-transform: capitalize;
        padding: 2px 10px;
        background: rgba(0, 0, 0, 0.6);
        z-index: 999;
        cursor: pointer;

        i {
            font-size: 10px;
            position: relative;
            top: -1px;
        }
    }
}