.refpay-web-container {
    color: $refpay-secondary-clr;

    .btn-success {
        background: linear-gradient(62.15deg, $refpay-secondary-clr 0%, $refpay-clr 100%);
    }
}

.atm-card-header {
    margin-top: -85px;
    padding: 15px;
    position: relative;
    background: #F7F7F7;
}

.atm-card-view {
    position: relative;
    border-radius: 14px;
    height: 200px;
    background: #fdffff url('../img/card-img.svg') no-repeat;
    background-size: cover;
    padding: 15px;
    color: $refpay-secondary-clr;
    max-width: 380px;
    z-index: 99;
    margin: 0 auto;
    box-shadow: 0 15px 15px 0 rgba(0, 0, 0, 0.06);

    .tooltip-info {
        color: rgba(16, 18, 67, 0.6);
    }

    .card-user {
        text-align: right;

        img {
            width: 40px;
            height: 40px;
            border-radius: 12px;
            background-color: #D8D8D8;
        }

        .card-user-name {
            color: $gray-900;
            font-size: 14px;
            font-family: $font-black;
            margin-top: 5px;
        }
    }

    .total-bal {
        color: $refpay-secondary-clr;
        position: relative;

        .total-bal-label {
            font-size: 16px;
            font-family: $font-bold;
            text-shadow: 1px 2px 0 rgba(0, 0, 0, 0.1);
        }

        .total-amt {
            font-size: 44px;
            font-family: $font-bold;
            line-height: 1;
            position: relative;
            margin-top: 5px;
            padding-left: 20px;
            text-shadow: 1px 2px 0 rgba(0, 0, 0, 0.1);

            img{
                position: absolute;
                width: 15px;
                left: 0;
            }

            i {
                font-size: 21px;
                position: absolute;
                top: 0;
                left: 0;
            }

            @media (max-width: 400px) {
                font-size: 34px;
                word-break: break-all;
            }
        }
    }

    .total-bonus-bal {
        @extend .total-bal;
        position: absolute;
        left: 15px;
        bottom: 20px;
        font-size: 14px;

        .total-amt {
            font-size: 22px;
        }
    }

    .user-date-section {
        margin-top: 0px;
        font-size: 11px;
        font-family: $font-bold-italic;
        text-align: right;
        position: absolute;
        bottom: 22px;
        right: 20px;

        i {
            display: inline-block;
            margin-left: 6px;
            font-size: 14px;
            position: relative;
            top: -2px;
        }
    }
}

.refpay-btn-group {
    padding: 0px 10px 20px;

    .subscribed{
        
        .btn-rounded {
            background:  $refpay-clr;
            color: #fff;

            i{color: $refpay-secondary-clr;}
        }
    }

    .btn-rounded {
        color: $refpay-secondary-clr;
        border-radius: 22px;
        padding: 15px 20px;
        box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0), 0 16px 16px 0 rgba(0, 0, 0, 0.05);
        background: $white;
        width: 100%;
        font-size: 14px;
        position: relative;
        letter-spacing: 1.18px;
        text-transform: uppercase;

        i {
            color: $refpay-clr;
            display: inline-block;
            margin-right: 8px;
            font-size: 16px;
            float: left;
            padding-top: 2px;

            &.icon-transfer {
                font-size: 20px;
                position: absolute;
                left: 20px;
                top: 50%;
                transform: translateY(-50%);
            }
        }

       

        @media screen and(max-width: 360px) {
            font-size: 12px;
            letter-spacing: 1px;
            padding: 15px 14px;

            i {
                font-size: 12px;
                margin-right: 4px;

                &.icon-transfer {
                    font-size: 14px;
                    left: 16px;
                }

                &.icon-circle-tick {
                    font-size: 14px;
                }
            }
        }

        @media (max-width: 428px) {
            padding: 13px 14px;
            font-size: 12px;

            i {
                font-size: 12px;

                &.icon-transfer {
                    font-size: 16px;
                    left: 16px;
                }
            }
        }

    }

    .btn-wrapper {
        width: 50%;
        padding: 5px;
        display: inline-block;
    }
}

.transaction-label-header {
    color: $black;
    font-size: 14px;
    font-family: $font-bold;
    padding: 15px;
    background: $white;
    text-transform: capitalize;
    box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.1), 0 16px 16px 0 rgba(0, 0, 0, 0.05);
    z-index: 1;
    position: relative;
}

.recent-transaction-label {
    padding: 6px 15px;
    font-size: 11px;
    color: $gray-900;
    text-transform: uppercase;
    background: $gray-570;
    letter-spacing: 1px;
    font-family: $font-black;

    .bank-details {
        font-family: $font-italic;
        text-transform: none;
    }
}

.trasaction-record {
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0), 0 10px 16px 0 rgba(0, 0, 0, 0.04);

    ul {
        padding: 0;
        margin: 0;
        list-style-type: none;
        margin-bottom: 80px;

        li {
            border-bottom: 1px solid $gray-570;
            background: $white;

            .display-table-cell {
                padding: 15px 12px;
                vertical-align: middle;

                &:first-child {
                    text-align: center;
                    width: 10%;
                    padding-right: 0;
                }

                &:last-child {
                    width: 30%;
                    text-align: right;
                    padding-left: 0;
                }

                &:nth-child(2) {
                    width: 60%;
                }

                i {
                    font-size: 20px;

                    &.icon-circular-menu {
                        color: $yellow
                    }

                    &.icon-cross-circular {
                        color: $red;
                    }

                    &.icon-tick-circular {
                        color: $primary;
                    }
                }
            }

            &.view-all-transaction {
                a {
                    width: 100%;
                    padding: 15px;
                    text-align: center;
                    display: inline-block;
                    color: $refpay-clr;
                    text-transform: uppercase;
                    font-family: $font-black;
                    font-size: 12px;
                }

                border-bottom: none;
            }
        }
    }

    .transaction-label {
        color: $gray-900;
        font-size: 14px;
        font-family: $font-bold;
        margin-bottom: 5px;
    }

    .transaction-id {
        font-size: 12px;
        font-family: $font-italic;
        color: $gray-200;

        .dot-seperator {
            width: 4px;
            height: 4px;
            border-radius: 50%;
            background: $gray-900;
            display: inline-block;
            margin: 0 5px;
            position: relative;
            top: -2px;
        }
    }

    .transaction-amt {
        font-size: 16px;
        color: $refpay-clr;
        font-family: $font-bold;
        margin-bottom: 5px;

        @media screen and(max-width: 360px) {
            font-size: 14px;
        }

        .transaction-type {
            color: $gray-675;
            font-size: 12px;
            font-family: $font-black;
            text-transform: uppercase;
        }

        &.danger {
            color: $secondary;
        }
    }

    .bal-left {
        color: $secondary;
        font-size: 12px;
        font-family: $font-semibold;

        span {
            font-family: $font-regular;
            color: $gray-550;
        }
    }

    &.full-trasaction {
        box-shadow: none;

        ul {
            li {
                background: $white;
            }
        }
    }
}

.refpay-footer {
    background: $white;
    position: fixed;
    bottom: 0;
    padding: 15px;
    width: 100%;
    max-width: 768px;
    text-align: center;
    box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.1), 0 16px 16px 0 rgba(0, 0, 0, 0.05);

    i {
        font-size: 14px;

        &.icon-circular-menu {
            color: $yellow
        }

        &.icon-cross-circular {
            color: $red;
        }

        &.icon-tick-circular {
            color: $primary;
        }
    }

    span {
        font-size: 11px;
        font-family: $font-bold-italic;
        text-transform: capitalize;
        color: $gray-550;
        display: inline-block;
        margin-right: 20px;
        margin-left: 10px;

        &:last-child {
            margin-right: 0;
        }
    }
}
.transaction-reason{
    color: $gray-200;
    font-size: 12px;
    font-family: $font-bold;
    margin-bottom: 5px; 
    line-height: 1;
}

.ref-balance{
    i{font-size: 10px;}
}