.my-team-root {

    .create-team-button {

        margin: 0 auto;
        margin-top: 10px;
        margin-bottom: 10px;
        text-align: center;
        width: 90%;
        border-radius: 15px;
        background-color: $white;
        box-shadow: 0 16px 16px 0 rgba(0, 0, 0, 0.05);
        padding: 5px;
        cursor: pointer;

        span {
            color: $green;
            font-family: $font-regular;
            font-size: 12px;
            font-weight: 800;
            line-height: 16px;
            text-align: center;
            text-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.4);
        }
    }

    .my-team-list-item {
        list-style: none;
        padding: 0;
        border-bottom: 0px;
        border-radius: 14px;
        margin: 15px;

        .drawer-menu-item {
            &.collpased-list-hide {
                transition: all 5s ease-in-out;
                overflow: hidden;
                height: 0px;
            }

            &.collpased-list-show {
                transition: all 5s ease-in-out;
                height: auto;
            }
        }
    }

    .my-teams-item {
        width: 100%;
        border-radius: 14px;
        background-color: $white;
        box-shadow: 0 16px 16px 0 rgba(0, 0, 0, .05);
        border: 1px solid $white;
        position: relative;

        &.selected {
            box-shadow: 0 16px 16px 0 rgba(0, 160, 87, 0.4);
            border: 1px solid $green;
        }

        table {
            border: 0px;
            padding: 0px;
            margin: 0px;

            .captain-vice-captain {
                background-color: $gray-125;
                box-shadow: 0 16px 16px 0 rgba(0, 0, 0, 0.05);
            }

            tr {
                background-color: $white;

                &.captain-vice-captain {
                    td {
                        width: 50%;
                    }
                }

                td {
                    border-top: 0px;

                    // width: 50%;
                    .image-container {
                        display: inline-block;
                        width: 58px;
                        position: relative;

                        .player-image {
                            height: 40px;
                            width: 40px;
                            border-radius: 50%;
                        }

                        .player-post {
                            height: 16px;
                            width: 16px;
                            border-radius: 50%;
                            position: absolute;
                            bottom: 0;
                            right: 7px;


                        }
                    }

                    .player-name-container {
                        display: inline-block;
                        vertical-align: middle;
                        margin-left: 7px;

                        .player-name {
                            color: $gray-900;
                            font-family: $font-bold;
                            font-size: 14px;
                            letter-spacing: -0.12px;
                            line-height: 18px;
                        }

                        .team-vs-team {
                            color: $gray-550;
                            font-family: $font-bold;
                            font-size: 10px;
                            letter-spacing: -0.07px;
                            line-height: 13px;
                            margin-top: 4px;
                        }

                        @media screen and(max-width: 420px) {
                            .player-name {
                                font-size: 10px;
                            }

                            .team-vs-team {
                                font-size: 8px;
                            }
                        }
                    }

                }
            }

        }

        .bottom-row {
            width: 100%;
            background-color: $white;
            text-align: center;
            padding: 8px;
            vertical-align: middle;
            cursor: pointer;
            border-radius: 0 0 14px 14px;

            span {
                color: $green;
                font-family: $font-bold;
                font-size: 12px;
                line-height: 15px;
                text-align: center;
            }

            i {
                color: $gray-550;
                font-size: 12px;
                margin-left: 10px;
            }
        }

        .player-list-container {
            .player-list-item {
                .item-header {
                    padding: 5px;
                    text-align: center;
                    background-color: $gray-150;
                    box-shadow: 8px 8px 16px 0 rgba(0, 0, 0, 0.1);

                    color: $gray-550;
                    font-family: $font-bold;
                    font-size: 12px;
                    letter-spacing: -0.1px;
                    line-height: 15px;
                    text-align: center;

                    &~.item table tr td {
                        border-top: 0;
                    }
                }

                .item {

                    table {
                        border: 0px;
                        padding: 0px;
                        margin: 0px;

                        .captain-vice-captain {
                            background-color: $gray-125;
                            box-shadow: 0 16px 16px 0 rgba(0, 0, 0, 0.05);
                        }

                        tr {
                            background-color: $white;

                            td {
                                border-top: 1px solid $border-light;
                                padding: 4px 15px;

                                &.left {
                                    text-align: left;
                                    vertical-align: middle;
                                }

                                &.right {
                                    text-align: right;
                                    vertical-align: middle;
                                }

                                .image-container {
                                    display: inline-block;
                                    width: 58px;
                                    position: relative;

                                    .player-image {
                                        height: 40px;
                                        width: 40px;
                                        border-radius: 50%;
                                    }

                                    .player-post {
                                        height: 16px;
                                        width: 16px;
                                        border-radius: 50%;
                                        position: absolute;
                                        bottom: 0;
                                        right: 7px;
                                        color: $white;
                                        text-align: center;

                                        &.captain {
                                            background-color: $red;
                                        }

                                        &.vice-captain {
                                            background-color: $blue;
                                        }

                                    }
                                }

                                .credit-container {
                                    display: inline-block;
                                    vertical-align: middle;
                                    margin-left: 7px;

                                    .credit-amount {
                                        font-family: $font-regular;
                                        color: $gray-550;
                                        font-size: 14px;
                                        letter-spacing: -0.12px;
                                        line-height: 18px;
                                        text-align: right;
                                    }

                                    .credit-text {
                                        font-family: $font-bold;
                                        color: $gray-550;
                                        font-size: 10px;
                                        letter-spacing: -0.07px;
                                        line-height: 13px;
                                        text-align: right;
                                    }

                                    @media screen and(max-width: 420px) {
                                        .player-name {
                                            font-size: 10px;
                                        }

                                        .team-vs-team {
                                            font-size: 8px;
                                        }
                                    }
                                }

                            }
                        }

                    }
                }

                &:last-child {
                    border-bottom: 1px solid $border-light;
                }
            }
        }

        a.edit-team {
            position: absolute;
            top: 8px;
            right: 45px;
            font-size: 18px;
            color: $gray-900;
        }
    }

    .row-header {
        background-color: $white;
        justify-content: space-between;
        padding: 10px;
        margin: 5px;
        border-radius: 14px 14px 0 0;

        .name-container {
            float: left;

            .team-name {
                height: 18px;
                color: $gray-900;
                font-family: $font-bold-italic;
                font-size: 16px;
                line-height: 18px;
                margin-bottom: 4px;
            }

            .contests-joined {
                color: $gray-550;
                font-family: $font-bold-italic;
                font-size: 10px;
                line-height: 10px;
                padding-bottom: 5px;
            }
        }

        .select-team-checkbox {
            float: right;

            box-sizing: border-box;
            height: 20px;
            width: 20px;
            border-radius: 10px;
            border: 1px solid $border-light;
            cursor: pointer;

            &.selected {
                border: 5px solid $green;
            }
        }
    }

    .rectangle-11-copy-21 {
        height: 60px;
        width: 100%;
        background-color: rgb(160, 31, 31);
        box-shadow: 0 16px 16px 0 rgba(0, 0, 0, 0.05);
    }


    .playerImg {
        height: 54px;
        width: 51px;
        background-color: $green;
    }

    .inner-oval {
        height: 10px;
        width: 10px;
        border-radius: 5px;
        align-self: center;
        background-color: $white;
    }

    .bottom {
        width: 100%;
        background-color: $green;
        color: $white;
        font-family: $font-regular;
        font-size: 14px;
        font-weight: 800;
        line-height: 16px;
        text-align: center;
        text-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.4);
    }
}