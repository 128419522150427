.stats-section {
    padding: 20px 15px;
}

.stats-list {
    height: 60px;

    .listing-section {
        width: 60px;
        height: 60px;
        border-radius: 50%;
        padding: 15px;
        text-align: center;
        box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.2);
        position: absolute;
        left: 0;
        top: 0;

        i {
            font-size: 28px;
            color: $gray-650;
        }
    }

    background: $white;
    border-radius: 30px 0 0 30px;
    position: relative;
    padding-left: 60px;
    border-radius: 100px;
    margin-bottom: 20px;
    box-shadow: 0 16px 16px 0 rgba(0, 0, 0, 0.05);
}

.stats-data-section {
    display: table;
    width: 100%;

    .display-table-cell {
        position: relative;
        padding: 5px 10px;
        vertical-align: middle;

        &:first-child {
            padding-left: 60px;
            width: 80%;
        }

        &:last-child {
            width: 20%;
            text-align: center;
        }
    }

    img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        position: absolute;
        left: 10px;
        top: 50%;
        transform: translateY(-50%);
    }

    .pass-count {
        color: $secondary;
        font-size: 14px;
        font-family: $font-black;
    }

    .stats-msg {
        font-size: 14px;
        font-family: $font-bold-italic;
        color: $gray-900;
    }

    .stats-pts {
        font-size: 20px;
        font-family: $font-black;
        color: $secondary;
    }

    .stats-pts-label {
        font-size: 12px;
        font-family: $font-bold-italic;
        color: $gray-200;
    }
}

.award-section {
    padding: 15px;

    .award-header {
        border-radius: 20px 20px 0 0;
        width: 80%;
        margin: 0 auto;
        padding: 11px 15px;
        color: $secondary;
        font-size: 14px;
        text-align: center;
        font-family: $font-bold-italic;
        position: relative;

        &:before {
            content: '';
            position: absolute;
            width: 18px;
            height: 110%;
            background: $primary;
            left: -3.5px;
            top: 6px;
            -webkit-transform: rotate(18deg);
            transform: rotate(18deg);
            border-radius: 30px 30px 0 0;
        }

        &:after {
            content: '';
            position: absolute;
            width: 18px;
            height: 110%;
            background: $primary;
            right: -4.5px;
            top: 6px;
            -webkit-transform: rotate(-18deg);
            transform: rotate(-18deg);
            border-radius: 30px 30px 0 0;
        }
    }

    .award-card {
        background: $white;
        box-shadow: 0 16px 16px 0 rgba(0, 0, 0, 0.05);
        border-radius: 14px;
        height: 180px;
        position: relative;
        padding: 15px;

        .player-first-name {
            font-size: 20px;
            font-family: $font-black;
            text-transform: capitalize;
        }

        .player-last-name {
            font-size: 30px;
            font-family: $font-black;
            line-height: 30px;
            text-transform: capitalize;
        }

        .player-team-position {
            font-size: 12px;
            font-family: $font-italic;
            color: $gray-910;
            margin: 5px 0 10px;
        }

        .btn {
            border-radius: 22px;
            padding: 6px 15px;
            font-size: 14px;
        }

        .team-logo {
            position: absolute;
            top: 15px;
            left: 10px;
            width: 30px;
        }

        .player-img {
            width: 135px;
            display: inline-block;
            vertical-align: top;
        }

        .player-info-section {
            display: inline-block;
            vertical-align: top;
            padding: 10px;
        }
    }

    &:nth-child(3n+1) {
        .award-header {
            background: $primary;

            &:before,
            &:after {
                background: $primary;
            }
        }

        .award-card {
            .player-first-name {
                color: $primary;
            }

            .player-last-name {
                color: $secondary;
            }

            .btn {
                background: linear-gradient(73.19deg, $ternary 0%, $primary 100%);
            }
        }
    }

    &:nth-child(3n+2) {
        .award-header {
            background: $refsocial-clr;
            color: $white;

            &:before,
            &:after {
                background: $refsocial-clr;
            }
        }

        .award-card {
            .player-first-name {
                color: $refsocial-clr;
            }

            .player-last-name {
                color: $refsocial-secondary-clr;
            }

            .btn {
                background: linear-gradient(65.18deg, #37003C 0%, #FF005A 100%);
            }
        }
    }

    &:nth-child(3n+3) {
        .award-header {
            background: $refpay-clr;
            color: $white;

            &:before,
            &:after {
                background: $refpay-clr;
            }
        }

        .award-card {
            .player-first-name {
                color: $refpay-clr;
            }

            .player-last-name {
                color: $refpay-secondary-clr;
            }

            .btn {
                background: linear-gradient(73.19deg, $refpay-secondary-clr 0%, $refpay-clr 100%);
            }
        }
    }
}

.awards-filter-section {
    border-bottom: 1px solid $border-light;

    .award-filter-border {
        border-right: 1px solid $border-light;
    }

    .select-secondary {
        padding: 0;
    }

    .select-league {
        padding: 7px 20px 0 20px;
    }

    .select-arr {
        right: 10px;
    }
}

.pass-detail {
    width: 100%;

    thead {
        background: $secondary;

        th {
            padding: 7px 15px;
            color: $white;
            font-size: 12px;
            font-family: $font-black;
            text-transform: capitalize;
        }
    }

    td {
        padding: 15px;
        vertical-align: middle;
        border-bottom: 1px solid $border-light;

        img {
            width: 40px;
            height: 40px;
            border-radius: 50%;
        }

        &.rank {
            color: $secondary;
            font-size: 14px;
            font-family: $font-black;
            width: 15%;
        }

        &.points {
            width: 15%;
            font-size: 20px;
            font-family: $font-black;
            color: $secondary;
        }

        &.user-detail {
            width: 70%;
            font-size: 14px;
            font-family: $font-bold;
            color: $gray-900;

            span {
                display: inline-block;
                margin-left: 10px;
            }
        }
    }
}