.refmail-list {
    ul {
        padding: 0;
        margin: 0;
        list-style-type: none;

        li {
            border-bottom: 1px solid $border-light;
            background: $white;

            .display-table-cell {
                vertical-align: top;
                padding: 15px 15px 15px 0;

                img {
                    width: 45px;
                    height: 45px;
                    border-radius: 50%;
                }

                &:first-child {
                    padding-left: 15px;
                    width: 10%;
                }

                &:last-child {
                    width: 10%;
                    text-align: right;
                }

                &:nth-child(2) {
                    width: 80%;
                }

                @media (max-width: 767px) {
                    &:last-child {
                        width: 20%;
                    }

                    &:nth-child(2) {
                        width: 70%;
                    }
                }
            }

            &.new-message {
                background: $highlight-clr;
            }
        }
    }

    .sender-name {
        font-size: 14px;
        font-family: $font-bold;
        color: $gray-900;
        margin-bottom: 3px;
    }

    .message {
        font-size: 12px;
        font-family: $font-regular;
        color: $gray-675;

        span {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
        }
    }

    .incoming-time {
        color: $gray-675;
        font-size: 12px;
        font-family: $font-regular;
    }

    .unread-mark {
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background: $primary;
        display: inline-block;
        margin: 0 auto;
    }
}