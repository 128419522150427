.add-funds-tab {
    .tab-navigation {
        li {
            padding: 0;

            a {
                background: $white;
                padding: 15px;
                color: $gray-675;
                letter-spacing: 0.92px;
                border-radius: 0;
                font-size: 11px;
                border-bottom: 2px solid $border-light;
            }

            &.active {
                a {
                    color: $secondary !important;
                    border-color: $refpay-clr;
                }
            }
        }
    }
}

.disclaimer-txt {
    font-size: 12px;
    font-family: $font-italic;
    color: $gray-550;
    max-width: 350px;
    margin: 0 auto;
}

.withdraw-form-section {
    padding: 20px 5px;
    background: $white;

    @media (max-width: 767px) {
        padding-bottom: 80px;
    }
}

.py-st-tab {
    display: flex;
    width: 100%;
    ul {
        width: 100%;
    }
}

@media (max-width: 480px) {
    .checkout__container__methods {
        height: 100%;
    }
}

.currency-grp {
    .rkfloatingLabelInput {
        span,
        input {
            padding-left: 80px;
            padding-right: 80px;
        }
    }

    .lab {
        color: #9e9e9e;
        font-size: 12px;
    }

    .shwn-inp {
        border: none;
        border-bottom: none !important;
        text-align: center;
    }

    .currency_coversion_ar{
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .phone_code_sel {
        margin-top: 0px;
        // position: absolute;
        background: #ebebeb;
        width: 90px;
        border-radius: 30px;
        margin-right: 15px;
        position: relative;
        .select-arr {
            position: absolute;
            top: 6px !important;
            right: -13px !important;
            -webkit-transform: translateX(-35%);
            transform: translateX(-35%);
            color: #b4b4b4;
            width: 30px !important;
        }

        .select-secondary {
            padding-bottom: 0;
        }

        .secondary__control {
            .secondary__input{padding-left: 10px;}
            
        }
        .secondary__menu .secondary__menu-list > div{
            font-size: 12px !important;
        }
        .secondary__value-container {
            padding: 2px 0 !important;
        }

        .secondary__single-value {
            width: 100%;
            text-align: center;
        }

       
    }

    .c-grp{
        label, input{
            padding-left: 90px;
            padding-right: 90px;
        }
    }
}
