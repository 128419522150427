.hidden-lg {
    @media (min-width: 1200px) {
        display: none;
    }
}

.hidden-md {
    @media (min-width: 992px) and (max-width: 1999px) {
        display: none;
    }
}

.hidden-sm {
    @media (min-width: 768px) and (max-width: 991px) {
        display: none;
    }
}

.hidden-xs {
    @media (max-width: 767px) {
        display: none;
    }
}

.visible-lg {
    display: none;

    @media (min-width: 1200px) {
        display: block;
    }
}

.visible-md {
    display: none;

    @media (min-width: 992px) and (max-width: 1999px) {
        display: block;
    }
}

.visible-sm {
    display: none;

    @media (min-width: 768px) and (max-width: 991px) {
        display: block;
    }
}

.visible-xs {
    display: none;

    @media (min-width: 767px) {
        display: block;
    }
}