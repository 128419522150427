.header-wrap {
    background-color: $primary;
    padding: 8px 15px;
    position: relative;
    // border-bottom: 2px solid $white;
    z-index: 1005;
    position: fixed;
    top: 0;
    width: 100%;
    max-width: 768px;

    &.header-shadow {
        box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.15);
    }

    .header-content {
        text-align: center;

        div {
            display: inline-block;
            vertical-align: middle;
        }

        .team-img-block {
            width: 34px;
            height: 34px;
            border-radius: 50%;
            overflow: hidden;

            img {
                max-width: 100%;
                max-height: 100%;
            }
        }
    }

    .header-action {
        font-size: 20px;
        color: $secondary;
        width: 25px;
        height: 40px;
        position: relative;
        cursor: pointer;
        display: inline-block;

        &:not(:first-child) {
            margin-right: 15px;
        }

        i {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-size: 17px;

            &.icon-bell {
                font-size: 20px;
            }

            &.icon-edit-line {
                font-size: 20px;
            }

            &.icon-left-arrow {
                font-size: 20px;
            }

            &.icon-message1 {
                font-size: 14px;
            }
        }

        &.f-sm {
            font-size: 16px;
        }

        &.f-xs {
            font-size: 12px;
        }

        &.right {
            float: right;
        }

        .live-indicator {
            height: 6px;
            width: 6px;

            &.live {
                background-color: $red;
            }

            &.completed {
                background-color: $green;
            }
        }

        .status-text {
            font-family: $font-black;
            font-size: 10px;
            font-style: italic;
            font-weight: bold;
            line-height: 13px;
            text-align: center;
            margin-left: 5px;

            &.live {
                color: $red;
            }

            &.completed {
                color: $green;

                @media screen and(max-width: 340px) {
                    font-size: 8px;
                }
            }
        }

        &.status {
            width: max-content;
            cursor: default;
        }

        &.header-action-inline-block {
            display: inline-block;

            &:nth-child(2),
            &:nth-child(3) {
                margin-right: 10px;
            }

            @media (max-width: 320px) {
                width: 20px;
            }
        }

        &.brand-logo {
            display: inline-block;
            vertical-align: middle;
            width: 100%;
            padding: 5px 0;
            color: $primary;
            i {
                font-size: 50px;
                color: $secondary;
            }
            img {
                width: 44px;
            }
        }
    }

    .team-header-detail {
        margin: 0 10px;

        .team-header-content {
            color: $primary;
            font-family: $font-black;
            font-size: 13px;

            small {
                font-size: 12px;
            }
        }

        p {
            margin: 0;
            font-size: 9px;
            font-family: $font-bold-italic;
            color: $gray-650;
        }
    }

    .toggle-ic {
        margin-right: 10px;
        display: inline-block;
        cursor: pointer;
        img {
            height: 24px;
            width: 24px;
            border-radius: 6px;
            // border: solid 1px #69ff85;
        }
    }

    .left-cl {
        display: flex;
        height: 100%;
        align-items: center;
    }

    .act-toggle {
        cursor: pointer;
        font-size: 25px;
        line-height: 0;
        color: $secondary;
    }
    .act-box-wrap {
        display: none;
        &.open {
            display: block;
        }
        .overlay {
            position: fixed;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            background: rgba(0, 0, 0, 0.05);
        }
    }
    .act-box {
        width: 190px;
        min-height: 150px;
        background: #fff;
        box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.15);
        position: absolute;
        top: 50px;
        left: 10px;
        // padding: 10px 7px;
        border-radius: 10px;
        &:before {
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0 10px 10px 10px;
            border-color: transparent transparent #fff transparent;
            content: "";
            position: absolute;
            top: -10px;
            left: 25px;
        }
        @media (max-width: 767px) {
            // height: 250px;
            // width: 280px;
        }

        .men-lnk-wrp {
            user-select: none;
            .men-lnk {
                text-align: center;
                cursor: pointer;
                img {
                    height: 24px;
                    margin-bottom: 5px;
                }
                padding: 15px;
                &:hover {
                    background: linear-gradient(270deg, #fff 0%, #f1f1f1 100%);
                    border-radius: 10px;
                }
                span {
                    display: block;
                    color: #000;
                    font-size: 12px;
                    font-weight: 600;
                }
            }

            a.active {
                .men-lnk {
                    background: linear-gradient(270deg, #fff 0%, #f1f1f1 100%);
                    border-radius: 10px;
                    span {
                        font-weight: 800;
                        font-family: "MuliBlack";
                    }
                }
            }
        }
    }
}

.header-title {
    font-size: 13px;
    margin: 12px 0;
    color: $secondary;
    font-family: $font-black;
    text-transform: uppercase;
}

.page-inner-header {
    padding: 15px 10px 0;
    background: #D7D7D7;
    box-shadow: 0px 9px 16px 0 rgba(0, 0, 0, 0.05);
    position: relative;
    overflow: hidden;
    height: 103px;

    &.refpay-header {
        z-index: 9;
    }

    h2 {
        padding-left: 5px;
        padding-bottom: 15px;
        margin: 0;
        font-size: 24px;
        font-family: $font-xtrabold;
        color: $refsocial-secondary-clr;
        position: relative;
        z-index: 2;
    }

    .header-bg {
        position: absolute;
        right: 0;
        top: 0;
    }

    .tab-navigation {
        position: relative;
    }

    &.page-inner-header-sm {
        padding: 25px 15px;
        height: 78px;

        h2 {
            padding-bottom: 0;
        }
    }

    &.single-title-header {
        padding: 25px 0;
        height: 78px;

        h2 {
            padding-bottom: 0;
            padding-left: 13px;
        }
    }

    &.single-title-header-lg {
        padding: 16px 0;

        h2 {
            padding-bottom: 0;
            padding-left: 13px;
            max-width: 65%;
        }
    }

    &.page-inner-header-xlg {
        padding: 15px 2px 0;

        h2 {
            padding-left: 13px;
        }
    }
}

.notification-circle {
    text-align: center;
    font-size: 9px;
    font-family: $font-bold;
    position: absolute;
    top: -5px;
    right: -5px;
    padding: 4px 2px;
    height: 19px;
    width: 19px;
    border-radius: 100%;
    background-color: $red;
    color: $white;
}

.refsocial-web-container {
    .header-wrap,
    .page-inner-header {
        background: $reflive-clr;
    }

    .page-inner-header {
        padding: 15px 2px 0;

        h2 {
            padding-left: 13px;
            color: $refsocial-secondary-clr;
        }

        &.single-title-header {
            padding: 25px 0;

            h2 {
                padding-bottom: 0;
            }
        }
    }

    .notification-circle {
        color: $red;
        background-color: $white;
    }
}

.refpay-web-container {
    .header-wrap,
    .page-inner-header {
        // background: $refpay-clr;
    }

    .page-inner-header {
        padding: 15px 2px 0;

        h2 {
            padding-left: 13px;
            color: $refpay-secondary-clr;
        }

        &.single-title-header {
            padding: 25px 0;

            h2 {
                padding-bottom: 0;
            }
        }

        &.page-inner-header-lg {
            height: 128px;
        }
    }
}

.achivements-web-container {
    .page-inner-header.page-inner-header-lg {
        height: 128px;
    }
}

.refer-friend {
    .page-inner-header.page-inner-header-sm {
        height: 104px;

        @media (max-width: 428px) {
            height: 128px;
        }

        @media (max-width: 400px) {
            height: 110px;
        }
    }
}

.create-contest {
    .page-inner-header.single-title-header-lg {
        height: 60px;

        @media (max-width: 428px) {
            height: 88px;
        }
    }
}

.navigation-bar {
    position: fixed;
    bottom: 0;
    height: 50px;
    overflow: hidden;
    background: #fff;
    width: 100%;
    max-width: 768px;
    z-index: 1000;
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.15);
    .row {
        align-items: center;
        height: 100%;
    }
    .men-lnk {
        text-align: center;
        padding: 5px;
        img {
            height: 20px;
        }
        i {
            font-size: 20px;
            color: #00af8b;
            width: 20px;
            height: 20px;
            display: inline-block;
            transition: all ease 0.5s;
            background: -webkit-linear-gradient($primary, #00af8b);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }
        span {
            display: block;
            font-size: 12px;
            color: #000;
        }
    }

    a:hover {
        .men-lnk {
            i {
                animation-name: ics_anim;
                animation-duration: 2s;
            }
        }
    }

    a.active {
        .men-lnk {
            background: linear-gradient(270deg, #fff 0%, #f1f1f1 100%);
            i {
                color: #00af8b;
            }
            span {
                font-weight: bold;
            }
        }
    }
}

@keyframes ics_anim {
    0% {
        margin-top: -20px;
        opacity: 0;
    }
    50% {
        margin-top: 0;
        opacity: 1;
    }
}
