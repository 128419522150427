.input-suggestion {
    font-size: 12px;
    color: $gray-675;
    font-family: $font-italic;
    position: relative;
    top: -15px;
    text-align: center;
}

.form-input-center {
    label {
        color: $gray-675;
        font-size: 14px;
    }

    .form-control {
        font-size: 20px;
        border: 0;
        border-bottom: 1px solid;
        border-radius: 0;
        color: $gray-900;
        text-align: center;
    }
}

.input-label-left {
    &.has-error {
        input {
            border-color: theme-color("danger") !important;
        }

        .select-field-transparent {
            .select__control {
                border-color: theme-color("danger") !important;
            }
        }
    }
}

.input-label-center {

    label {
        text-align: center !important;
    }

    .secondary__control {
        width: 100% !important;
    }

    .secondary__placeholder {
        left: 0;
        right: 0;
        font-size: 16px;
        font-family: "MuliBold";
        text-align: center;
    }
}

.bordered-color {
    .secondary__control {
        border-bottom: 1px solid $border-light !important;
    }
}