.user-info {
    padding: 25px 15px 25px 80px;
    position: relative;

    img {
        position: absolute;
        left: 15px;
        border-radius: 50%;
        width: 47px;
        height: 47px;
        top: 15px;
    }

    .user-name {
        font-size: 16px;
        font-family: $font-black;
        color: $gray-900;
    }
}

.create-post-section {
    height: calc(100vh - 60px);
    position: relative;
    padding-bottom: 70px;
}

.create-post-footer {
    border-top: 1px solid $border-light;
    position: absolute;
    bottom: 0;
    width: 100%;
    background: $white;
    padding: 15px;

    i {
        display: inline-block;
        color: $gray-550;
        font-size: 18px;
        margin: 13px 10px;
    }

    a.btn.btn-rounded {
        background: linear-gradient(75.01deg, #ACACAC 0%, #E9E9E9 100%);
        padding: 13px 30px;
        color: $white;
        font-size: 14px;
        // opacity: 0.4;
        float: right;
        border-radius: 22px;
    }
}

.create-post-body {
    textarea.form-control {
        border: 0;
        color: $gray-900;
        font-family: $font-regular;
        font-size: 20px;
    }

    ::-webkit-input-placeholder {
        color: $gray-550;
        font-family: $font-italic;
    }

    ::-moz-placeholder {
        /* Firefox 19+ */
        color: $gray-550;
        font-family: $font-italic;
    }

    :-ms-input-placeholder {
        /* IE 10+ */
        color: $gray-550;
        font-family: $font-italic;
    }

    :-moz-placeholder {
        /* Firefox 18- */
        color: $gray-550;
        font-family: $font-italic;
    }
}