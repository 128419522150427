.filter-container {
    .slide-pane_from_bottom.ReactModal__Content--after-open {
        max-width: 768px;
    }
}

.slide-pane__header {
    display: none !important;
}

.slide-pane_from_bottom.slide-pane_from_bottom.ReactModal__Content--after-open {
    margin-top: 0;
    height: auto;
    bottom: 0;
    position: absolute;
    background-color: $gray-150;
    -webkit-transform: translateY(0%);
    transform: translateY(0%);
}

.filter-custom-overlay {
    background-color: rgba(222, 222, 222, 0.9);
}

.slide-pane__content {
    padding: 0;
    overflow-y: hidden;

    .filter-header {
        background: $white;
        padding: 15px;
        color: $gray-650;
        font-size: 12px;
        font-family: $font-bold;
        text-align: center;
        box-shadow: 0 -8px 20px 0 rgba(0, 0, 0, 0.2);
        position: relative;
        width: 100%;

        i {
            position: absolute;
            left: 15px;
            display: inline-block;
            top: 15px;
            font-size: 16px;

            &.active {
                color: $primary;
            }
        }

        .btn {
            position: absolute;
            right: 15px;
            top: 15px;
            background: transparent;
            padding: 0;
            color: $gray-650;

            &.active {
                color: $primary;
            }
        }
    }

    .filter-body {
        height: 100%;
        max-height: 250px;
        overflow-y: auto;

        ul {
            list-style-type: none;
            margin: 0;
            padding: 0;

            li {
                border-bottom: 1px solid $gray-500;
                padding: 10px 20px;
                color: $black;
                font-size: 14px;
                font-family: $font-regular;

                &.active {
                    color: $primary;
                }

                .form-group {
                    margin: 0;
                }

                label {
                    margin: 0;
                    width: 100%;
                }

                .custom-checkbox {

                    input[type="checkbox"],
                    input[type="radio"] {
                        position: absolute; // take it out of document flow
                        opacity: 0; // hide it

                        &~span {
                            position: relative;
                            cursor: pointer;
                            padding: 0;
                            display: block;
                            width: 100%;
                            padding-right: 30px;
                            max-width: 100%;
                            overflow: hidden;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                        }

                        // Box.
                        &~span:before {
                            content: '';
                            margin-right: 10px;
                            display: inline-block;
                            vertical-align: text-top;
                            width: 20px;
                            height: 20px;
                            background: white;
                            border-radius: 50px;
                            border: 1px solid #D1D1D1;
                            margin: 0;
                            position: absolute;
                            right: 0;
                        }

                        // Box checked
                        &:checked~span {
                            color: $primary;

                            &:before {
                                background: $green;
                                border: none;
                            }
                        }

                        // Disabled state label.
                        &:disabled~span {
                            color: #b8b8b8;
                            cursor: auto;
                        }

                        // Disabled box.
                        &:disabled~span:before {
                            box-shadow: none;
                            background: #ddd;
                        }

                        // Checkmark. Could be replaced with an image
                        &:checked~span:after {
                            content: '';
                            position: absolute;
                            right: 13px;
                            top: 9px;
                            background: white;
                            width: 2px;
                            height: 2px;
                            box-shadow:
                                2px 0 0 white,
                                4px 0 0 white,
                                4px -2px 0 white,
                                4px -4px 0 white,
                                4px -6px 0 white,
                                4px -8px 0 white;
                            transform: rotate(45deg);
                        }
                    }
                }
            }
        }
    }
}

.slide-pane__overlay.ReactModal__Overlay--after-open {
    z-index: 9;
    margin: 0;
}

.filter-body {
    .custom-nav-tabs {
        &.tabs-three {
            .nav-tabs {
                li {
                    padding: 0;
                }
            }
        }
    }
}

.custom-nav-tabs.tabs-three.contest-filter-tab {
    .tab-content {
        padding: 0;
        background: $white;

        .slider-header {
            max-width: 500px;
            margin: 0 auto;
        }
    }
}