.select-secondary{
    display:block;
    z-index: 1;
    padding-bottom: 8px;
}
.select-club{
    display:inline-block;
    vertical-align: middle;
    width: calc(100% - 50px);
    -webkit-width: calc(100% - 50px);
    -moz-width: calc(100% - 50px);
    position: relative;
}
.secondary__control{
    border-color:transparent !important;
    background-color: transparent !important;
    box-shadow: none !important;
    min-height: 30px !important;
    border-width: 0 !important;
    position: relative;
    z-index: 9;
}
.secondary__indicators{
    display: none !important;
}
.select-arr{
    position: absolute;
    right: 0;
    top: 35%;
    transform: translateX(-35%);
    color: #B4B4B4;
    width: 30px !important;
}
.secondary__value-container {
    padding: 2px 8px !important;
    z-index: 99;

}
.secondary__single-value{
    font-family: $font-semibold;
 //   color:$btn-default-color !important;
}
.primary__indicator-separator{
    background-color: transparent !important;
}
.primary__control{
    border-width: 0 !important;
    background-color: transparent !important;
    border-color: transparent !important;
    box-shadow: none !important;
}
.secondary__control--is-focused{
    z-index: 999;
    position: relative;
}
.Select-menu-outer {
    z-index: 9999;
  }
  .Select.is-open { position: relative; z-index: 1000; }
  
  .select-secondary-select__menu,
  .secondary__menu {
      z-index: 9999 !important;
  }
  .select-state-field{
      .select-secondary {
          .secondary__menu,
          .secondary__menu-list {
              height: 80px !important;
          }
      }
  }
  .select-country-field{      
      .select__menu,
      .select__menu-list {
          height: 150px !important;
      }
  }
.streetaddress-input-field{
    label{
        span{
            z-index: initial !important;
        }
    }
}
.secondary--is-disabled{
    opacity: 0.6;
}
.secondary__control--is-disabled{
    opacity: 0.6;
}
.select__menu-list {
    .select__option {
        max-width: 100% !important;
        // overflow: hidden !important;
        // text-overflow: ellipsis !important;
        // white-space: nowrap !important;
    }
}

.secondary__menu, .select__menu{
    margin-top: 0 !important;
}
.sportStyle .secondary__menu{
    width: 110% !important;
}
.secondary--lable--is-disabled{
    opacity: 0.6;
}