.loader-back {
    background: rgba(0, 0, 0, 0.2);
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 999999;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    &:after {
        content: '';
        display: none; //block;
        width: 100px;
        height: 100px;
        background-color: rgba(0, 0, 0, 0.8);
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        position: absolute;

    }
}

.lds-ellipsis {
    display: inline-block;
    position: relative;
    width: 64px;
    height: 64px;
    left: 50%;
    top: 50%;
    margin-left: -35px;
    margin-top: -28px;
    z-index: 999;
}

.lds-ellipsis div {
    position: absolute;
    top: 27px;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
    background: -webkit-linear-gradient(317.7deg, #00AF8B 0%, #00FF87 100%);
    background: linear-gradient(132.3deg, #00AF8B 0%, #00FF87);
}

.lds-ellipsis div:nth-child(1) {
    left: 0px;
    animation: lds-ellipsis1 0.6s infinite;
}

.lds-ellipsis div:nth-child(2) {
    left: 0px;
    animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(3) {
    left: 18px;
    animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(4) {
    left: 36px;
    animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(5) {
    left: 58px;
    animation: lds-ellipsis3 0.6s infinite;
}

@keyframes lds-ellipsis1 {
    0% {
        transform: scale(0);
    }

    100% {
        transform: scale(1);
    }
}

@keyframes lds-ellipsis4 {
    0% {
        transform: scale(1);
    }

    100% {
        transform: scale(0);
    }
}

@keyframes lds-ellipsis3 {
    0% {
        transform: scale(1);
    }

    100% {
        transform: scale(0);
    }
}

@keyframes lds-ellipsis2 {
    0% {
        transform: translate(0, 0);
    }

    100% {
        transform: translate(19px, 0);
    }
}

.animated {
    -webkit-animation-duration: 24s;
    animation-duration: 24s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -moz-user-select: none;
    -ms-user-select: none;
    -webkit-user-select: none;
}

.yt-loader {
    -webkit-animation-name: horizontalProgressBar;
    animation-name: horizontalProgressBar;
    -webkit-animation-timing-function: ease;
    animation-timing-function: ease;
    background: red;
    height: 3px;
    left: 0;
    position: absolute;
    ;
    top: -9px;
    width: 0%;
    z-index: 9999;
}

@keyframes horizontalProgressBar {
    0% {
        width: 0%;
    }

    25% {
        width: 22%;
    }

    50% {
        width: 55%;
    }

    75% {
        width: 83%;
    }

    100% {
        width: 100%;
    }
}

@-webkit-keyframes horizontalProgressBar

/* Safari and Chrome */
    {
    0% {
        width: 0%;
    }

    25% {
        width: 22%;
    }

    50% {
        width: 55%;
    }

    75% {
        width: 83%;
    }

    100% {
        width: 100%;
    }
}