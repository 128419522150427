.progress {
    height: 4px;
    background: $gray-1000;
    margin-top: 10px;
    border-radius: 3px;
    max-width: 65%;

    &.danger {
        .progress-bar {
            background: $red;
        }
    }
}

.progress-bar {
    border-radius: 3px;

    &.danger {
        background: $red;
    }
}

.progressbar-entries {
    color: $gray-550;
    font-size: 12px;
    font-family: $font-bold-italic;
    margin-top: 6px;

    &.danger {
        span {
            color: $red;
        }
    }

    span {
        color: $primary;
    }
}