
@font-face {
     font-family: "MuliBlack";
     src: url("../fonts/MuliBlack.woff2") format("woff2"),
        url("../fonts/MuliBlack.woff") format("woff"),
        url("../fonts/MuliBlack.eot") format("embedded-opentype"),
        url("../fonts/MuliBlack.ttf") format("truetype"),
        url('../fonts/MuliBlack.eot?#iefix') format('embedded-opentype'),
        url("../fonts/MuliBlack.svg#MuliBlack") format("svg");
      font-weight: normal;
}

@font-face {
    font-family: "MuliBold";
    src: url("../fonts/MuliBold.woff2") format("woff2"),
    url("../fonts/MuliBold.woff") format("woff"),
    url("../fonts/MuliBold.eot") format("embedded-opentype"),
    url("../fonts/MuliBold.ttf") format("truetype"),
    url('../fonts/MuliBold.eot?#iefix') format('embedded-opentype'),
    url("../fonts/MuliBold.svg#MuliBold") format("svg");
    font-weight: normal;
}

@font-face {
    font-family: "Muli-BoldItalic";
    src: url("../fonts/Muli-BoldItalic.woff2") format("woff2"),
    url("../fonts/Muli-BoldItalic.woff") format("woff"),
    url("../fonts/Muli-BoldItalic.eot") format("embedded-opentype"),
    url("../fonts/Muli-BoldItalic.ttf") format("truetype"),
    url('../fonts/Muli-BoldItalic.eot?#iefix') format('embedded-opentype'),
    url("../fonts/Muli-BoldItalic.svg#Muli-BoldItalic") format("svg");
    font-weight: normal;
}

@font-face {
    font-family: "MuliItalic";
    src: url("../fonts/MuliItalic.woff2") format("woff2"),
    url("../fonts/MuliItalic.woff") format("woff"),
    url("../fonts/MuliItalic.eot") format("embedded-opentype"),
    url("../fonts/MuliItalic.ttf") format("truetype"),
    url('../fonts/MuliItalic.eot?#iefix') format('embedded-opentype'),
    url("../fonts/MuliItalic.svg#MuliItalic") format("svg");
    font-weight: normal;
}

@font-face {
    font-family: "Muli-LightItalic";
    src : url("../fonts/Muli-LightItalic.woff") format("woff"),
    url("../fonts/Muli-LightItalic.eot") format("embedded-opentype"),
    url("../fonts/Muli-LightItalic.ttf") format("truetype"),
    url('../fonts/Muli-LightItalic.eot?#iefix') format('embedded-opentype'),
    url("../fonts/Muli-LightItalic.svg#Muli-LightItalic") format("svg");
    font-weight: normal; 
}
@font-face {
    font-family: "MuliRegular";
    src: url("../fonts/MuliRegular.woff") format("woff"),
    url("../fonts/MuliRegular.eot") format("embedded-opentype"),
    url("../fonts/MuliRegular.ttf") format("truetype"),
    url('../fonts/MuliRegular.eot?#iefix') format('embedded-opentype'),
    url("../fonts/MuliRegular.svg#MuliRegular") format("svg");
    font-weight: normal; 
}
@font-face {
    font-family: "MuliSemiBold";
    src: url("../fonts/MuliSemiBold.woff") format("woff"),
    url("../fonts/MuliSemiBold.eot") format("embedded-opentype"),
    url("../fonts/MuliSemiBold.ttf") format("truetype"),
    url('../fonts/MuliSemiBold.eot?#iefix') format('embedded-opentype'),
    url("../fonts/MuliSemiBold.svg#MuliSemiBold") format("svg");
    font-weight: normal; 
}
@font-face {
    font-family: "Muli-BlackItalic";
    src: url("../fonts/Muli-BlackItalic.woff") format("woff"),
    url("../fonts/Muli-BlackItalic.eot") format("embedded-opentype"),
    url("../fonts/Muli-BlackItalic.ttf") format("truetype"),
    url('../fonts/Muli-BlackItalic.eot?#iefix') format('embedded-opentype'),
    url("../fonts/Muli-BlackItalic.svg#Muli-BlackItalic") format("svg");
    font-weight: normal; 
}
@font-face {
    font-family: "Muli-ExtraBold";
    src: url("../fonts/Muli-ExtraBold.woff") format("woff"),
    url("../fonts/Muli-ExtraBold.eot") format("embedded-opentype"),
    url("../fonts/Muli-ExtraBold.ttf") format("truetype"),
    url('../fonts/Muli-ExtraBold.eot?#iefix') format('embedded-opentype'),
    url("../fonts/Muli-ExtraBold.svg#Muli-ExtraBold") format("svg");
    font-weight: normal; 
}
@font-face {
    font-family: "Muli-SemiBoldItalic";
    src: url("../fonts/Muli-SemiBoldItalic.woff") format("woff"),
    url("../fonts/Muli-SemiBoldItalic.eot") format("embedded-opentype"),
    url("../fonts/Muli-SemiBoldItalic.ttf") format("truetype"),
    url('../fonts/Muli-SemiBoldItalic.eot?#iefix') format('embedded-opentype'),
    url("../fonts/Muli-SemiBoldItalic.svg#Muli-SemiBoldItalic") format("svg");
    font-weight: normal; 
}
@font-face {
    font-family: "Muli-ExtraBoldItalic";
    src: url("../fonts/Muli-ExtraBoldItalic.woff") format("woff"),
    url("../fonts/Muli-ExtraBoldItalic.eot") format("embedded-opentype"),
    url("../fonts/Muli-ExtraBoldItalic.ttf") format("truetype"),
    url('../fonts/Muli-ExtraBoldItalic.eot?#iefix') format('embedded-opentype'),
    url("../fonts/Muli-ExtraBoldItalic.svg#Muli-ExtraBoldItalic") format("svg");
    font-weight: normal; 
}

/* Saira condensed fonts */

@font-face {
    font-family: "Sairacondensed-black";
    src: url("../fonts/sairacondensed-black.woff") format("woff"),
        url("../fonts/sairacondensed-black.eot") format("embedded-opentype"),
        url("../fonts/sairacondensed-black.ttf") format("truetype"),
        url('../fonts/sairacondensed-black.eot?#iefix') format('embedded-opentype'),
        url("../fonts/sairacondensed-black.svg#sairacondensed-black") format("svg");
    font-weight: normal;
}
@font-face {
    font-family: "sairacondensed-bold";
    src: url("../fonts/sairacondensed-bold.woff") format("woff"),
        url("../fonts/sairacondensed-bold.eot") format("embedded-opentype"),
        url("../fonts/sairacondensed-bold.ttf") format("truetype"),
        url('../fonts/sairacondensed-bold.eot?#iefix') format('embedded-opentype'),
        url("../fonts/sairacondensed-bold.svg#sairacondensed-bold") format("svg");
    font-weight: normal;
}
@font-face {
    font-family: "sairacondensed-extrabold";
    src: url("../fonts/sairacondensed-extrabold.woff") format("woff"),
        url("../fonts/sairacondensed-extrabold.eot") format("embedded-opentype"),
        url("../fonts/sairacondensed-extrabold.ttf") format("truetype"),
        url('../fonts/sairacondensed-extrabold.eot?#iefix') format('embedded-opentype'),
        url("../fonts/sairacondensed-extrabold.svg#sairacondensed-extrabold") format("svg");
    font-weight: normal;
}
@font-face {
    font-family: "sairacondensed-extralight";
    src: url("../fonts/sairacondensed-extralight.woff") format("woff"),
        url("../fonts/sairacondensed-extralight.eot") format("embedded-opentype"),
        url("../fonts/sairacondensed-extralight.ttf") format("truetype"),
        url('../fonts/sairacondensed-extralight.eot?#iefix') format('embedded-opentype'),
        url("../fonts/sairacondensed-extralight.svg#sairacondensed-extralight") format("svg");
    font-weight: normal;
}
@font-face {
    font-family: "sairacondensed-light";
    src: url("../fonts/sairacondensed-light.woff") format("woff"),
        url("../fonts/sairacondensed-light.eot") format("embedded-opentype"),
        url("../fonts/sairacondensed-light.ttf") format("truetype"),
        url('../fonts/sairacondensed-light.eot?#iefix') format('embedded-opentype'),
        url("../fonts/sairacondensed-light.svg#sairacondensed-light") format("svg");
    font-weight: normal;
}
@font-face {
    font-family: "sairacondensed-medium";
    src: url("../fonts/sairacondensed-medium.woff") format("woff"),
        url("../fonts/sairacondensed-medium.eot") format("embedded-opentype"),
        url("../fonts/sairacondensed-medium.ttf") format("truetype"),
        url('../fonts/sairacondensed-medium.eot?#iefix') format('embedded-opentype'),
        url("../fonts/sairacondensed-medium.svg#sairacondensed-medium") format("svg");
    font-weight: normal;
}
@font-face {
    font-family: "sairacondensed-regular";
    src: url("../fonts/sairacondensed-regular.woff") format("woff"),
        url("../fonts/sairacondensed-regular.eot") format("embedded-opentype"),
        url("../fonts/sairacondensed-regular.ttf") format("truetype"),
        url('../fonts/sairacondensed-regular.eot?#iefix') format('embedded-opentype'),
        url("../fonts/sairacondensed-regular.svg#sairacondensed-regular") format("svg");
    font-weight: normal;
}
@font-face {
    font-family: "sairacondensed-semibold";
    src: url("../fonts/sairacondensed-semibold.woff") format("woff"),
        url("../fonts/sairacondensed-semibold.eot") format("embedded-opentype"),
        url("../fonts/sairacondensed-semibold.ttf") format("truetype"),
        url('../fonts/sairacondensed-semibold.eot?#iefix') format('embedded-opentype'),
        url("../fonts/sairacondensed-semibold.svg#sairacondensed-semibold") format("svg");
    font-weight: normal;
}
@font-face {
    font-family: "sairacondensed-thin";
    src: url("../fonts/sairacondensed-thin.woff") format("woff"),
        url("../fonts/sairacondensed-thin.eot") format("embedded-opentype"),
        url("../fonts/sairacondensed-thin.ttf") format("truetype"),
        url('../fonts/sairacondensed-thin.eot?#iefix') format('embedded-opentype'),
        url("../fonts/sairacondensed-thin.svg#sairacondensed-thin") format("svg");
    font-weight: normal;
}

// LemonMilk

@font-face {
    font-family: "LemonMilk";
    src: url("../fonts/LemonMilk.woff") format("woff"),
        url("../fonts/LemonMilk.eot") format("embedded-opentype"),
        url("../fonts/LemonMilk.ttf") format("truetype"),
        url('../fonts/LemonMilk.eot?#iefix') format('embedded-opentype'),
        url("../fonts/LemonMilk.svg#LemonMilk") format("svg");
    font-weight: normal;
}


// CocoSharpL

@font-face {
    font-family: "cocosharp-regular";
    src: url("../fonts/CocoSharpL-Regular.otf");
}

@font-face {
    font-family: "CocoSharp-Bold";
    src: url("../fonts/CocoSharp-Bold.otf");
}

@font-face {
    font-family: "CocoSharp-BoldItalic";
    src: url("../fonts/CocoSharp-BoldItalic.otf");
}

@font-face {
    font-family: "CocoSharp-Extrabold";
    src: url("../fonts/CocoSharp-Extrabold.otf");
}
@font-face {
    font-family: "CocoSharp-Light";
    src: url("../fonts/CocoSharp-Light.otf");
}