.msg-section {
    padding: 15px;

    &.media {
        img.user-img {
            border-radius: 50%;
            max-width: 48px;
            max-height: 48px;
            display: inline-block;
        }

        .media-body {
            padding: 0 10px;
        }

        .comment-txt {
            background: $white;
            box-shadow: 0 16px 16px 0 rgba(0, 0, 0, 0.05);
            border-radius: 14px;
            min-height: 70px;
            padding: 7px 15px;
            font-size: 14px;
            font-family: $font-regular;
            color: $gray-910;
            display: inline-block;

            .comment-by {
                color: $secondary;
                font-family: $font-bold;
                text-transform: capitalize;
                line-height: 1.3;
            }
        }

        .comment-time {
            margin-top: 10px;
            color: $gray-200;
            font-size: 12px;
            font-family: $font-bold;

            span {
                display: inline-block;
                color: $gray-900;
                font-size: 12px;
                margin-left: 15px;

                i {
                    font-size: 15px;
                    color: $gray-200;
                    display: inline-block;
                    margin-right: 5px;
                }
            }
        }

        &.outgoing-msg {
            .comment-time {
                text-align: right;
            }

            .comment-txt {
                background: $primary;
            }
        }
    }
}

.date-tag {
    display: inline-block;
    margin: 20px auto;
    border-radius: 15px;
    background: $gray-570;
    height: 20px;
    width: 110px;
    color: $gray-900;
    font-family: $font-bold;
    text-transform: capitalize;
    text-align: center;
    font-size: 11px;
    line-height: 20px;
}

.enter-comment-section {
    background: $white;
    padding: 10px;
    position: fixed;
    width: 100%;
    bottom: 0;

    .msg-section {
        padding: 0;

        .media-body {
            padding: 0;
        }
    }

    img {
        border-radius: 50%;
        max-width: 48px;
        max-height: 48px;
        margin-right: 10px;
    }

    .type-comment-area {
        background: $gray-570;
        border-radius: 22px;

        .form-group {
            margin: 0;
            position: relative;

            .form-control {
                border: none;
                background: $gray-570;
                border-radius: 22px;
                padding: 12px 40px 12px 12px;
            }

            i {
                position: absolute;
                right: 10px;
                top: 15px;
                font-size: 18px;
                color: $gray-200;
                cursor: pointer;
            }
        }
    }
}

.post-comment-section {
    padding-bottom: 30px;

    .blog-detail-section {
        .blog-action {
            padding: 0;
            border-bottom: 0;
        }
    }

    .post-card {
        border-radius: 0;
    }

    .comment-section {
        background: $white;
    }

    .msg-section.media {
        .comment-txt {
            background: $gray-170;
            box-shadow: none;
        }
    }

    .enter-comment-section {
        box-shadow: 0 -4px 20px 0 rgba(0, 0, 0, 0.1);
    }
}