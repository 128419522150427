.membership-wrapper {
    padding: 20px;
    position: relative;
    margin-top: 70px;
}

.left-empty-card,
.right-empty-card {
    background: $white;
    box-shadow: 0 16px 16px 0 rgba(0, 0, 0, 0.1);
    width: 8%;
    position: absolute;
    top: 5%;
    opacity: 0.6;
    height: 610px;

    @media(max-width: 767px) {
        height: 580px;
    }
}

.left-empty-card {
    border-radius: 0 14px 14px 0;
    left: 0;
}

.right-empty-card {
    border-radius: 14px 0 0 14px;
    right: 0;
}

.membership-card {
    background: $white;
    box-shadow: 0 16px 16px 0 rgba(0, 0, 0, 0.1);
    border-radius: 14px;
    max-width: 85%;
    margin: 0 auto;
    position: relative;
    min-height: 638px;

    @media(max-width: 767px) {
        min-height: 608px;
    }

    .btn {
        position: absolute;
        bottom: -22px;
        left: 50%;
        transform: translateX(-50%);
        box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.1);
        padding: 0 45px !important;
        height: 44px;
        border-radius: 22px;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .discount-text {
        color: #017949;
        font-size: 10px;
        font-family: $font-xtrabold-italic;
    }
}

.membership-card-header {
    background: url('../img/membership-bg.png') no-repeat;
    background-size: cover;
    padding: 20px 20px 70px;
    height: 230px;
    background-position: center bottom;
    border-top-left-radius: 14px;
    border-top-right-radius: 14px;

    @media (max-width: 428px) {
        height: 200px;
    }
}

.membership-card-body {
    padding: 2px 20px 70px;

    &.membership-premium-plus {
        margin-top: -20px;
    }

    @media (max-width: 320px) {
        padding: 2px 10px 70px;
    }
}

.membership-heading {
    font-size: 16px;
    font-family: $font-bold-italic;
    color: $secondary;
    text-align: center;
}

.membership-prize {
    font-size: 40px;
    font-family: $font-xtrabold-italic;
    color: $gray-900;
    text-align: center;

    span {
        position: relative;
        display: inline-block;
    }

    i {
        left: -25px;
        top: 6px;
        font-size: 20px;
        color: $primary;
        position: absolute;
    }
}

.membership-type {
    font-size: 14px;
    font-family: $font-italic;
    text-align: center;
    color: $gray-550;
}

.membership-feature {
    padding: 0;
    margin: 0;
    list-style-type: none;

    li {
        text-align: center;
        border-bottom: 1px solid $border-light;
        padding: 10px;
        font-size: 12px;
        font-family: $font-italic;
        color: $gray-600;

        &:last-child {
            border-bottom: none;
        }

        &.highlight-feature {
            font-family: $font-bold-italic;

            img {
                width: 20px;
                margin-right: 4px;
            }
        }

        img {
            width: 20px;
            margin-right: 4px;
            display: inline-block;
        }
    }
}

.user-pl-dt{
    padding: 15px;
    padding-bottom: 0;
    text-align: center;
    font-weight: 500;
    span{color: $primary; font-family: "MuliBlack";}
}

.mem-tab-body {
    padding: 15px 50px;
    overflow-x: hidden;
    &.mem-slider-body{
        padding: 15px 00px;  
    }
    .membership-slider{
            .slick-prev{
                left: 0;
            z-index: 999;
                &::before{
                    //content: "\e9b3";
                    z-index: 999;
                    color: #333333!important;
                    opacity: 0.3;
                }
            }
            .slick-next{
                right: 0;
                &::before{
                    //content: "\e9b3";
                    z-index: 999;
                    color: #333333!important;
                    opacity: 0.3;
                }
            }
        .slick-arrow{
            &.slick-next{
                display: block!important;
            }
            &.slick-prev{
                display: block!important;
            }
        }
        .slick-dots{
            bottom: unset;
            top: -14px;
            display: flex !important;
            padding: 0 10px;
            li{
                width: 33.33%;
                margin: 0 5px;
                height: auto;
                background-color: $white;
                border-radius: 6px 6px 0 0;
                .mem-tab-ar{
                    background: transparent;
                    padding: 0;
                }
                .mem-tab{
                    color: #37003C;
                    font-family: $font-bold;
                    font-size: 12px;
                    letter-spacing: 0;
                    line-height: 18px;
                    text-align: center;
                    background-color: transparent;;
                    border: none;
                    
                }
                &.slick-active{
                    border-radius: 6px 6px 0 0;
                    background-color: #37003C;
                    .mem-tab{
                        color:$white;
                        border: none;
                    }
                }
            }
        }
    }
    .mem-tab-dv {
        display: none;
        // padding: 30px 15px;
        // border-radius: 10.89px;
        // background-color: #ffffff;
        // box-shadow: 0 16px 16px 0 rgba(0, 0, 0, 0.1);
        // padding-top: 50px;
        min-height: 300px;
        margin-top: 60px;
        position: relative;
        &.active {
            display: block;
        }

        &:before,
        &:after {
            content: "";
            height: 80%;
            width: 60px;
            border-radius: 10.37px;
            position: absolute;
            background-color: #ffffff;
            box-shadow: 0 16px 16px 0 rgba(0, 0, 0, 0.1);
            top: 10%;
            // display: none;
            @media(max-width:767px){
                width: 30px;
            }
        }

        &:before {
            // left: -80px;
            left: 4px;
            @media(max-width:767px){
                left: 0px;
            }
        }

        &:after {
            // right: -80px;
            right: 4px;
            @media(max-width:767px){
                right: 0px;
            }
        }
.mem-inner-body {
    border-radius: 10.89px;
    background-color: #ffffff;
    box-shadow: 0 16px 16px 0 rgba(0, 0, 0, 0.1);
    width: 80%;
    margin: 0 auto;
    @media(max-width:767px){
        width: 75%;
    }
}
        .head-part {
            background: url("../img/membership-bg.png") no-repeat;
            background-size: cover;
            background-position: center bottom;
            padding: 20px 20px 70px;
            min-height: 260px;
            padding-top: 50px;
        }
    }
}
